@import '~less/include.less';

[data-role="modal-content"] .input-control {
	padding: @gutter-half;
}

.input-control {
	input.control {
		width: 100%;
		padding: 3px 6px;
		font-weight: bold;
		font-size: 18px;
		color: @brand-primary;
	}
}

