@import '~less/include.less';

.contact-value-in-list {
	.contact-value-main{
		margin-bottom:10px;
	}
	.contact-value-type, .contact-value{
		display:inline-block;
		vertical-align:bottom;
	}
	.contact-value{
		font-size:18px;
		font-weight:500;
		a:Hover{
			text-decoration:none;
		}
	}
	.contact-value-type{
		width:100px;
		.ff-header(400);
		font-size:16px;
	}
}
