@import '~less/include.less';


.building-class-by-selector-mixin(@_add, @selector, @rule) {
	@sel: ~"@{selector}";
	@add: ~"@{_add}";

	&.@{add}Ap @{sel} {
		@realty-color: @clr-bld-Ap;
		@rule();
	}

	&.@{add}A @{sel} {
		@realty-color: @clr-bld-A;
		@rule();
	}

	&.@{add}Bp @{sel} {
		@realty-color: @clr-bld-Bp;
		@rule();
	}

	&.@{add}B @{sel} {
		@realty-color: @clr-bld-Bp;
		@rule();
	}

	&.@{add}Cp @{sel} {
		@realty-color: @clr-bld-Cp;
		@rule();
	}

	&.@{add}C @{sel} {
		@realty-color: @clr-bld-C;
		@rule();
	}

	&.@{add}Dp @{sel} {
		@realty-color: @clr-bld-Dp;
		@rule();
	}

	&.@{add}D @{sel} {
		@realty-color: @clr-bld-D;
		@rule();
	}

	&.@{add}Ep @{sel} {
		@realty-color: @clr-bld-Ep;
		@rule();
	}

	&.@{add}E @{sel} {
		@realty-color: @clr-bld-E;
		@rule();
	}
}

.offers-v2 {
	&.with-scroll {
		height: 100%;

		> div:not(.offer-list-item2):not(.empty-view) {
			height: 100%;
		}

		.scrollY-region {
			padding-right: 5px;
		}
	}
}

.offer-list-item2 {
	position: relative;
	margin: 3px;
	margin-bottom: 7.5px;
	padding: 2px;
	border: 1px solid rgba(0,0,0,.1);
	box-shadow: 0 0 5px 3px rgba(0,0,0,.1);
	.ff-header();

	border-width: 3px;
	border-radius: 6px;

	.fields {
		padding-top: 16px;
		padding-right: 32px;
		display: flex;
		flex-flow: row wrap;

		> * {
			padding: 3px;
			flex-grow: 1;
			flex-basis: 0;
		}

		.building-class {
			flex-basis: 20px;
			padding: 3px 0;
			flex-grow: 0;
			text-align: center;
		}

		.building {
			font-size: 12px;
		}

		.building, .building-class {
			line-height: 1;
			font-weight: 500;

			&.Ap {
				color: @clr-bld-Ap;
			}

			&.A {
				color: @clr-bld-A;
			}

			&.Bp {
				color: @clr-bld-Bp;
			}

			&.B {
				color: @clr-bld-Bp;
			}

			&.Cp {
				color: @clr-bld-Cp;
			}

			&.C {
				color: @clr-bld-C;
			}

			&.Dp {
				color: @clr-bld-Dp;
			}

			&.D {
				color: @clr-bld-D;
			}

			&.Ep {
				color: @clr-bld-Ep;
			}

			&.E {
				color: @clr-bld-E;
			}

		}

		.metro {
			font-size: 10px;
			font-weight: 500;
			text-align:center;
		}

		.income {
			font-size: 12px;
			text-align: right;
		}

		.square {
			font-size: 16px;
			min-width: 80px;
			font-weight: 400;
		}

		.price {
			font-size: 12px;
			min-width: 80px;
		}
	}

	.toggle-select {
		position: absolute;
		top: 0;
		right: 0;
		width: 32px;

		button {
			width: 100%;
			height: 100%;
			display: block;
			border: none;
			box-shadow: none;

			i {
				font-style: normal;
				.icon('check-square-o');
				color: rgba(0,0,0,.4);
			}
		}
	}

	.system {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 3px;
		font-size: 11px;
		text-transform: uppercase;
	}

	&.mo-rent .fields .price {
		color:@brand-primary;
		font-weight:400;
	}

	&.in-selected {
		.toggle-select {
			button {
				background-color:@brand-success;
				color:#fff;
				i {
					color:#fff;
				}
			}
		}
	}



	&.ui2-molecule.ui2-molecule.ui2-molecule.ui2-molecule.ui2-molecule.ui2-molecule {
		.ui2-atom {
			> span {
				padding-bottom: 0;
			}
		}
		border-radius: 3px;
		align-items: center;
		button {
			border-radius: 3px;
			i {
				font-style: normal;
			}
		}
		> * {
			flex-grow: 0;
			flex-shrink: 0;
		}

	}

	

	.search-on-map & {

		flex-wrap: wrap;
		padding-top: 14px;
		.actions {
			position: absolute;
			top: 1px;
			right: 1px;
			> button {
				width: 27px;
				height: 27px;
				font-size: 15px;
			}
		}

		.system.offer-additional-info {
			padding-left: 10px;
			overflow: hidden;
			max-width: calc(100% - 30px);
		}

	}

	.area {
		width: 80px;
	}
	.price {
		width: 135px;
	}
	.agent {
		width: 85px;
	}
	.metro-holder .metro-name {
		font-weight: 500;
	}
	.building {
		max-width: 100%;
	}
	.important-value {
		font-weight: 500;
	}

	.postfix {
		font-size: 10px;
		margin-left: 5px;
	}

	.realty-name-with-badge {
		display: block;
		.realty-name {
			display: inline-block;
			vertical-align: middle;
		}
		.realty-class-badge {
			margin-left: 4px;
			// background-color: #aaa;
			// width: 18px;
			// height: 18px;
			// border-radius: 9px;
			// display: inline-block;
			// text-align: center;
			// font-family: sans-serif;
			// font-size: 10px;
			// vertical-align: middle;
			// line-height: 19px;
			// font-weight: bold;
		}
	}

	.agent-percent {
		display: inline-block;
		> * {
			display: inline-block;
			vertical-align: middle;
			&:first-child {
				margin-right: 2px;
			}
			&:last-child {
				margin-left: 2px;
			}
		}

	}

	.system.offer-additional-info {
		padding-left: 45px;
		font-weight: 500;
		color: @brand-primary;
	}

	.actions {
		> button {
			outline: none;
			&:active, &:focus {
				outline: none;
			}
			background-color: transparent;
			border-color: transparent;
			display: block;
			padding: 0 5px;
			font-size: 18px;
			width: 35px;
			height: 35px;
			text-align:center;
			box-shadow: none;
			&.card i {
				.icon('list-alt');
			}
			&.select {
				i {
					.icon('check-square-o');
					// color: #ddd;
				}
			}
		}
	}

	.actions {
		> button {
			// color: #444;
			&:hover {
				background-color: rgba(0,0,0,.1);
				color: #444;
			}
		}
	}

	&:not(.selected):not(.joined) {

		.actions > .select {
			color: rgba(0,0,0,.1);
		}

		&.rent {
			.linear-back(darkseagreen);
		}
		&.sell {
			.linear-back(steelblue);
		}
	}
	&.selected {
		// > .select {
		// 	// background-color:@brand-success;
		// 	color:#fff;
		// 	> i {
		// 		.icon('check-square-o');
		// 	}
		// }

		.actions > .select {
			color: white;
		}		

		&.rent {
			.linear-back(darkseagreen, 25);

			border-color: fade(darkgreen, 30);
			.actions > .select {
				background-color: darkseagreen;
			}
		}
		&.sell {
			.linear-back(steelblue, 25);

			border-color: fade(steelblue, 30);
			.actions > .select {
				background-color: steelblue;
			}
		}

	}

	&.joined {

		.linear-back(orange, 25);

		border-color: fade(orange, 30);
		.actions > .select {
			display: none;
		}
	}

	.small-badge {
		padding: 0 2px;
		background-color: #ddd;
		border-radius: 2px;
		color: white;
		margin-right: 2px;
		font-size: 10px;
	}

	&.rent {
		.important-value {
			color: darkgreen;
		}
		.small-badge {
			background-color: fade(darkgreen, 50);
		}
	}
	&.sell {
		.important-value {
			color: midnightblue;
		}
		.small-badge {
			background-color: fade(midnightblue, 50);
		}
	}

	&.rent, &.sell {
		.small-badge {
			&.nds {
				background-color: #704070;
			}
			&.boma {
				background-color: #a06000;
			}
		}
	}


	.building-class-by-selector-mixin('class-', '.building .realty-name-with-badge .realty-class-badge.badge', {
		background-color: @realty-color;
	});



}

.metro-station + .result {
	.offer-list-item2 .metro {
		display:none;
	}
}
.realty-item + .result {
	.offer-list-item2 .building {
		display: none;
	}
}

.linear-back(@clr, @f: 5) {
	// @tr-start: 10%;
	// @low-offset: 15%;
	// @max-opacity: 10%;
	// background: linear-gradient(
	// 	transparent (0 - @tr-start), 
	// 	fade(@clr, @max-opacity/2) 50% - @low-offset, 
	// 	fade(@clr, @max-opacity), 
	// 	//fade(@clr, @max-opacity/2) 50% + @low-offset, 
	// 	transparent (100% + @tr-start)
	// );
	// background-color: fade(@clr, 5);
	background: linear-gradient(
		transparent,
		fade(@clr, @f),
		transparent
	)
}