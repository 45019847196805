@emptyRule: {};

.ui2-button-setup-mixin(@buttonRule: @emptyRule) {
    &:focus {
        outline: none;
    }
    @buttonRule();
}


.ui2-button-hover-mixin(@rule: @emptyRule) {
    &:not(.active):not(:disabled):not(.disabled) {
        cursor: pointer;
        &:Hover {
            @rule();
        }    
    }
    &.active {
        cursor: default;
    }
    &.disabled, &:disabled {
        cursor: not-allowed;
    }
}

.ui2-buton-click-mixin() {
    position: relative;
    > * {
        position: relative;
    }
    &:active {
        > * {
            transform: translateY(1px);
        }
    }
}

.ui2-button-mixin(@buttonRule, @hoverRule) {
    .ui2-button-setup-mixin(@buttonRule);
    .ui2-button-hover-mixin(@hoverRule);
    .ui2-buton-click-mixin();
}

.ui2-button-flat-mixin(@hoverRule: @emptyRule) {
    .ui2-button-mixin({
        border: none;
        background-color: transparent;
        box-shadow: none;
    }, @hoverRule);
}