.map-control-inputWithIcon-mixin(@ico)
{
	font-size:0;
	.icon {
		.icon(@ico);
		position:absolute;
		right:0;
		font-size:24px;
		width:25px;
		text-align:center;
		height:100%;
		&:before{
			vertical-align: middle;
		}
		//margin-left: -25px;
	}

	.input-view {
		input {
			padding-right: 23px;
			padding-left: 6px;
		}
	}
	> span {
		display:inline-block;
		vertical-align:middle;
	}
}