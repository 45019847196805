@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 100;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9A4kDNxMZdWfMOD5VvkrCqYTc.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAGQBf_.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBiQxf_.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5VvkojO.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrA6Qhf_.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAWRRf_.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrByRBf_.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBuRxf_.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBKRhf_.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 100;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5Vn9IjO.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnWKnuQQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnPKruQQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9E4kDNxMZdWfMOD5VfkA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnZKvuQQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnSKzuQQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnLK3uQQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnMK7uQQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnFK_uQQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: italic;
  font-weight: 100;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOtEADFm8hSaQTFG18FErVhsC9x-tarUfPVzONU.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: italic;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVYMJ0dw.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVBMF0dw.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOjEADFm8hSaQTFG18FErVhsC9x-tarUfPtqA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: italic;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVXMB0dw.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVcMd0dw.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVFMZ0dw.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: italic;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVCMV0dw.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: italic;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVLMR0dw.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 100;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOjEADFm8hSaQTFG18FErVhsC9x-tarWZXtqA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWTnMiMM.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWV3PiMM.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOhEADFm8hSaQTFG18FErVhsC9x-tarYfE.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWQXOiMM.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWSnJiMM.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWU3IiMM.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWVHLiMM.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Condensed';
  font-style: normal;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWXXKiMM.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: italic;
  font-weight: 100;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPOcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqW21-e.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: italic;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWd36-pA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWE32-pA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPMcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fquvw.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: italic;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWS3y-pA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWZ3u-pA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWA3q-pA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: italic;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWH3m-pA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: italic;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWO3i-pA.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 100;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPMcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3Zyuvw.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3TCPn38.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3VSMn38.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPKcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda5fg.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3QyNn38.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3SCKn38.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3USLn38.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3ViIn38.ttf) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-style: normal;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3XyJn38.ttf) format('truetype');
}
