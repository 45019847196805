@import '~less/include.less';

.block.subdivision-item {



	&[data-url]:Hover{ .hover-darken(); }

	header {
		.ff-header();
		text-transform: uppercase;
		font-weight: 400;
	}

	footer {
		text-align: right;

		a {
			text-decoration: none;

			&:Hover {
				text-decoration: none;
			}

			span {
				.icon('chevron-right')
			}
		}
	}
}