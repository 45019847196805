@import '~less/include.less';
.edit-realties-list {
    .eeeeeedit-realty-list-item {
        position: relative;
        margin: 15px;
        box-shadow: 0 0 10px 1px #00000010;
        border: 1px solid #00000020;
        display: flex;
        flex-direction: row;
        border-radius: 2px;

        .thumbnail-holder {
            position: relative;
            flex-grow: 0;
            flex-shrink: 0;
            width: 100px;
            height: 100px;
            .thumbnail-place {
                width: 100px;
                height: 100px;
                img {
                    width: 100%;
                    height: 100%;
                }
                .fake {
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: fade(royalblue, 5);
                }
            }
            .vertical-bar {
                position: absolute;
                width: 4px;
                height: 100%;
                top: 0;
                &.class-bar {
                    left: 0px;
                }
            }
            .class-letter {
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 30px;
                height: 30px;
                text-align: center;
                .ff-header();
                line-height: 28px;
                text-transform: uppercase;
                font-weight: 900;
                text-shadow: 0 0 2px white;
            }
        }

        &.aplus .class-bar { .bldclr-bg("Ap"); }
        &.a     .class-bar { .bldclr-bg("A"); }
        &.bplus .class-bar { .bldclr-bg("Bp"); }
        &.b     .class-bar { .bldclr-bg("B"); }
        &.cplus .class-bar { .bldclr-bg("Cp"); }
        &.c     .class-bar { .bldclr-bg("C"); }
        &.dplus .class-bar { .bldclr-bg("Dp"); }
        &.d     .class-bar { .bldclr-bg("D"); }
        &.eplus .class-bar { .bldclr-bg("Ep"); }
        &.e     .class-bar { .bldclr-bg("E"); }
        &.fplus .class-bar { .bldclr-bg("Fp"); }
        &.f     .class-bar { .bldclr-bg("F"); }

        &.aplus .class-letter { .bldclr-bg("Ap"); }
        &.a     .class-letter { .bldclr-bg("A"); }
        &.bplus .class-letter { .bldclr-bg("Bp"); }
        &.b     .class-letter { .bldclr-bg("B"); }
        &.cplus .class-letter { .bldclr-bg("Cp"); }
        &.c     .class-letter { .bldclr-bg("C"); }
        &.dplus .class-letter { .bldclr-bg("Dp"); }
        &.d     .class-letter { .bldclr-bg("D"); }
        &.eplus .class-letter { .bldclr-bg("Ep"); }
        &.e     .class-letter { .bldclr-bg("E"); }
        &.fplus .class-letter { .bldclr-bg("Fp"); }
        &.f     .class-letter { .bldclr-bg("F"); }

        // .class-letter {
        //     @is-bg-dark: boolean(luma($background-color) > 50%);
        //     color: if(@is-bg-dark, black, white);
        // }

        .info {
            flex-grow: 1;
            flex-shrink: 1;
            padding: 0 5px;
            position: relative;
            .name {
                .ff-header();
                font-weight: bold;
                font-size: 18px;
                small {
                    color: #555;
                    font-weight: 400;
                }
            }
            .actualize-data {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 24px;
                line-height: 20px;
                .ff-action(400);
                margin-left: -5px;
                padding-left: 5px;
                overflow: hidden;

                padding-top: 1px;
                border-top: 1px solid #00000010;

                &:Hover {
                    height: unset;
                    overflow: unset;
                    min-height: 24px;
                }
                .status {
                    padding:0 3px;
                    border-radius: 2px;
                    font-weight: bold;
                    border: 1px solid #00000020;
                    background-color: #00000010;
                }
                .comment {
                    padding:0 3px;
                }
            }
        }

        &.a-undefined {
            .info {
                .actualize-data {
                    background-color: #ffa;
                }
            }
        }

        &.a-ok {
            .info {
                .actualize-data {
                    background-color: transparent;
                }
            }
        }

        &.a-errors {
            .info {
                .actualize-data {
                    background-color: #faa;
                }
            }
        }

        .actions {
            flex-grow: 0;
            flex-shrink: 0;
        }
    }
}