.static-board(){
	height:100%;
	padding-top:40px;
	.lists-tabs{
		position:absolute;
		top:0;
	}
	.lists {

		height:100%;
		font-size:0;
		white-space:nowrap;
		overflow:auto;
		.list {
			height:100%;
			display:inline-block;
			width:250px;
			vertical-align:top;
			font-size:16px;
			//margin: 0 5px;

			&.r-hidden {
				display:none;
			}

			> div {
				padding: 5px;
				border-radius: 4px;
				background-color: rgba(0,0,0,0.05);
				margin: 0 5px;
			}
			header {
			    font-weight: 500;
				position: relative;
				.badge{
					position:absolute;
					top:0;
					right:0;
				}
			}
			header + section {
				max-height:~"calc(100vh - 145px)";
				overflow:auto;
				margin: 0 -5px;
				.v-scrollbar();
			}

			.item{
				margin:10px 5px;
				* {
					white-space:normal;
				}
			}

		}
	}
}