@import '~less/include.less';

.ownerInRealty {

	> .iinfo {
		display: flex;
		//width: 100%;
		flex-direction: row;
		flex-wrap: wrap;
		//padding: 0 15px;
		margin:0 auto;
		width: ~"calc(100% - 30px)";
		box-shadow: 0 0 10px 5px rgba(0,0,0,.05);
		border: 1px solid rgba(0,0,0,.07);				
		border-radius: 2px;
		background-color: #fff;

		> * {

			border-top: 1px solid rgba(255,255, 0, .1);
			border-left: 1px solid rgba(255,255, 0, .1);
			border-right: 1px solid rgba(0,0,0,.1);
			border-bottom: 1px solid rgba(0,0,0,.1);

			flex-grow: 1;
			min-width: 300px;

			.inner {

				position: relative;
				margin-bottom: 0;

				
				display: flex;
				flex-direction: row;
				width: 100%;

				> * {
					padding: 7.5px;
					flex-grow: 0;
					flex-shrink: 0;
					&.entity {
						flex-grow:1;
					}
				}

				a {
					text-decoration: underline;
					font-weight: bold;
					color: cornflowerblue;
				}

				&.realty {
					.icon {
						.icon('building', true)
					}
				}

				&.owner {
					.icon {
						.icon('user-secret', true)
					}
				}

				.tags-list {
					.tag {
						//padding: 5px;
						margin:5px;
						border-radius: 2px;
						border: 1px solid rgba(0,0,0,.1);
						background-color: blanchedalmond;
						font-size: 0;
						> span {
							display: inline-block;
							padding: 4px;
							font-size: 10px;
							vertical-align: middle;
							> * {
								vertical-align: middle;
								top: unset;
							}
							& + span {
								margin-left: 4px;
							}
							&:last-child {
								border-left: 1px solid rgba(0,0,0,.1);
							}

							&.close-btn {
								&:Hover {
									cursor: pointer;
									background-color: rgba(0,0,0,.1);
								}
							}

						}
					}
				}

			}

			&.tags-info {

				> .inner {
					> .icon {
						align-self: center;
						> * {
							vertical-align: middle;
							& + * {
								margin-left: 5px;
							}
						}
						> button {
							padding: 3px;
							width: 24px;
							border-radius: 3px;
						}
					}
				}

			}

		}
	}

	> .info {
		display: flex;
		//width: 100%;
		flex-direction: row;
		flex-wrap: wrap;
		//padding: 0 15px;
		margin:0 auto;
		width: ~"calc(100% - 30px)";
		box-shadow: 0 0 10px 5px rgba(0,0,0,.05);
		border: 1px solid rgba(0,0,0,.07);				
		border-radius: 2px;
		background-color: #fff;

		> * {
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			&.oir {


			}

			&.tags-info {
				//border:1px solid rgba(0,0,0,.1);				
				> .inner {
					> .icon {
						align-self: center;
					}
				}

			}

			&.actualize-info {
				//border:1px solid rgba(0,0,0,.1);
				> .inner {
					> .icon {
						align-self: start;
					}
				}
				.notes {
					white-space: pre-wrap;
					.ff-text();
					padding: 9px;
				}
			}

			// &.contacts-info {
			// 	//border:1px solid rgba(0,0,0,.1);
			// }

			&:not(:first-child):not(:last-child) {
				border:1px solid rgba(0,0,0,.1);
				border-bottom: none;
			}

			&:last-child {
				border:1px solid rgba(0,0,0,.1);
			}

			.inner {
				display: flex;
				flex-direction: row;
				.icon {
					padding: 9px 0;
					align-self: start;
					> * {
						vertical-align: middle;
						& + * {
							margin-left: 5px;
						}
					}
					> button {
						padding: 3px;
						width: 24px;
						border-radius: 3px;
					}					
				}
				> *:not(.flex-grow) {
					flex-grow: 0;
					flex-shrink: 0;
				}
				> .flex-grow {
					flex-grow: 1;
					flex-shrink: 1;
				}
				> * {
					> * {
						padding: 4px;
					}
				}
			}

		}

		.oir {
			.owner-info, .realty-info {
				flex-grow: 0;
				flex-shrink: 0;
			}

			> * {
				border-top: 1px solid rgba(0,0,0,.1);
				width: 100%;
				border-left: 1px solid rgba(0,0,0,.1);
				border-right: 1px solid rgba(0,0,0,.1);
				@media(min-width:@screen-sm-min) {
					&:not(:first-child) {
						border-left: none;
					}
					width: unset;
				}
			}

			.inner.realty {
				.icon i {
					.icon('building', true);
					font-style: normal;
				}
			}

			.inner.owner {
				.icon i {
					.icon('user-secret', true);
					font-style: normal;
				}
			}				

			a {
				
				text-decoration: underline;
				color: cornflowerblue;
				.ff-header();
				font-weight: bold;
				line-height: 26px;				
			}

			.btn-icon {
				padding: 9px 5px;
				&:Hover {
					background-color: rgba(0,0,0,.1);
					cursor: pointer;
				}
			}

			.entity {
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			.days-to-stale {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				&:Hover {
					background-color: rgba(0,0,0,.1);
				}
			}

			.days-to-stale_inner {
				padding: @gutter-half;
			}

		}


		.tags-list {
			.tag {
				//padding: 5px;
				margin:5px;
				border-radius: 2px;
				border: 1px solid rgba(0,0,0,.1);
				background-color: blanchedalmond;
				font-size: 0;
				> span {
					display: inline-block;
					padding: 4px;
					font-size: 10px;
					vertical-align: middle;
					> * {
						vertical-align: middle;
						top: unset;
					}
					& + span {
						margin-left: 4px;
					}
					&:last-child {
						border-left: 1px solid rgba(0,0,0,.1);
					}

					&.close-btn {
						&:Hover {
							cursor: pointer;
							background-color: rgba(0,0,0,.1);
						}
					}

				}
			}
		}

		.contacts {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.contacts-list {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			column-gap: 4px;
			row-gap: 4px;
			.contacts-list-item-prev {
				.ff-header();
				position: relative;
				display: flex;
				flex-direction: column;
				//padding: 12px 4px;
				padding-top: 0px;
				border-radius: 4px;
				background-color: #eee;
				margin: 4px;				
				> div {
					//display: inline-block;
					font-weight: 500;
					padding: 0 4px;
					> * {
						display: inline-block;
						vertical-align: middle;
					}
					.name {
						color: darkred;
					}
					&.funcs {
						font-size: 12px;
						font-weight: 400;
						color: darkcyan;
						margin-top: -5px;
					}
				}
			}
			.contacts-list-item {
				background-color: rgba(0,0,0,.1);
				border-radius: 3px;
				max-width: 300px;
			}
		}

	}

	// .owner-info, .realty-info {
	// 	display: inline-block;
	// 	width: 50%;
	// 	padding: 5px 15px;
	// }

	// .owner-info {
	// 	padding-right: 7.5px;
	// }
	// .realty-info {
	// 	padding-left: 7.5px;
	// }

	.items {
		padding: 5px 15px;		
		.realty-objectoffer {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: baseline;
			width: 100%;
			padding: 5px;
			padding-top: 13px;
			margin-bottom:4px;
			background-color: #fff;
			box-shadow: 0 0 10px 5px rgba(0,0,0,.05);	
			border: 1px solid rgba(0,0,0, .1);
			position: relative;

			> * {
				// width: 100px;
				flex-grow: 0;
				flex-shrink: 0;
				border-radius: 3px;
				> small {
					font-size: 13px;
				}
			}

			> .object-system {
				position: absolute;
				background-color: #80ff0010;
				top: 0;
				left: 0;
				width: 100%;
				.s-badge {
					border-radius: 3px;					
					.ff-header();
					font-size: 10px;
					text-transform: uppercase;
					padding: 3px;
					padding-bottom: 2px;
					margin: 2px;
					font-weight: bold;
					&.sb-alert {
						background-color: tomato;
						color: white;
					}
					&.sb-notice {
						background-color: black;
						color: white;
					}
					&.sb-def {
						background-color: darkgray;
						color: white;
					}
				}
			}

			.postfix  {
				font-style: normal;
				color: rgba(0,0,0,.4);
				font-weight: bold;
				font-size: 11px;
				white-space: nowrap;
			}



			.important-value {
				font-weight: 500;
			}
		
			.postfix {
				font-size: 10px;
				margin-left: 5px;
			}			


			.modified {
				width: 100px;
				> b {
					font-size: 9px;
				}
				> span {
					font-weight: bold;
					line-height: 1;
					display: block;
					font-size: 11px;
				}
				&.never {
					* {
						color: darkred;
					}
				}
				&.longago {
					* {
						color: tomato
					}
				}
				&.twoweeks {
					* {
						color: orange;
					}
				}
				&.fresh {
					* {
						color: #777;
					}
				}
			}

			.id {
				width: 130px;
			}

			.floor {
				width: 50px;
			}
			.room-number {
				width: 50px;
			}
			.area {
				width: 100px;
				> small {
					font-size: 12px;
				}
			}
			.info {
				width: 150px;
			}
			.price {
				width: 170px;
			}

			.contract {
				width: 150px;
			}

			.top-text-icon {
				padding-right: 5px;
				color: #777;
				font-size: 13px;
			}

			.actions {
				display:flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-end;
				flex-grow: 1;
				> * {
					flex-grow: 0;
					flex-shrink: 0;
					&:not(:last-child) {
						margin-right: 10px;
					}
					border-radius: 3px;
					border-color: rgba(0,0,0,.5);
					box-shadow:2px 2px 3px 0 rgba(0,0,0,.1);
				}

				.createoffer {
					&.no-accent {
						opacity: .3;
					}
					&:Hover {
						opacity: 1;
					}
				}					
				
				input { 
					margin: 0; 
					width: 25px;
					height: 25px;
					&:Hover {
						background-color: rgba(0,0,0,.1);
						cursor: pointer;
					}
				}
				button {
					padding: 5px;
					i {
						display: block;
						top: 0;
					}
				}
				button.action.select, .objectoffer-action-button.select {
					i {
						display: block;
						width: 14px;
						height: 14px;
						font-size: 14px;
						line-height: 14px;						
					}
					// border: none;
					// padding: 0;
					// > i {
					// 	width: 100%;
					// 	height: 100%;
					// 	font-style: normal;
					// }
				}
			}
		
			&.no-operation {
				.operation { display: none }
			}
			&.no-market {
				.market { display: none }
			}
			&.actual.active {
				background-color: #00ff0008;
				.actions .unpublish {
					background-color: #ff000040;
				}
			}
			&.actual.off {
				background-color: #ff000008;
				.actions .publish {
					background-color: #00ff0040;
				}
			}
			&.notModerated:not(.deleted):not(.archived), &.notVerified {
				background-color: antiquewhite;
				.actions .publish {
					background-color: #00ff0040;
				}

			}
			&.archived, &.deleted {
				background-color: #ddd;
			}
			> * {
				> b, > i {
					color: #777;
				}
			}

			&.rent {
				> *:not(.modified) {
					*:not(.postfix):not(.no-operation-color):not(b):not(i):not(a) {
						color: forestgreen;
					}
				}
			}
			&.sell {
				// .price {
				// 	color: cornflowerblue;
				// }
				> *:not(.modified) {
					*:not(.postfix):not(b):not(i):not(a) {
						color: cornflowerblue;
					}
				}				
			}

			&.selected {
				border-color: cornflowerblue;
				background-color: fade(cornflowerblue, 10);
				.action.select, .objectoffer-action-button.select {
					background-color: cornflowerblue;
					i {
						.icon('check');
						color: white;
						display: block;
					}
				}
			}

			&:not(.selected) {
				.action.select i::before {
					content:'';
				}
				&:Hover {
					border: 1px solid rgba(0,0,0,.3);
					&.active {
						background-color: #00ff0010;
					}
					&.off {
						background-color: #ff000010;
					}					
				}
			}

		}

	}

	.filter {
		padding: 15px;
		.filters {
			text-align:center;
			.enum-collection {
				display: inline-block;
				white-space: nowrap;
				margin: 5px;
			}
			.enum-item {
				font-size: 12px;
				padding:5px;
				line-height: 1;
				position: relative;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;

				&:last-child {
					border-top-right-radius: 5px;
					border-bottom-right-radius: 5px;
				}
				&:first-child {
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
				}
				&:not(:first-child) {
					margin-left: -1px;
				}

				&.active {
					color: white;
					background-color: royalblue;
				}
			}
		}
	}

	.items-actions {
		display: flex;
		flex-direction: row;
		padding: 0 15px;
		.actions {
			display:flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			flex-grow: 1;
			padding: 0 5px;
			> * {
				flex-grow: 0;
				flex-shrink: 0;
				&:not(:last-child) {
					margin-right: 10px;
				}
				border-radius: 3px;
				border-color: rgba(0,0,0,.5);
				box-shadow:2px 2px 3px 0 rgba(0,0,0,.1);
			}

			.createoffer {
				&.no-accent {
					opacity: .3;
				}
				&:Hover {
					opacity: 1;
				}
			}					

			button {
				padding: 5px;
				i {
					display: block;
					top: 0;
				}
			}

			button.action {
				i {
					display: block;
					width: 14px;
					height: 14px;
					font-size: 14px;
					line-height: 14px;
					font-style: normal;				
				}
				// border: none;
				// padding: 0;
				// > i {
				// 	width: 100%;
				// 	height: 100%;
				// 	font-style: normal;
				// }

				&.add i {
					.icon('plus', true);
				}

			}
	

		}
		&:not(.selected) .actions button.action.select {
			i::before {
				content:'';
			}
		}

		&.selected .actions button.action.select {
			background-color: cornflowerblue;
			i {
				.icon('check', true);
				color: white;
			}
		}
	}




}

.objectoffer-action-button.unselectall {
	background-color: cornflowerblue;
	border-radius: 3px;
	padding: 5px;
	i {
		.icon('check', true);
		color: white;
		width: 14px;
		height: 14px;
		font-size: 14px;
		line-height: 14px;			
		display: block;
	}
}


.actualization-ownerInRealty {
	.multi-actions-box {
		position: fixed;
		display: flex;
		flex-direction: column;
		column-gap: 15px;
		row-gap: 15px;
		padding: 15px;
		bottom: 15px;
		left: 15px;
		width: 300px;
		background-color: coral;
		color: white;
		box-shadow: 0 0 10px 5px rgba(0,0,0,.15);

		.info {
			display: flex;
			flex-direction: row;
			align-items: baseline;
			.count {
				font-weight: bold;
				flex-grow: 1;
				flex-shrink: 1;
			}
		}

		.actions {
			.count {
				.value {
					font-size: 16px;
					font-weight: bold;
				}
			}
			button.action.select {
				padding: 5px;
				background-color: cornflowerblue;
				i {
					.icon('check');
					display: block;
					top: 0;
					width: 14px;
					height: 14px;
					font-size: 14px;
					line-height: 14px;						
					display: block;
					color: white;
				}
			}			
			.buttons {
					display:flex;
					flex-direction: row;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					flex-grow: 1;
					margin-bottom: 15px;
					> *:not(label) {
						flex-grow: 0;
						flex-shrink: 0;
						&:not(:last-child) {
							margin-right: 10px;
						}
						border-radius: 3px;
						border-color: rgba(0,0,0,.5);
						box-shadow:2px 2px 3px 0 rgba(0,0,0,.1);
					}
		
					> label {
						width: 100%;
						margin: 0;
						padding: 5px;
					}
					.createoffer {
						&.no-accent {
							opacity: .3;
						}
						&:Hover {
							opacity: 1;
						}
					}					
		
					button {
						color: #777;
						padding: 5px;
						i {
							display: block;
							top: 0;
						}
						&:Hover {
							color: #000;
						}
					}
		

			}
		}
	}


	.edit-price-dialogue {
		> * {
			margin-bottom: 15px;
		}
		.current-price-label, .new-price-label {
			color: #777;
			big {
				color: #000;
			}
		}
		.header-label {
			color: #777;
		}
	}
}


.actualize-actions-waiter {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #00000060;
	.waiter-box {
		padding: 30px;
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: white;
		border-radius: 5px;
		box-shadow: 0 0 10px 3px #00000020;
		color: cornflowerblue;
	}
}