[data-role="modal"] {
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

[data-role="modal-bg"] {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #000000a0;
}

[data-role="modal-box"] {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    padding: 10px;
}

[data-role="modal-close-button"] {
    border: none;
    box-shadow: none;
    border-radius: 0;
    border-top-right-radius: 4px;
}

[data-role="modal-box-content"] {
    display: flex;
    flex-direction: column;
    background-color: white;
    min-width: 400px;
    border-radius: 4px;
    box-shadow: 0 0 10px 3px #00000030;
}

[data-role="modal-header"] {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: end;

    [data-role="modal-header-content"] {
        flex-grow: 1;
        flex-shrink: 1;
        .ff-header();
        font-weight: 500;
        font-size: 18px;
        padding: 4px @gutter/2;
    }

    > button {
        flex-grow: 0;
        flex-shrink: 0;
    }
}

[data-role="modal-promise-buttons"] {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 7.5px;
    .buttons-separator {
        &.reject-rejecthard {
            flex-grow: 1;
            flex-shrink: 1;
        }
    }
}

[data-role="modal-content"] {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: @gutter/2 0;
}

// .my-modal {
//     position: fixed;
//     padding: 30px;
//     border-radius: 6px;
//     border:1px solid #00000020;
//     background-color: white;
//     box-shadow: 0 0 10px 5px #00000040;
//     width: 200px;
//     height: 90px;
//     box-sizing: border-box;
//     left: calc(50% - 100px);
//     top: calc(50% - 90px);
// }