@import '~less/include.less';

.search-on-map{
	height:100%;
	width:100%;
	position:relative;
	display:flex;
	flex-flow:row;
	align-items:stretch;



	.toggle-sidebar {
		position: fixed;
		width: 50px;
		height: 50px;
		background-color: transparent;
		border: none;
		box-shadow: none;
		//border-radius: 5px;
		z-index:6;
		color: #444;
		font-family: 'FontAwesome';
		font-size: 30px;
		text-align: center;
		padding: 0;
		top: 0px;
		right: 51px;
		z-index: 2;
		&:hover {
			background-color: rgba(0,0,0,.1);
		}
		&::after {
			content: @fa-var-columns;
		}
	}
	&.sidebar-on .toggle-sidebar {
		color: cornflowerblue;
	}

	

	.side-bar{
		flex-grow:0;
		flex-shrink: 0;

		//padding-top: 50px;
		display: flex;
		flex-flow: column;
		align-items: stretch;
		position: relative;
		padding-right: 6px;
		&:not(:empty) {
			width:400px;
		}
		.result-container {
			> .item {
				border-bottom: 1px solid rgba(0,0,0,.1);
			}
		}



		.tabs {
			flex-grow: 0;
			flex-shrink: 0;
			// position: absolute;
			// top: 0;
			// left: 0;
			// right: 6px;
			height: 50px;
			overflow: hidden;
			background-color: rgba(100,0,0,.1);

			> div {
				padding-top: 5px;
			}

			.tab-item {
				height: 45px;
				padding: 0;
				text-align: center;
				width: 45px;
				border: none;
				margin: 0 4px;
				background-color: rgba(0,0,0,.1);
				border-radius: 4px;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;

				&:focus, &:active {
					outline: none;
				}

				i {
					font-style: normal;
					line-height: 45px;
				}

				&.active {
					background-color: #fff;
					-webkit-box-shadow: none;
					box-shadow: 2px 2px 3px 0 rgba(0,0,0,.1);
				}

				&.result {
					i {
						.icon('list-ol');
					}
				}

				&.filters {
					i {
						.icon('filter');
					}
				}

				&.selected {
					i {
						.icon('check-square-o');
					}
				}
			}
		}

		.content {
			flex-grow: 1;
			flex-shrink: 1;
			overflow: hidden;
			// padding-top: 50px;
			.fastfilters-control{
				box-shadow:none;
				border:none;
				.actions button {
					box-shadow:none;
				}
			}
			.selected-pane {
				box-shadow:none;
				border:none;
			}
		}

		.slider {
			width: 6px;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			// flex-grow: 0;
			// flex-basis: 6px;
			background-color: rgba(0,0,0,.3);
			cursor: col-resize;
		}

	}

	.map-wrapper {
		flex-grow:1;
		flex-shrink: 1;
		position: relative;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		z-index: 1;
		/*position:absolute;
		left:400px;
		right:0;
		//width:100%;
		height:100%;*/
		//padding-left: 315px;

		.map-container{
			width:100%;
			height:100%;
			// flex-grow:1;
			// flex-shrink: 1;
		}

		.inputs-container {
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			flex-direction: row;
			.map-control-wrapper {
				position: relative;
			}
		}
		.features-container {
			position: absolute;
			top: 64px;
			right: 0;
			display: flex;
			flex-direction: row;
			padding: 0 5px;
			column-gap: 5px;
			button.on {
				background-color: @brand-primary;
				color: white;
			}
		}

		.loader {
			position: absolute;
			top: 25px;
			left: 15px;
			display: none;
			
		}

		&.fetching .loader {
			display: inline-block;
		}
	}

	.actions-panel{
		height:auto;
		&.cluster-info, &.poly-info{
			height:100%;
			//overflow:hidden;

			.selected-cluster{
				overflow:hidden;
				.offers-items-list{
					padding-left:5px;
					padding-right:5px;
					height: ~"calc(100% - 25px)";
					.offer-list-item{
						padding:5px;
						padding-bottom:0;
						.aline > .line {
							display:block;
							line-height: 1;
							margin-bottom:5px;
							.full-address{
								display:none;
							}
							> * {
								width:auto;
								font-size:14px;
								line-height:1;
								margin:0;
								margin-right:5px;
							}
						}
					}

				}
			}

		}
		&.poly-info {
			.selected-cluster{
				> div {
					height:100%;
				}
				.offers-items-list{
					height: ~"calc(100% - 85px)";
				}
				.backButton{
					position:absolute;
					height:60px;
					right:0;
					z-index:1;
					> span {
						.icon('chevron-up');
						line-height:60px;
						padding:0 10px;
						font-size:24px;
						color:rgba(0,0,0,.5);
					}
					&:Hover{
						cursor:pointer;
						background-color:rgba(0,0,0,.1);
						> span {
							color:rgba(0,0,0,1);
						}
					}
				}
			}
		}


	}

	.media-width-down(900px, {
		.side-bar {
			display: none;
			.slider {
				display: none;
			}
		}
		&.sidebar-on .side-bar {
			display: flex;
			position: fixed;
			top: 53px;
			bottom: 3px;
			left: 3px;
			right: 3px;
			width: unset;
			z-index: 4;
			background-color: white;
			overflow: hidden;
		}
	});

}

.side-bar {

}



.map-realty-search-popup {
	max-height: 400px;
	overflow-y: auto;
	background-color: white;
	box-shadow: 0 0 5px 3px rgba(0,0,0,.1);
    left: 1px;
    position: relative;

	.map-realty-search-popup-item {
		padding: 10px;
		&:Hover {
		cursor: pointer;
		background-color: rgba(0,0,0,.1);
		}
		big {
			font-weight:700;
			display: block;
			font-size: 12px;
			line-height: 12px;
			margin-bottom: 2px;
			color: #204080;
		}
		small {
			display: block;
			font-size: 10px;
			line-height: 10px;
			//font-weight: 100;
			font-weight: bold;
			font-style: italic;
			color: rgba(0,40,0,0.8);
		}
		& + * {
			border-top: 1px solid rgba(0,0,0,.1);
		}
	}
}



// <div class="map-realty-search-popup-item">
// 	<big>Lotte Plaza A, Смоленская</big>
// 	<small>Бизнес-центр, Новинский бульвар, 8, Москва, Россия, 121099</small>
// </div>
