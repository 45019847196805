@import '~less/include.less';


.bg-zebra-mixin(@odd: rgba(0,0,0,.1), @even: transparent, @angle: 45deg) {
    background-image: repeating-linear-gradient(
        @angle,
        @odd,
        @odd 20px,
        @even 20px,
        @even 40px,
        );
}

.list-item-eyes-mixin() {
    .broker-visible, .advert-visible {
        width: 35px;
        text-align:center;
        &.true {
            .is-visible > i { .icon('eye'); color: @brand-primary; }
            > b { color: @brand-primary; }
        }
        &.false {
            .is-visible > i { .icon('eye-slash'); color: #777; }
            > b { color: #777; }
        }
    }  
}


.entity-edit-page {
    .content {
        //background-color: #00000010;
    }
    .realty-objects-page {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        > .info-container, > .filters-container {
            flex-grow: 0;
            flex-shrink: 0;
        }

        > .realty-objects-container {
            flex-grow: 1;
            flex-shrink: 1;
            overflow-y: auto;
            .realty-objects {
                min-height:100%;
            }
        }

        .info-container {
            padding: 5px;
            > .wrapper {
                box-shadow: 0 0 5px 3px #00000010;
                padding: 10px;
                background-color: cornsilk;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                > *:not(.long-separator) {
                    flex-grow: 0;
                    flex-shrink: 0;
                }
                > .item {

                    border-left: 1px dotted transparent;
                    margin: 1px;
                    &:not(:first-child):not(.active):not(:hover) {
                        border-left-color: #777;
                    }

                    &.active + .item.item.item.item, &:hover + .item.item.item.item {
                        border-left-color: transparent;
                    }

                    &.active.active.active.active.active {
                        background-color: @brand-primary;
                        color: white;
                        > * {
                            color: white;
                        }
                        border-radius: 4px;
                    }


                    &:not(.active):hover {
                        background-color: rgba(0,0,0,.1);
                        border-radius: 4px;
                        border-left-color: transparent;
                        cursor: pointer;
                    }

                    > * {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                    > div {
                        text-align:center;
                    }
                }
                > .long-separator {
                    flex-grow: 1;
                    flex-shrink: 1;
                }
            }
        }

        .filters-container {
            padding: 5px;
            > .wrapper {
                box-shadow: 0 0 5px 3px #00000010;
                padding: 10px;
                background-color: aliceblue;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                > * {
                    margin: 4px;                    
                }
                * {
                    font-size: 10px!important;
                }

                > .form-group, > .input-single {
                    > label {
                        width: 100%;
                    }
                }

                input, select {
                    font-weight: bold;
                    padding: 3px 6px;
                    &.form-control {
                        height: 26px;
                    }
                }
                .btn {
                    height: 26px;
                }
                .input-group {
                    > * {
                        padding: 3px 6px;
                    }
                }
                label {
                    margin-bottom: 2px;
                }
                input[type="number"] {
                    width: 80px;
                }
            }
        }

    }

    .realty-objects {
        overflow-y: auto;
    }



    .paginator-pages {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 7.5px;
        .paginator-page {
            flex-grow: 0;
            flex-shrink: 0;
            .ff-header();
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            border-radius: 2px;
            margin: 1px;
            border:1px solid transparent;
            &:not(.current):Hover {
                background-color: rgba(0, 0, 0, .1);
                cursor: pointer;
            }
            &.current {
                border-color: rgba(0,0,0,.1);
                background-color: cornsilk;
                font-weight: bold;
            }
        }
    }


}

.entity-edit-page, .edit-objects-list {

    .realty-object-list-item {
        padding: 5px;
        > .wrapper {
            // display: flex;
            // flex-direction: row;
            // flex-wrap: wrap;
            width: 100%;
            box-shadow:0 0 5px 3px #00000010;
            background-color: white;

            border: 1px solid rgba(0,0,0,.1);

            .table-row {
                width: 100%;
                display: table;
                > * {
                    display: table-cell;
                }
            }

            .flex-row {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                > .fixed {
                    flex-grow: 0;
                    flex-shrink: 0;
                    &.index {
                        width: 35px;
                    }
                }
                > :not(.fixed) {
                    &:not(.square-info) {
                        flex-basis: 250px;
                    }
                    &.square-info {
                        flex-basis: 180px;
                    }
                    flex-grow: 1;
                    flex-shrink: 1;

                    &:nth-last-child(2) {
                        flex-basis: 215px;
                    }
                }
								.square-info {
									flex-grow: 3;
									flex-shrink: 0;
								}
            }

            .square-info {
							
                > div {
                    height: 32px;
                    line-height: 32px;
                    font-size: 20px;
										white-space: nowrap;               
                }
            }            

            .options {
                width: 40px;
                .dropdown-toggle {
                    border: 0;
                    border-radius: 0;
                    width:100%;
                    height:100%;
                    box-shadow: none;
                    background-color: transparent;
                    &:focus {
                        background-color: unset;
                    }
                    &:Hover {
                        background-color: rgba(0,0,0,.1);
                    }
                }
            }

            // > * {
            //     padding: 5px;
            //     font-size: 16px;
            // }
            // .square-info {
            //     width: 170px;
            // }
            // .floor {
            //     width: 70px;
            // }
            // .square {
            //     width: 70px;
            // }
            // .status {
            //     width: 120px;
            //     font-size: 12px;
            //     text-transform: lowercase;
            //     font-weight: bold;
            // }



            .offers-container {
                width: 100%;
                padding: 0.1px;
                > :first-child {
                    // display: flex;
                    // flex-direction: row;
                    // flex-wrap: wrap;
                    // padding: 7.5px 0;
                }
            }
            // .offers-container {
            // }

            /*
            .owner, .realty {
                width: 200px;
                flex-grow: 0;
                flex-shrink: 0;
            }
            .current-purpose {
                width: 150px;
            }
            .square-info {
                width: 180px;

                > div {
                    height: 32px;
                    line-height: 32px;
                    font-size: 20px;                    
                }

            }
            .square-status {
                width: 200px;
            }
            .index {
                flex-grow: 0;
                flex-shrink: 0;
            }
            .purposes {
                flex-grow: 1;
                flex-shrink: 1;
            }
            */
        }


        // .broker-visible, .advert-visible {
        //     width: 35px;
        //     text-align:center;
        //     &.true {
        //         .is-visible > i { .icon('eye'); color: @brand-primary; }
        //         > b { color: @brand-primary; }
        //     }
        //     &.false {
        //         .is-visible > i { .icon('eye-slash'); color: #777; }
        //         > b { color: #777; }
        //     }
        // }  


        .list-item-eyes-mixin();


        &.visibility-missing .square-info > b { color: tomato; }
        &:not(.actual) .square-info > i { color: tomato; }
        &.no-offers .offers-container { color: tomato; }
        &.zero-square .square-info > div { color: tomato; }

        &.archived > .wrapper {
            //background-color: #aaa;
            .bg-zebra-mixin(#ddd, #eee);
            //color: #fff;
        }

        &:not(.archived) {

            &.completely-available > .wrapper {
                background-color: white;
            }

            &.partial-available {
                &.services-available > .wrapper {
                    background-color: lighten(cornsilk, 1);
                    background-color: aliceblue;
                }
                &.public-available > .wrapper {
                }
            }

            &:not(.completely-available):not(.partial-available) {
                > .wrapper {
                    background-color: lighten(coral, 30);
                }
                &.notVerified > .wrapper {
                    .bg-zebra-mixin(#ffaa0030);
                }
            }

        }




				&.accent {
					.wrapper {
						// box-shadow: 0 0 3px 1px lightcoral;
						animation: box-shadow-accent-animation .3s steps(5, start) infinite;
						border-radius: 3px;
					}
				}




    }

}

.object-offers-table {

    .list-item-eyes-mixin();

    .object-offer {
        flex-grow: 0;
        flex-shrink: 0;
        border-radius: 2px;
        margin: 4px;
        display: inline-block;
        min-width: 150px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .main-info {
            min-width:150px;
            > i {
                display: flex;
                flex-direction: row;
                width:100%;
                justify-content: space-between;
            
                > * {
                    display:block;
                }
            }
        }
        &.rent .main-info {
            > b {
                color: forestgreen;
            }
        }
        &.sell .main-info {
            > b {
                color: cornflowerblue;
            }
        }

        .operation-cell {
            flex-basis:75px;
        }
        .market-cell {
            flex-basis: 150px;            
        }

        .price-cell {
            flex-basis: 150px;
        }

        border: 2px solid rgba(0,0,0,.1);
        background-color: rgba(0,0,0,.1);

        // > * {
        //     padding: 2px;
        //     display: inline-block;
        // }

        &:Hover {
            cursor: pointer;
            border-color: @brand-warning;
            //border-width: 3px;
        }


        &.notModerated {
            .main-info {
                > i {
                    color: tomato;
                }
            }
        }

    }

    &:not(.archived) {
        .object-offer.active {
            &.rent {
                background-color: lighten(forestgreen, 60);                    
                &:not(:hover) {
                    border-color: forestgreen;
                }
            }
            &.sell {
                background-color: lighten(cornflowerblue, 25);
                &:not(:hover) {
                    border-color: cornflowerblue;
                }                    
            }
        }
        .object-offer.notModerated {
            .bg-zebra-mixin(#ffaa0040);
            // background-image: repeating-linear-gradient(
            //     45deg,
            //     #ffaa0040,
            //     #ffaa0040 20px,
            //     transparent 20px,
            //     transparent 40px,
            //     );
        }
    }

}


.input-range {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 207px;
    // > *:not(label) {
    //     flex-grow: 0;
    //     flex-shrink: 0;
    // }

    > label {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .input-group {
        flex-basis: 0;
        flex-grow: 0;
        flex-shrink: 0;

        //width: 50%;
        & + .input-group {
            border-left:0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            > :first-child {
                border-left:0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            > :last-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}




// .blink {
// 	animation: blink-animation 1s steps(5, start) infinite;
// 	-webkit-animation: blink-animation 1s steps(5, start) infinite;
// }

@keyframes box-shadow-accent-animation {
	from {
		box-shadow: 0 0 0 3px greenyellow;
	}
	to {
		box-shadow: 0 0 0 3px lightcoral;
	}
}
