@import '~less/include.less';

.realty-in-list{
	position:relative;
	font-size:0;

	.scrollY-region & {
	    /*margin-right: -5px;
		padding-right: 5px;*/
	}

	/*> * {
		display:inline-block;
		vertical-align:middle;
	}*/
	.realty-thumb{
		position:absolute;
		top:0;
		left:0;
		width:60px;
		height:60px;
		overflow:hidden;
		img {
			width:100%;
		}
	}
	.realty-info{
		font-size:0;
		height:60px;
		padding-left: 60px;
		//margin-right:-5px;
		> * {
			display:inline-block;
			vertical-align:middle;
		}

		> span {
			height:100%;
			width:0;
		}
		> div{
			padding:0 5px;
			width:100%;
		}
		.name{
			.ff-header();
			font-size:16px;
			font-weight:500;
			width:100%;
			white-space:nowrap;
		}
		.details{
			width:100%;
			font-size:11px;
			white-space:nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&.clickable{
		&:Hover{
			background-color:rgba(0,0,0,.1);
			cursor:pointer;
		}
	}

}