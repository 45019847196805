@import '~less/include.less';

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.task.task-in-block {
	.block();
	margin-bottom: 10px;
	position: relative;
	border-width: 0px;
	box-shadow: 0 0 4px 1px rgba(0,0,0,0.2);

	&.status-completing {
		background-color: fade(@brand-success, 30%);
	}

	&.status-canceling {
		background-color: fade(@brand-danger, 30%);
	}

	> * {
		position: relative;
		z-index: 1;
	}

	> .bg-holder {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 0;
		border-radius: 2px;
	}

	.system {
		.ff-header();
		font-size: 10px;
		font-weight: 500;
		padding: 2px @gutter;
		white-space: nowrap !important;
		overflow: hidden;

		> span {
			margin-right: 5px;
			display: inline-block;
		}
	}

	&:Hover {
		cursor: pointer;
	}

	.task-info, .todo, .deal, .client {
		.ff-header();
		font-weight: 400;
		font-size: 16px;
		padding: 5px @gutter;
		line-height: 1;
		vertical-align: middle;
	}

	.todo {
		color: darken(@brand-info,20);
	}

	.task-info {
		> * {
			display: inline-block;
			vertical-align: middle;
		}

		> i {
			padding: 0 @gutter/3;
			font-style: normal;
			float: right;
		}

		.status {
			float: right;
			line-height: 16px;
		}
	}
	/*&.type-call .task-info > i {
		.icon('phone');
	}*/
	&.type-common .task-info > i {
		.icon('cogs');
		color: #777;
	}

	&.type-call .task-info > i {
		.icon('phone');
		color: @brand-primary;
	}

	&.type-email .task-info > i {
		.icon('envelope');
		color: @brand-success;
	}

	&.type-outHomeMeeting .task-info > i {
		.icon('car');
		color: @brand-warning;
	}

	&.state-initial {
		background-color: rgba(100,100,200,.1);
	}

	&.on-deal:not(.state-initial) {
		//background-color: fade(@brand-success,30%);
		.system {
			background-color: darken(@brand-success,20);
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
		}

		.deal {
			color: darken(@brand-success,20);
			font-weight:500;
		}

		* {
			//color:#fff;
		}
	}

	&.on-client:not(.state-initial) {
		//background-color: fade(@brand-primary, 30%);
		.system {
			background-color: darken(@brand-danger,20);
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
		}

		.client {
			color: darken(@brand-danger,20);
		}

		* {
			//color:#fff;
		}
	}

	&.strict-date {
		.task-date {
			animation: blinker 1.5s linear infinite;
		}
	}

	.status {
		display: inline-block;
		font-size: 12px;
	}

	.task-date {
		display: inline-block;

		> * {
			display: block;
			//text-align: center;
		}

		.task-date-value {
			.ff-header();
			font-weight: 500;
		}

		.task-date-help {
			font-size: 10px;
			line-height: 0.6;
			font-weight: 500;
			color: rgba(0,0,0,.5);
		}
	}


	.comment {
		font-size: 12px;
		padding: 5px @gutter;
		line-height: 1;
		font-style: italic;

		> i {
			font-style: normal;
			.icon('comment');
		}
	}

	.deal, .client {
		font-size: 16px;
		//background-color: rgba(255,255,255, .3);
	}

	.deal > i, .client > i {
		padding: 0 @gutter/3;
		padding-left: 0;
		font-style: normal;
	}

	.deal > i {
		.icon('cog');
	}

	.client > i {
		.icon('user');
	}
}
