.border-radius-topleft(@r) {
	border-top-left-radius: @r;
}

.border-radius-topright(@r) {
	border-top-right-radius: @r;
}

.border-radius-bottomright(@r) {
	border-bottom-right-radius: @r;
}

.border-radius-bottomleft(@r) {
	border-bottom-left-radius: @r;
}

.border-radius-top(@r) {
	.border-radius-topleft(@r);
	.border-radius-topright(@r);
}

.border-radius-bottom(@r) {
	.border-radius-bottomleft(@r);
	.border-radius-bottomright(@r)
}

.border-radius-left(@r) {
	.border-radius-topleft(@r);
	.border-radius-bottomleft(@r);
}

.border-radius-right(@r) {
	.border-radius-topright(@r);
	.border-radius-bottomright(@r);
}



.border-radius-mixin(@r) {
	border-radius: @r;
}

.border-radius-mixin(@r, top) {
	.border-radius-top(@r);
}

.border-radius-mixin(@r, bottom) {
	.border-radius-bottom(@r);
}

.border-radius-mixin(@r, left) {
	.border-radius-left(@r);
}

.border-radius-mixin(@r, right) {
	.border-radius-right(@r);
}


