@import '~less/include.less';
.editValue-control.select-employees-editValue {

	> .auto {
		// display: flex;
		// flex-direction: column;
		// flex-grow: 1;
		// flex-shrink: 1;
		> .control-region {
			// display: flex;
			// flex-direction: column;
			// flex-grow: 1;
			// flex-shrink: 1;
			// overflow: auto;
			> .select-control-box.select-employees {
				// display: flex;
				// flex-direction: column;
				// flex-grow: 1;
				// flex-shrink: 1;
				// overflow: auto;
				> :not(ul) {
					flex-grow: 0;
					flex-shrink: 0;
				}
				> ul {
					flex-grow: 1;
					flex-shrink: 1;
					overflow: auto;
					margin: 0;
				}

				.select-label-searcher {
					padding: 0 @gutter;
				}

			}
		}
	}
}