@import '~less/include.less';

.filters-control-item {
	position: relative;
	border-top: 1px solid rgba(0,0,0,.05);

	> small {
		position: absolute;
		font-size: 10px;
		top: 2px;
		left: @gutter;
		text-transform: lowercase;
		font-weight: bold;
	}

	> span {
		.ff-action();
		font-size: 16px;
		min-height: 36px;
		display: block;
		padding: 10px 15px;
		padding-right: @gutter2;
		//margin-right:@gutter2;
		padding-top: 13px;
		padding-bottom: 7px;
		//margin: -10px -15px;
		.hover-darken();
	}

	> button.close {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: @gutter2;
		.hover-darken();
	}

	&.valued {
		background-color: #ffffdd;

		> span:nth-child(2) {
			color: @brand-primary;
			font-weight: bold;
		}
	}
}
