.offset-mixin-l(@px:@gutter){
	margin-left:@px;
}
.offset-mixin-r(@px:@gutter){
	margin-right:@px;
}
.offset-mixin-t(@px:@gutter){
	margin-top:@px;
}
.offset-mixin-b(@px:@gutter){
	margin-bottom:@px;
}
.offset-mixin-h(@px:@gutter){
	.offset-mixin-l(@px);
	.offset-mixin-r(@px);
}
.offset-mixin-v(@px:@gutter){
	.offset-mixin-t(@px);
	.offset-mixin-b(@px);
}
.offset-l{ .offset-mixin-l(); }
.offset-r{ .offset-mixin-r(); }
.offset-b{ .offset-mixin-b(); }
.offset-t{ .offset-mixin-t(); }
.offset-v{ .offset-mixin-v(); }
.offset-h{ .offset-mixin-h(); }
.offset-all{ .offset-mixin-h(); .offset-mixin-v(); }

.with-b-offset{
	> * {
		.offset-mixin-b();
	}
}



.padding-mixin-l(@px:@gutter){
	padding-left:@px;
}
.padding-mixin-r(@px:@gutter){
	padding-right:@px;
}
.padding-mixin-t(@px:@gutter){
	padding-top:@px;
}
.padding-mixin-b(@px:@gutter){
	padding-bottom:@px;
}
.padding-mixin-h(@px:@gutter){
	.padding-mixin-l(@px);
	.padding-mixin-r(@px);
}
.padding-mixin-v(@px:@gutter){
	.padding-mixin-t(@px);
	.padding-mixin-b(@px);
}
.padding-l{ .padding-mixin-l(); }
.padding-r{ .padding-mixin-r(); }
.padding-b{ .padding-mixin-b(); }
.padding-t{ .padding-mixin-t(); }
.padding-v{ .padding-mixin-v(); }
.padding-h{ .padding-mixin-h(); }
.padding-all, .padding-vh {
	.padding-mixin-h();
	.padding-mixin-v();
}
