button:disabled {
    cursor: not-allowed;
}
button.waiting {
    cursor:progress;
}

.btn-flat-text {
    padding: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-bottom: 1px dashed #ddd;
    border-radius: 0;
    line-height: 20px;
    height: 20px;
    &:Hover {
        color: steelblue;
        background-color: transparent;
        border-bottom-color: steelblue;
    }

    > label {
        margin: 0;
    }
}
.btn-group {
    &.inline {
        display: inline-block;
        > .dropdown-toggle {
            display: block;
        }
    }
}