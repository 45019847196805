@import '~less/include.less';

.realty-box {
}
.realty-box-name {
		height: 47px;
		.realty-name {
				big {
						.ff-header();
						font-weight: 500;
						font-size: 18px;
				}
		}
}