@import '~less/include.less';

.tasks-container { 
	height:100%;
	overflow-x:hidden;
	overflow-y:auto;
	.v-scrollbar();
	&.tasks-inline{
		padding:0.1px @gutter;
		.tasks-list-region{
			margin-bottom: @gutter2;
		}
	}

}
