[data-placeholder] {
	position: relative;

	&.inline {
		display: inline-block;
	}


	&:after {
		position: absolute;
		bottom: 0;
		left: 0;
		padding-left: 14px;
		content: attr(data-placeholder);
		.ff-header();
		font-weight: 500;
		font-size: 10px;
		z-index: 3;
	}

	> input.form-control {
		padding-bottom: 12px;
	}
}
span[data-placeholder] {
	display: inline-block;
}