@import '~less/include.less';

.object-card-layout {
    .core-stats {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 10px;
        > * {
            flex-grow: 1;
            flex-shrink: 1;
            border: 1px solid #00000010;
            padding: 5px;
            > div {
                .ff-header();
                font-size: 36px;
                text-align:center;
            }
        }
    }
    .links {
        display: flex;
        flex-direction: column;
        width: 100%;
        > .row {
            //width: 100%;
            margin: 0 10px;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            &:not(:first-child) {
                border-top: 1px solid #00000010;
            }
            > * {
                width: 50%;
                flex-grow: 1;
                flex-shrink: 1;
                padding: 5px;
            }
            > :first-child {
                text-align: right;
                color: @brand-primary;
                font-weight: bold;
            }
            > :last-child {
                text-align: left;
                label {
                    display: block;
                    width: 100%;
                    .ff-header();
                    font-size: 24px;
                }

            }
        }
    }



}

.object-offers-layout {



    .info-container {
        padding: 5px;
        > .wrapper {
            box-shadow: 0 0 5px 3px #00000010;
            padding: 10px;
            background-color: cornsilk;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            > *:not(.long-separator) {
                flex-grow: 0;
                flex-shrink: 0;
            }
            > .item {

                border-left: 1px dotted transparent;
                margin: 1px;
                &:not(:first-child):not(.active):not(:hover) {
                    border-left-color: #777;
                }

                &.active + .item.item.item.item, &:hover + .item.item.item.item {
                    border-left-color: transparent;
                }

                &.active.active.active.active.active {
                    background-color: @brand-primary;
                    color: white;
                    > * {
                        color: white;
                    }
                    border-radius: 4px;
                }


                &:not(.active):hover {
                    background-color: rgba(0,0,0,.1);
                    border-radius: 4px;
                    border-left-color: transparent;
                    cursor: pointer;
                }

                > * {
                    padding-left: 15px;
                    padding-right: 15px;
                }
                > div {
                    text-align:center;
                }
            }
            > .long-separator {
                flex-grow: 1;
                flex-shrink: 1;
            }
        }
    }

    .offers-list {

        .offer-item {
            margin: 4px;
            box-shadow: 0 0 5px 3px rgba(0,0,0,.1);
            background-color: white;
            > * {
                > i, > b {
                    color: #aaa;
                }
            }
        }

        &.as-table {
            display: table;
            width: 100%;
            .offer-item {
                display: table-row;
                > .cell {
                    display: table-cell;
                    vertical-align: top;
                    > div {
                        height: 20px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.as-ui-atom {
    vertical-align:middle;
    > i, > b {
        font-weight: 500;
        font-style: normal;
        display: block;
        font-size: 10px;
        height: 12px;
        color: #777;
    }
    > b {
        line-height: 10px;
        height: 13px;        
    }
    > * {
        padding-left: 4px;
        padding-right: 4px;
    }
    > div {        
        padding-top: 0px;
        padding-bottom: 0px;
        font-weight: 500;
        height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &.clickable {
        > div {
            color: @brand-primary;
        }
        > b {
            color: darken(@brand-primary, 20);
        }
        &:hover {
            background-color: rgba(0,0,0,.1);
            cursor:pointer;
        }
    }

}

.as-ui-molecule {
    display: flex;
    flex-direction: row;
    width: 100%;
    &.space-between {
        justify-content: space-between;
    }
    &.space-around {
        justify-content: space-around;
    }
    > .fixed {
        flex-grow: 0;
        flex-shrink: 0;
    }
    > .elastic {
        flex-grow: 1;
        flex-shrink: 1;
    }
}