@import '~less/include.less';

.tasks-static-board {
	.static-board();

	&.hide-bad.hide-bad .l-bad {
		display: none
	}

	&.hide-past.hide-past .l-past {
		display: none
	}

	&.hide-today.hide-today .l-today {
		display: none
	}

	&.hide-tomorrow.hide-tomorrow .l-tomorrow {
		display: none
	}

	&.hide-later.hide-later .l-later {
		display: none
	}

	&.hide-nextweek.hide-nextweek .l-nextweek {
		display: none
	}

	&.hide-thisweek.hide-thisweek .l-thisweek {
		display: none
	}



	.lists .list.l-bad > div {
		background-color: fade(@brand-danger,30);
	}

	.lists .list.l-past > div {
		background-color: fade(@brand-warning,30);
	}

	.lists .list.l-today > div {
		background-color: fade(@brand-success,30);
	}

	.lists .list .item {
		border-radius: 3px;
	}
}
