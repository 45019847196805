@import 'less-root/fontawesome.less';

header {
    .header-links {
        width: auto;
        white-space: nowrap;
        // display: flex;
        // flex-direction: row;
        .header-link {
            &:first-child {
                margin-left: 30px;
            }
            width: 40px;
            height: 100%;
            // flex-grow: 0;
            // flex-shrink: 0;
            display: inline-flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            &:Hover {
                background-color: rgba(0,0,0,.1);
                cursor: pointer;
            }
            > b {
                height: 100%;
            }
            .icon {
                vertical-align: middle;
                display: inline-block;
                font-style: normal;
                font-size: 18px;
                &.realty {
                    .icon('building-o');
                }
                &.owner {
                    .icon('user');
                }

            }
        }
    }
}