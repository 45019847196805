.timepicker-inline {
	display: inline-block;
	vertical-align:top;
}

.ui-timepicker {

	input {
		//font-size: 20px;
		text-align: center;
		border-color: transparent;
		width: 1.5em;
		height: 1em;
		line-height: 1em;
		display: block;

		&[type="number"]::-webkit-outer-spin-button,
		&[type="number"]::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type="number"] {
			-moz-appearance: textfield;
		}
	}

	.ui-timepicker-items {
		font-size: 0;

		> * {
			position: relative;
			display: inline-block;
			font-size: 48px;
			line-height: 1em;
			//height: 1em;
			vertical-align: middle;
		}

		.ui-timepicker-separator {
			> span {
				font-size: 60%;
				font-weight: bold;
				display: block;
			}
		}

		.ui-timepicker-item {
			.ui-timepicker-item-changeButton {
				display: block;
				text-align: center;
				border-radius: 2px;
				.hover-darken();
				.unselectable();
				> span {
					font-size: .6em;
					opacity: .5;
				}
			}

			.ui-timepicker-item-increase {
				> span {
					.icon('chevron-up');
					//font: initial;
					display: block;
				}
			}

			.ui-timepicker-item-decrease {
				> span {
					.icon('chevron-down');
					display: block;
				}
			}
		}

		&.no-hours {
			.ui-timepicker-hours, .ui-timepicker-separator-hours, .ui-timepicker-separator-minutes {
				display: none;
			}
		}

		&.no-minutes {
			.ui-timepicker-minutes, .ui-timepicker-separator-minutes, .ui-timepicker-separator-seconds {
				display: none;
			}
		}

		&.no-seconds {
			.ui-timepicker-seconds, .ui-timepicker-separator-seconds {
				display: none;
			}
		}
	}
}