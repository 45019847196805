@import '~less/include.less';

.departments-layout{
	padding:@gutter;
	height:100%;
	display: flex;
	flex-direction:column;
	overflow:auto;
}
.add-department{
margin-bottom:@gutter;
}
.department-item {
	.block();
	.block-shadow(true);
	margin-bottom: @gutter/2;

	header {
		font-size: 20px;
		font-weight: 500;
	}

	> div {
		position:relative;
		padding:@gutter/2 @gutter;
	}

}
.department-children{
	margin-left:@gutter2;
}