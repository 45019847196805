@import '~less/include.less';

.send-by-email-editor{
	background-color:#eee;
	height:100%;
	.actions{
		//.container-fluid;
		.container-fixed();
		padding:@gutter;
		padding-bottom:0;
		> .row {
			.clearfix;
		}
		.acion-col{
			display:inline-block;
			> div {
				margin-bottom:4px;
			}
		}

		label:first-child{
			width:90px;
		}

		label{
			display:inline-block;
			margin-bottom:0;
		}
		input{
			display:inline-block;
		}
		input:not([type=radio]){
			width: 200px;
		}
	}

	.preview-container {

		height:~"calc(100% - 91px)";

		.holder {
			height:100%;
			overflow:auto;
			padding:@gutter;
			> div{
				padding:@gutter;
				box-shadow:0px 0px 15px 5px rgba(0,0,0,.1);
				background-color:#fff;
				> table {
					margin-left:auto;
					margin-right:auto;
				}
			}
		}
	}

}