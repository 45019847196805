@import '~less/include.less';

.deals-emps-stats {
	padding:@gutter;
	.deals-emps-stats-items{
		margin-top:@gutter;
		.employee {
			> * {
				display:inline-block;
				vertical-align:middle;
				width:80px;
				text-align:center;
				font-size:24px;
				color: @brand-primary;
				font-weight:900;
				&.rating {
					color:@brand-success;
				}
				> i {
					display:block;
					text-align:center;
					.ff-header();
					font-weight:400;
					font-size:10px;
					color:#444;
					margin-top:-8px;
					font-style:normal;
				}
				& + * {
					padding-left: @gutter/2;
				}
				&:first-child{
					width:200px;
					overflow:hidden;
					text-align:left;
					white-space:nowrap;
					.ff-text();
					font-weight:500;
					font-size:16px;
					color:#444;
					padding:10px 0;
					&:Hover{
						cursor:pointer;
						color:@brand-primary;
					}
				}
			}
			& + .employee {
				border-top:1px solid rgba(0,0,0,.1);
			}
		}
	}
}