html {
	height:100%;
}
body {
	//padding-top:48px;
	height:100%;
	background-color:#fbfbfb;
	&:not(.no-submenu){
		@media (min-width:@screen-sm-min){
			//padding-left:51px;
		}
	}

	.ff-text(300);
	overflow: hidden;
}
