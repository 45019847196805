@import '~less/include.less';

.recordset-view {

	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;


	.result {
		flex-grow: 1;
		//padding: 0 0 0 300px;
		&.scrolled-region {
			height: 100%;
			> *:first-child {
				height: 100%;
				overflow-y:scroll;
			}
		}
	}

	&.main-content {
		padding: 0;
		margin: 0 -15px;
		width: calc(100% + 30px);

		.result.scrolled-region {
			> *:first-child {
				padding-left: 15px;
			}			
		}
	}

	> .actions-panel-left {
		flex-grow: 0;
		flex-shrink: 0;
		box-sizing: border-box;
		overflow-y: auto;
	}

	.hide-left-panel, .show-left-panel {
		display: none;
	}

	.media-width-down(800px, {
		> .actions-panel-left {

			// &::after {
			// 	position: fixed;
			// 	content: @fa-var-filter;
			// 	width: 40px;
			// 	height: 40px;
			// 	top: 50px;
			// 	left: 5px;
			// 	background-color: gray;
			// 	border-radius: 5px;
			// 	z-index:-1;
			// 	color: white;
			// 	font-family: 'FontAwesome';
			// 	font-size: 30px;
			// 	text-align: center;				
			// }			

			background-color: @brand-success;
			position: fixed;
			top: 50px;
			left: -@actions-panel-width;
			height: calc(100% - 50px);
			z-index: 5;
			&:Hover {
				left: 0px;
				width: 100%;
				&::after {
					visibility: hidden;
				}
				& ~ .hide-left-panel {
					display: block;
				}
				& + * {
					&::before {
						position: fixed;
						content: @fa-var-arrow-left;
						width: 40px;
						height: 40px;
						bottom: 5px;
						left: 5px;
						background-color: gray;
						border-radius: 5px;
						z-index: 5;
						color: white;
						font-family: 'FontAwesome';
						font-size: 30px;
						text-align: center;		
					}
				}
			}
		}
		.result {
			padding: 0 0 0 0;
		}

		.show-left-panel, .hide-left-panel {
			position: fixed;
			width: 50px;
			height: 50px;
			background-color: transparent;
			border: none;
			box-shadow: none;
			//border-radius: 5px;
			z-index:6;
			color: cornflowerblue;
			font-family: 'FontAwesome';
			font-size: 30px;
			text-align: center;
			padding: 0;
			&:hover {
				background-color: rgba(0,0,0,.1);
			}
		}

		.show-left-panel {
			&::after {
				content: @fa-var-filter;
			}
			top: 0px;
			right: 51px;
		}
		.hide-left-panel {
			&::after {
				content: @fa-var-file;
				// position: relative;
				// top: -3px;
			}
			top: 0px;
			right: 51px;	
		}

		// .actions-panel-left:not(.showing) ~ .show-left-panel {
		// 	display: block;
		// }

		&:not(.panel-on) {
			.show-left-panel {
				display: block;
			}
			.hide-left-panel {
				display: none;
			}
		}

		&.panel-on {
			.show-left-panel {
				display: none;
			}
			.hide-left-panel {
				display: block;
			}
			.actions-panel-left {
				left: 0px;
				width: 100%;
			}
		}


	});




}
