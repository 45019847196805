@import '~less/include.less';

.phone-control {

	input {
		display: block;
		width: 100%;
		font-size: 16px;
		font-weight: 400;
		height: 32px;

		&[type=number] {
			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}

		border-color: transparent;
		box-shadow: none;
	}


	> div {
		&:extend(.form-control);
		display: table;
		width: 100%;
		padding: 0;

		> div {
			display: table-cell;
		}
	}

	.ui-phone-country {
		width: 50px;

		&:before {
			content: '+';
			font-size: 20px;
			position: absolute;
			left: 3px;
			z-index: 3;
		}

		input {
			text-align: right;
		}
	}

	.ui-phone-region {
		width: 60px;
	}
	.ui-phone-add {
		width: 65px;
	}


	.modern-edit {
		> *:not(:first-child) {
			border-left: 2px solid rgba(0,0,0,.1);
		}
		border: 2px solid rgba(0,0,0,.1);
		border-radius: 3px;
		margin: @gutter 0;

		.ui-phone-number {
			max-width: 200px;
		}
		.ui-phone-add {
			min-width: 150px;
		}
	}

}
