.ui-datepicker.ui-widget.ui-widget-content:not(.ui-datepicker-inline) {
	.block();
	.block-shadow(true);
	padding: @gutter / 3;
}

.ui-datepicker-btn() {
	border-radius: 2px;
	.hover-darken();
	.hover-nounderline();
}
#ui-datepicker-div{
	display:none;
}

.datepicker-inline{
	display: inline-block;
}

.ui-datepicker {

	.ui-datepicker-header {
		position: relative;

		.ui-corner-all {
			position: absolute;
			top: 0;
			display: block;
			line-height: @gutter2;
			width: @gutter2;
			text-align: center;
			.ui-datepicker-btn();
		}

		.ui-datepicker-prev {
			left: 0;
		}

		.ui-datepicker-next {
			right: 0;
		}

		.ui-datepicker-title {
			.ff-header();
			font-weight: 500;
			line-height: @gutter2;
			padding: 0 5px;
			margin: 0 @gutter2;
			text-align: center;
		}

		.ui-icon-circle-triangle-w {
			.icon('chevron-left');
		}

		.ui-icon-circle-triangle-e {
			.icon('chevron-right');
		}
	}

	.ui-datepicker-calendar {
		margin: auto;

		th {
			width: @gutter2;
			text-align: center;
		}

		td {
			//.ff-header();
			position: relative;
			width: @gutter2;
			text-align: center;
			line-height: 30px;

			a {
				display: block;
				height: 100%;
				width: 100%;
				font-weight: 400;
				color: #444;
				.ui-datepicker-btn();
				position: relative;
				z-index: 1;
			}
		}

		.ui-datepicker-week-end {
			color: @brand-danger;

			a {
				color: @brand-danger;
			}
		}

		.ui-datepicker-today {
			a:after {
				position: absolute;
				content: '';
				width: 100%;
				height: 5px;
				background-color: @brand-success;
				border-radius: 2px;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				left: 0;
				bottom: 0;
				opacity: .5;
				z-index: 1;
			}
		}

		.ui-datepicker-current-day {
			a:before {
				position: absolute;
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				background-color: #efefef;
				z-index: -1;
				border-radius: 2px;
			}
		}

		.ui-datepicker-other-month{
			a {color:#ddd;}
		}
	}
}