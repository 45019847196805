.dropdown-toggle.labeled {
	> * {
		display: block;
	}
	> label {
		margin: 0;
		font-size: 10px;
		margin-top: -6px;
		text-align: left;
	}
	&.btn-default > label {
		color: cornflowerblue
	}
}
