@import '~less/include.less';

.edit-value-view > .input-control {
	padding: 3px 0;
}



.v2-modal-content-container > .edit-value-view {
	display: flex;
	flex-direction: column;
	overflow: auto;

	> .input-control {
		padding: @gutter;
	}
}

.edit-value-view {
	// .select-control {
	// 	min-height: 30px;
	// 	&:not(:hover) {
	// 		.select-entry:not(.selected) {
	// 			display: none;
	// 		}
	// 	}
	// }


	.select-control.inline-buttons {
		.select-entries {
			display:flex;
			flex-direction: row;
			flex-wrap: wrap;
			.select-entry {
				.select-icon {
					display: none;
				}
				.select-entry-label {
					padding: 2px 5px;
				}
				&.selected {
					background-color: @brand-primary;
					.select-entry-label {
						color: white;
					}
				}
			}
		}

		&.multiple {
			.select-entries {
				.select-entry:not(.selected) {
					background-color: rgba(0,0,0,.1);
				}
			}
		}

	}

}