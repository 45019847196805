@import '~less/include.less';

.deals-static-board{
	.static-board();
	.lists .list .deal {
		margin:5px;
		* {
			white-space:normal;
		}
	}
}

.hide-initial.hide-initial &.l-initial { display:none}
.hide-qualify.hide-qualify &.l-qualify { display:none}
.hide-search.hide-search &.l-search { display:none}
.hide-offer.hide-offer &.l-offer { display:none}
.hide-show.hide-show &.l-show { display:none}
.hide-negotiations.hide-negotiations &.l-negotiations { display:none}
.hide-deal.hide-deal &.l-deal { display:none}
.hide-income.hide-income &.l-income { display:none}

