.v-scrollbar(){

	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar{
		//left:-20px;	
		width:4px;
		//background-color:transparent;
	} 
	&::-webkit-scrollbar-track{
		/*height:50%;
		width:4px;*/
		background-color:transparent;
	} 
	&::-webkit-scrollbar-thumb{
	//background-color:rgba(0,0,0,.3);
	background-color:@brand-primary;
	border-radius:2px;
	width:2px;
	}
	&::-webkit-scrollbar-button:end{height: 8px; }
	&::-webkit-scrollbar-button:start{height: 8px; }
}

.h-scrollbar(){

	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar{
		height:4px;
		/*left:-20px;	
		width:4px;
		background-color:transparent;*/
	} 
	&::-webkit-scrollbar-track{
		//height:50%;
		//width:4px;
		background-color:transparent;
	} 
	&::-webkit-scrollbar-thumb{
	background-color:rgba(0,0,0,.3);
	background-color:@brand-primary;
	border-radius:2px;
	height:2px;
	}
	//&::-webkit-scrollbar-button:end{height: 8px; }
	//&::-webkit-scrollbar-button:start{height: 8px; }
}