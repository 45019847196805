@import '~less/include.less';

.realtyobject-in-list {
	.data-cnt {
		font-size: 0;

		> * {
			display: inline-block;
			font-size: @font-size-base;
			margin-right: 5px;
			vertical-align:top;
		}
	}

	.floor {
		width: 30px;
	}

	.square {
		width: 50px;
	}

	.offers{
		.offer{
			> * {
				display:block;
			}
			small {
				color:#777;
				font-size:10px;
			}
		}
	}
}
