@emptyRule: {};

.string_add(@a, @b, @s: '') {
	@res: "@{a}@{s}@{b}";
  }

.string_repeat(@char, @separator, @len) {
@res: .iterate(1, @char)[];
.iterate(@index, @c) when (@index < @len) {
	@res: .iterate(@index + 1, .string_add(@c, @char, @separator)[])[];
}
.iterate(@index, @c) when (@index = @len) {
	@res: @c
}
}
  .element_and_next_n_elements(@count, 
	  @rule: @emptyRule, 
	  @firstRule: @emptyRule, 
	  @lastRule: @emptyRule, 
	  @middleRule: @emptyRule
  ) {
	//@minusCount: @count - 1;
	.iterate(@index, @src, @end: @count) when (@index < @end) {
	  @aster: .string_repeat("*", " + ", @index)[];
	  @sel: "& + @{aster}";
	  @res: .iterate(@index + 1, .string_add(@src, @sel, ", ")[], @end)[@res];
	}
	.iterate(@index, @src, @end: @count) when (@index = @end) {
	  @aster: .string_repeat("*", " + ", @index)[];
	  @sel: "& + @{aster}";
	  @res: .string_add(@src, @sel, ", ")[];
	}
	.iterate(@index, @src, @end: @count) when (@index > @end) {
	  @res: @src;
	}
  
	// ALL-ELEMENTS
	  //@width: 100% / (@count + 1);
	@selector_txt: .iterate(1, "&")[@res];
	@selector: ~"@{selector_txt}";
	@{selector} {
		  //box-sizing: border-box;
		  //width: @width;
	  @rule();
	}
	
	  // MIDDLE-ELEMENTS
	@middle_selector_txt: .iterate(2, "& + *", @count - 1)[@res];
	@middle_selector: ~"@{middle_selector_txt}";	
	  &:before {
		  content: @middle_selector_txt;
	  }
	  @{middle_selector} {
		  @middleRule();
	  }
  
	  // FIRST-ELEMENT
	  & {
		  @firstRule();
	  }
  
	  // LAST-ELEMENT
	  @last_aster: .string_repeat("*", " + ", @count)[];
	  @last_sel: ~"& + @{last_aster}";
	  @{last_sel} {
		  @lastRule();
	  }
	  
	  
  }
  
  .make-container-with-borders-3() {
	  @BR: 3px;
	  @WidthReduce: 0px;
	  @BG: rgba(0,0,0,.02);
	  @DBG: rgba(0,0,0,.1);	
	  @LBG: rgba(255, 255, 0, .05);
	  @DBC: 1px solid @DBG;
	  @LBC: 1px solid @LBG;
	  
	  display: flex;
	  flex-direction: row;
	  flex-wrap: wrap;
	  box-shadow: 0 0 5px 3px rgba(0,0,0,.1);

	  border-radius: @BR;

	  //background-color: white;

	  > * {
		  box-sizing: border-box;
		  width: 100%;
		  &:not(:last-child) {
			  //border-bottom: 1px solid gray;
		  }
		  
		  &.two {
			  .element_and_next_n_elements(1, @rule: {
					  width: 50%;
			  }, @lastRule: {
						  border-right: none;
			  });
		  }
		  &.three {
			  .element_and_next_n_elements(2, @rule: {
					  width: 100%/3;
			  }, @lastRule: {
						  border-right: none;
			  });
		  }
		  &.four {
			  .element_and_next_n_elements(3, @rule: {
					  width: 25%;
			  }, @lastRule: {
						  border-right: none;
			  });
		  }
		  
	  }
  }
  

  @uiColumnWidth: 300px;
  @uiColumnPadding: 5px;
  @uiColumnMargin: 5px;
  
  @uiHoverBg: rgba(0,0,0,.1);
  
  
  .box-2-color-mixin(@BGC: #fafafa, @LBC: rgba(255,255,255,.15), @DBC: rgba(0,0,0,.05)) {
	  background-color: @BGC;
	  > * {
		  border-top: 1px solid @LBC;
		  border-left: 1px solid @LBC;
		  border-bottom: 1px solid @DBC;
		  border-right: 1px solid @DBC;
	  }
  }
  
  
  
  .clean-button-mixin() {
	  position: relative;
	  &:focus {
		  outline: none;
	  }
	  &:active {
		  > * {
			  top: 1px;	
		  }			
	  }
	  &:focus:active {
		  outline: none;
	  }
	  &:Hover {
		  cursor: pointer;
		  background-color: @uiHoverBg;
	  }
  }
  
  .system-lines-mixin() {
		  > i, > b {
		  font-style: normal;
		  font-weight: 400;
		  position: absolute;
		  //.ff-header1();
		  text-transform: uppercase;
		  font-size: .55em;
		  padding-left:(0.3/0.55em);
		  padding-right:(0.3/0.55em);
		  white-space: nowrap;		
	  }
	  > i {
		  top: .05em;		
	  }
	  > b {
		  bottom: 0;		
	  }		
  }
  
  .make-ui-line() {
	  position: relative;
	  width: 100%;
	  display: flex;
	  flex-direction: row;
	  overflow: hidden;
  
	  > * {
		  flex-shrink: 1;
		  flex-grow: 1;		
	  }
	  > button, > .icon, > .inner-button {
		  font-size: inherit;
		  padding: 0 .2em;
		  position: relative;
		  border: none;
		  background-color: transparent;
		  flex-shrink: 0;
		  flex-grow: 0;
		  display: flex;
		  flex-direction: row;
		  align-items: baseline;
	  }
	  > button, > .icon {
		  > * {
			  text-align:center;
			  min-width: 1.2em;
			  align-self: center;
		  }
	  }
	  > button {
		  > * {
			  position: relative;
		  }
		  .clean-button-mixin();
	  }
	  > .inner-button {
		  position: relative;
		  > * {
			  display: inline-flex;
			  flex-direction: row;			
			  font-size: 100%;
			  box-sizing: border-box;
			  padding: 0 .2em;
			  border: none;
			  background-color: transparent;
			  
			  > * {
				  text-align:center;
				  min-width: 1.2em;
				  padding: .2em 0;
			  }
		  }
		  > button {
			  border-radius: 2px;
			  .clean-button-mixin();
			  > * {
				  position: relative;
			  }
		  }
		  
	  }
  }
  
  .make-ui-line-with-molecules(@molecules: true) when (@molecules = true) {
	  > button, > .icon {
		  padding-top: .7em;
		  padding-bottom: .7em;
	  }
	  > .inner-button {
		  padding-top: .5em;
		  padding-bottom: .5em;
	  }
	  .system-lines-mixin();
  }
  
  .make-ui-line-align-items(@layout) {
	  > button, > .icon, > .inner-button {
		  align-items: @layout;
	  }
  }
  
  .ui-line-mixin(@molecules: true, @layout: center) {
	  .make-ui-line();
	  .make-ui-line-with-molecules(@molecules);
	  .make-ui-line-align-items(@layout);
  }
  
  
  
  
  .make-ui-molecule() {
	  position: relative;
	  display: flex;
	  flex-direction: column;
	  padding-top: .7em;
	  padding-bottom: .7em;
	  text-overflow: ellipsis;
	  overflow: hidden;	
	  > * {
		  padding: 0 .3em;
		  line-height: 1;
	  }
  
	  .system-lines-mixin();
	  
	  > big {
		  //.ff-header3();
	  }
	  
	  > span {
		  font-size: 1em;
		  //.ff-header2();
		  //padding-bottom: .15em;
	  }
	  
	  
	  
	  > big {
		  font-size: 1.2em;
		  padding:0 (0.3/1.2em);
	  }
	  
	  > small {
		  //.ff-text1();
		  font-size: .7em;
		  &:not(:empty) {
			  padding-left: (0.3/0.7em);
			  padding-right: (0.3/0.7em);
		  }
	  }
	  
	  > big:not(:empty) ~ small:not(:empty), > span:not(:empty) ~ small:not(:empty) {
		  padding-top: (0.3/0.7em);
	  }
	  
  }
  
  
  