.realty-box-thumb {
    position: relative;
    text-align: center;
		min-height: 20px;
		
    display: flex;
    flex-direction: column;

    > .image {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        transform: scale(1);
        transition: .3s ease-in-out;
        display: block;
        position:relative;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 150, 0, 0.75);
        }
    }

    .realty-box-thumb-label {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        background-color: #00000050;
        color: white;
        font-weight: 500;
        // text-shadow: 1px 1px white, 
        //             0 1px white, 
        //             1px 0 white, 
        //             -1px -1px white, 
        //             -1px 0 white, 
        //             0 -1px white, 
        //             1px -1px white,
        //             -1px 1px white,
        //             0 0 white;
    }
    &:hover {
        cursor: pointer;
        background-color: #00000010;
        img {
            transform: scale(1.3);
        }
    }
}