.clr-metro-1 {
	&.txt { color: #ED1B35; }
	&.bg { background-color: #ED1B35; color: #fff; }
	.metro-bg { background-color: #ED1B35; color: #fff; }
}
.clr-metro-2 {
	&.txt { color: #2a9a41; }
	&.bg { background-color: #2a9a41; color: #fff;  }
	.metro-bg { background-color: #2a9a41; color: #fff;  }
}
.clr-metro-3 {
	&.txt { color: #0078BF; }
	&.bg { background-color: #0078BF; color: #fff;  }
	.metro-bg { background-color: #0078BF; color: #fff;  }
}
.clr-metro-4,  .clr-metro-400{
	&.txt { color: #19C1F3; }
	&.bg { background-color: #0078BF; color: #fff;  }
	.metro-bg { background-color: #0078BF; color: #fff;  }
}
.clr-metro-5 {
	&.txt { color:#894E35; }
	&.bg { background-color:#894E35; color: #fff;  }
	.metro-bg { background-color:#894E35; color: #fff;  }
}
.clr-metro-6 {
	&.txt { color: #F58631; }
	&.bg { background-color: #F58631; color: #fff;  }
	.metro-bg { background-color: #F58631; color: #fff;  }
}
.clr-metro-7 {
	&.txt { color: #8E479C; }
	&.bg { background-color: #8E479C; color: #fff;  }
	.metro-bg { background-color: #8E479C; color: #fff;  }
}
.clr-metro-8, .clr-metro-800 {
	&.txt { color: #E0AB16; }
	&.bg { background-color: #E0AB16; color: #fff;  }
	.metro-bg { background-color: #E0AB16; color: #fff;  }
}
.clr-metro-9 {
	&.txt { color: #999999; }
	&.bg { background-color: #999999; color: #fff;  }
	.metro-bg { background-color: #999999; color: #fff;  }
}
.clr-metro-10 {
	&.txt { color: #93B50E; }
	&.bg { background-color: #93B50E; color: #fff;  }
	.metro-bg { background-color: #93B50E; color: #fff;  }
}
.clr-metro-11 {
	&.txt { color: #4fafaf; }
	&.bg { background-color: #4fafaf; color: #fff;  }
	.metro-bg { background-color: #4fafaf; color: #fff;  }
}
.clr-metro-12 {
	&.txt { color: #708ab9; }
	&.bg { background-color: #708ab9; color: #fff;  }
	.metro-bg { background-color: #708ab9; color: #fff;  }
}
.clr-metro-13 {
	&.txt { color: #350d42; }
	&.bg { background-color: #350d42;  color: #fff; }
	.metro-bg { background-color: #350d42;  color: #fff; }
}
.clr-metro-14 {
	&.txt { color: #de62be; }
	&.bg { background-color: #de62be; color: #fff;  }
	.metro-bg { background-color: #de62be; color: #fff;  }
}
.clr-metro-15 {
	&.txt { color: #2C87C5; }
	&.bg { background-color: #2C87C5; color: #fff;  }
	.metro-bg { background-color: #2C87C5; color: #fff;  }
}



.metro-line1 {
	color: #ED1B35;
}
.metro-line2 {
	color: #2a9a41;
}
.metro-line3 {
	color: #0078BF;
}
.metro-line4 {
	color: #19C1F3;
}
.metro-line5 {
	color:#894E35;
}
.metro-line6 {
	color: #F58631;
}
.metro-line7 {
	color: #8E479C;
}
.metro-line8 {
	color: #E0AB16;
}
.metro-line9 {
	color: #999999;
}
.metro-line10 {
	color: #93B50E;
}
.metro-line11 {
	color: #4fafaf;
}
.metro-line12 {
	color: #708ab9;
}
.metro-line13 {
	color: #350d42;
}
.metro-line14 {
	color: #de62be;
}
.metro-line15 {
	color: #2C87C5;
}