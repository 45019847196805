@import '~less/include.less';

.fastfilters-control {
	.block-create-solid-flat();
	.offset-b();
	> h3 {
		font-weight: 300;
		padding: 5px @gutter;
	}

	.fastfilter-amount {
		.ff-header();
		font-size:15px;
		font-weight:500;
		line-height:10px;
		> * {
			display:block;
			text-align:right;
		}
	}

	> .actions {
		font-size:0;
		button {
			border-color:rgba(0,0,0,.05);
			font-size: 10px;
			font-weight: 400;
			text-transform: uppercase;
			//color: darkcyan;
		}
		> button {
			width: 100%;
		}

		> div > button {
			width: 50%;
		}
	}
}
