@import '~less/include.less';

.visibility-button {

	flex-grow: 0;
	flex-shrink: 0;
	margin: 3px;
	border-radius: 3px;

	> b, .eye-icon {
		display: block;
		width: 100%;
		text-align: center;
	}

	> big {
		display: block;
		visibility: hidden;
		width: 100%;
		text-align: center;
		line-height: .6;
	}

	.eye-icon {
		font-size: 20px;
		width: 30px;
		padding-right: 3px;
		line-height: .8;
	}

	> b {
		font-size: 10px;
		line-height: .6;
	}

	&.visible {
		color: @brand-primary;
		big, .eye-icon, b {
			color: @brand-primary;	
		}
	}

	&.not-visible {
		.eye-icon, b {
			color: #888;	
		}
		&:not(.own-visible) {
			big {
				b {
					visibility: none;
				}
			}
		}
	}

	&.own-visible {
		big {
			visibility: visible;
			color: @brand-primary;
		}
	}


	&.not-visible .eye-icon {
		.icon(eye-slash);
		color: #888;
	}

	&.visible .eye-icon {
		.icon(eye);
		color: @brand-primary;
	}

	&.clickable {
		cursor: pointer;
		&:Hover {
			background-color: rgba(0,0,0,.1);
		}
	}


}