@import '~less/include.less';

.actualization-changes {
	padding: 0 15px;
	.actualization-changes-result {

		padding: 7.5px 0;

		.changes-entry {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 7.5px 0;

			background-color: #fff;
			box-shadow: 0 0 10px 5px rgba(0,0,0,.05);	
			border: 1px solid rgba(0,0,0, .1);


			> * {
				padding: 3px 5px;
				flex-grow: 0;
				flex-shrink: 1;
				align-items: center;				
			}

			.object {
				flex-grow: 1;
				flex-shrink: 1;
				.info {
					 > *:not(:first-child):before {
						 content: ' - '
					 }
				}
			}

			.taked-action {
				// font-weight: bold;
				// font-size: 10px;
				display: flex;
				flex-direction: column;
				width: 150px;
				.ff-header();

				> * {
					padding: 0 5px;
					display: block;
    				width: 100%;
				}

				.what {
					font-size: 12px;
					font-weight: bold;
				}
				.who {
					color: darkcyan;
					font-weight: 400;
				}
				.when {
					color: #444;
				}
			}

			.short-info {
				display:flex;
				flex-direction: column;
				width: 150px;
				> * {
					display: block;
					width: 100%;
					text-align: center;
					
				}
				> i {
					text-align: left;
					font-style: normal;
					.ff-header();
					font-weight: bold;
				}
				> big {
					font-weight: 400;
				}
			}

			.actor, .date {
				font-weight: bold;
				font-size: 10px;
				text-align: right;
			}


			&.rent {
				.short-info {
					color: forestgreen;
				}
			}
			&.sell {
				.short-info {
					color: cornflowerblue;
				}
			}

			&.published {
				background-color: fade(aquamarine, 50);
				.taked-action .what {
					color: forestgreen;
				}
			}
			&.unPublished {
				background-color: fade(pink, 50);
				.taked-action .what {
					color: tomato;
				}
			}
			&.updatedWithNoChanges  {
				background-color: #eee;
			}
			&.created {
				background-color: fade(palegoldenrod, 50);
				.taked-action .what {
					color: forestgreen;
				}
			}
		}

	}

}
