@import '~less/include.less';

.ui2-value-with-label {
    display: flex;
    flex-direction: column;
    padding: 4px;
    [data-role="label"] {
        font-size: 10px;
        line-height: 0.8;
        font-weight: 500;
        padding: 0 4px;
        //color: @brand-primary;
    }
    [data-role="value"] {
        .ff-header();
        font-weight: 500;
        padding: 8px 4px;
        font-size: 15px;
        line-height: 1;
    }
    [data-role="desc"] {
        font-size: 10px;
        line-height: 0.8;
        font-weight: 500;
        padding: 0 4px;
    }
    .colored-values & {
        &.empty {
            [data-role="label"], [data-role="value"] {
                color: #777;
            }
        }
        &.filled {
            [data-role="label"] {
                color: @brand-primary;
            }
            [data-role="value"] {
                color: black;
            }
        }


    }
}

.colored-values {
    &.blue-values {
        .ui2-value-with-label {
            [data-role="value"] {
                color: @brand-primary;
            }
        }
    }
}