@import '~less/include.less';

.contact-value-add-layout {
	.inline {
		table {
			.w100p;
		}
	}

	td:not(:first-child) {
		> * {
			margin-left: -1px;
		}
	}

	.value-region {
		.btn-shadow();
	}

	.submit-text, .dropdown-toggle-label {
		display: none;
	}

	.dropdown-toggle{
		font-size:0;
		> * {
			font-size:@font-size-base;
			vertical-align:middle;
		}
		.caret{
			margin-left:4px;
		}
	}


	.submit-icon {.icon('plus',true);}

	&.type-phone .type-icon {.icon('phone');}
	&.type-mobilePhone .type-icon {.icon('mobile');}
	&.type-email .type-icon {.icon('envelope-o');}
	&.type-site .type-icon {.icon('globe');}
	&.type-social .type-icon {.icon('users');}
	&.type-messenger .type-icon {.icon('comments-o');}
	&.type-other .type-icon {.icon('circle-o');}


	@media(min-width:@screen-sm-min) {
		.submit-text, .dropdown-toggle-label {
			display: inline-block;
		}
		.dropdown-toggle-label{
			margin-left:4px;
		}
		.submit-icon, .type-icon {
			display: none;
		}
	}
}
