@import '~less/include.less';

.media-plate-mixin() {
    position: relative;
    width: 204px;
    // height: 300px;
    border-radius: 3px;
    border: 1px solid #00000050;
    margin: @gutter-half;
    box-shadow: 0 0 7px 2px #00000010;
		background-color: #888;
    > .info {
			position: relative;
			> * {
				padding: @gutter-half;
				font-weight: bold;
				color: cornsilk;
				.ff-econd();
				font-weight: bold;
				//border-top: 1px solid #00000010;
			}
			> .download {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				a {
					color: white;
				}
			}
    }
}
.media-plate-actions-mixin() {
    //position: absolute;
    padding: 3px;
    border-radius: 3px;
    background-color: #fff;
    border:1px solid #00000040;
    display: flex;
    flex-direction: row;
    width: calc(100% - 6px);
    > * {
        display: inline-block;
        height: 26px;
        line-height: 26px;
        border-radius: 2px;
        flex-grow: 1;
        flex-shrink: 1;
        &:hover {
            background-color: #00000010;
            cursor: pointer;
        }
    }
    .fa {
        //width: 30px;
        text-align:center;
    }
}

.edit-medias-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .info-container, .filters-container {
        flex-grow: 0;
        flex-shrink: 0;
    }
    // .medias-container {
    //     flex-grow: 1;
    //     flex-shrink: 1;
    // }


    .info-container {
        //padding-bottom: 10px;
        padding-bottom: 0;
        .ui-tab-triggers {
            display: flex;
            flex-direction: row;
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 10px;
            //background-color: #00004060;
        }

        .ui-tab-trigger {
            padding: 7px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border:1px solid #00000010;
            border-bottom: none;
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: 4px;
            .ff-header();
            &.active {
                background-color: white;
            }
            &:not(.active) {
                background-color: #ffffff50;
                &:Hover {
                    background-color: #ffffff80;
                    cursor: pointer;
                }
            }
        }
    }


    .media-plates {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: auto;
        align-content: flex-start;
        background-color: white;
    }
    .media-plate {

        .media-plate-mixin();

        .thumb {
						margin: 3px;
            position: relative;
            display: flex;
            flex-direction: column;
            background-color: #0000a020;
            justify-content: center;
            align-items: center;
            .bg {
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                background-color: #0000a020;
            }

            img {
                //margin: auto;
                max-width: 196px;
                max-height: 147px;
								border-radius: 3px;
            }
            span {
                display: inline-block;
                .ff-header();
                font-weight: bold;
                background-color: white;
                border:1px solid #00000060;
                //padding: 20px 4px;
                height: 70px;
                width: 40px;
                line-height: 70px;
                text-align:center;
            }
        }

        .filename {
            position: absolute;
            border-top: none;
            padding:0 4px;
            top: -16px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            color: white;
            background-color: #555;
            &:Hover {
                cursor: pointer;
                background-color: @brand-primary;
            }
        }

        .order-button, .edit-buttons {
            .media-plate-actions-mixin();
						margin: 3px;
            .fa-check {
                color: #aaa;
            }
            b {
                padding:0 4px;
                margin-left: 3px;
                //min-width: 30px;
                text-align: center;
                flex-grow: 1;
                flex-shrink: 1;
            }
        }

				.media-desktop-up({
					&:not(:hover) {
						.order-button {
							b {
								color: white;
							}
							i {
								display: none;
							}
							background-color: transparent;
							border-color: transparent;
						}
						.edit-buttons {
							i {
								color: rgba(255,255,255,.4);
							}
							background-color: transparent;
							border-color: transparent;
						}
					}
				});


        // .order-button {
        //     top: 3px;
        //     left: 3px;
				// 		top: 0px;
        //     left: 0px;
				// 		position: absolute;
        // }
        // .edit-buttons {
        //     bottom: 3px;
        //     left: 3px;
        // }

        &.primary {
            .order-button {
                background-color: #cfc;
                .fa-check, b {
                    color: royalblue;
                }
            }
        }

    }


    .upload-medias {
        flex-grow: 1;
        flex-shrink: 1;
        margin: 5px;
        border: 5px dashed #00000020;

        display: flex;
        flex-direction: column;
        // flex-wrap: wrap;
        overflow: auto;

        > div {
            flex-grow: 0;
            flex-shrink: 0;
            padding: 15px;
        }

        > section {
            flex-grow: 1;
            flex-shrink: 1;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            overflow: auto;
						align-items: start;     
        }

        &.drag-over {
            border-color: #00800040;
        }

        .upload-item {
            padding: 5px;
            > .wrapper {
                .media-plate-mixin();
								// overflow: hidden;
								// display: flex;
								// flex-direction: column;
								// position: relative;
								.status {
									position: absolute;
									top: 4px;
									/* font-family: 'Play'; */
									text-transform: uppercase;
									font-weight: bold;
									border-radius: 3px;
									padding: 3px;
									left: 4px;
									background-color: lightgoldenrodyellow;
									box-shadow: 0 0 3px 1px rgba(0,0,0,.1);
									font-size: 10px;
								}
								> :not(.info) {
									flex-grow: 0;
									flex-shrink: 0;
								}
								> .info  {
									flex-grow: 1;
									flex-shrink: 1;
								}
            }

            &.error {
                > .wrapper {
                    background-color: darken(tomato, 30);
                }
            }

            &.uploaded {
                > .wrapper {
                    background-color: darken(darkseagreen, 25);
                }
            }
						.info {
							.name {
								white-space: nowrap;
								text-overflow: ellipsis;
							}
						}
						.wait-container {
							padding: @gutter;
							text-align: center;
							i {
								font-size: 24px;
								color: cornflowerblue;
							}
						}
						.thumb {
							margin: 3px;
							position: relative;
							display: flex;
							flex-direction: column;
							background-color: #0000a020;
							justify-content: center;
							align-items: center;
							height: 147px;
							img {
								max-width: 196px;
                max-height: 147px;
								border-radius: 3px;
								
							}
							div.icon {
								font-size: 24px;
								text-align: center;
								padding: @gutter;
								color: cornflowerblue;
							}
						}

        }

        .edit-buttons {
            .media-plate-actions-mixin();
            margin: 3px;
        }

    }

}