.tdtv-list {
	.ui2-atom.tdtv-item {
		> i {
			font-size: 12px;
			color: #444;
			padding-top: 8px;
		}
		> span {
			padding-bottom: 0;
			white-space: pre-wrap;
		}
		> b {
			color: #888;
			font-weight: normal;
			padding-bottom: 8px;
			&:empty {
				min-height: unset;
				padding-bottom: 8px;
			}
		}
	}


	&.with-borders {
		.ui2-atom.tdtv-item {
			&:not(:first-child) {
				border-top:1px solid rgba(0,0,0,.1);
			}
		}
	}

}