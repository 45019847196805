@import '~less/include.less';

.edit-contact-layout {
	padding: 15px;


	.ui2-box {
		> header {
			background-color: @brand-success;
			color: white;
		}
		margin-bottom: (@gutter * 2);
		footer.ui2-box-actions {
			button {
				background-color: floralwhite;
			}
		}
	}

	.contact-contacts-list {
		.contacts-list-item {
			> .ui2-atom {
				flex-grow:1;
				flex-shrink:1;
				&:first-child {
					width: 40%;
				}
				&:nth-child(2) {
					width: 30%;
				}
			}
			> button {
				flex-grow:0;
				flex-shrink:0;
			}
		}
	}

	.contact-building-item {
		> *:first-child {
			width: 50%;
		}
	}

}