@import '~less/include.less';

.board-task-list-item {
	position: relative;

	.task-state {
		.ff-header();
		font-weight: 500;
		text-transform: uppercase;
		font-size: 9px;
		line-height: 9px;
		text-align: right;
		position: absolute;
		top: 3px;
		right: 3px;
	}

	.task-responsive {
		.ff-header();
		font-weight: 500;
		text-transform: uppercase;
		font-size: 9px;
		line-height: 9px;
		position: absolute;
		top: 3px;
		left: @gutter;
		color:rgba(0,0,0,.5);
	}

	.task-text {
		.ff-text();
		font-weight: 500;
		font-size: 16px;
		margin-top:5px;
	}


	&.past {
		.task-text-todo, .task-date {
			color: @brand-danger;
		}
	}

	&.future {
		.task-text-todo, .task-date {
			color: #777;
		}
	}

	&.future.today {
		.task-text-todo, .task-date {
			color: @brand-primary;
			font-weight: 500;
		}
	}

	.task-info {
		font-size: 10px;

		* {
			color: rgba(0,0,0,.4);
		}

		> * {
			display: inline-block;
			padding-right: @gutter;
		}

		.task-date {
			font-size: 12px;
		}
	}

	.task-icon {
		margin-right: 5px;
	}
}
