@import './two-icons.less';

.two-icons {
	> span {
		position: relative;
	}
	.two-icons-mixin({
		position: relative;
	}, {
		position: absolute;
		bottom: -2px;
		left: -2px;
	});
}