@import '~less/include.less';

.task {
	&.type-common  .task-icon > span {
		.icon('cogs');
		color: #777;
	}

	&.type-call  .task-icon > span {
		.icon('phone');
		color: @brand-primary;
	}

	&.type-email  .task-icon > span {
		.icon('envelope');
		color: @brand-success;
	}

	&.type-outHomeMeeting  .task-icon > span {
		.icon('car');
		color: @brand-warning;
	}
}
.task.task-in-line {
	.block();
	.block-shadow(true);
	.offset-v();
	.hover-darken();
	font-size: 0;

	> * {
		display: inline-block;
		font-size: 16px;
		vertical-align: middle;
		padding: 5px;
	}

	.task-date {
		width: 100px;
		padding: @gutter 5px;

		> * {
			display: block;
			//text-align: center;
		}

		.task-date-value {
			.ff-header();
			font-weight: 500;
		}

		.task-date-help {
			font-size: 10px;
			line-height: 0;
			color: rgba(0,0,0,.5);
		}
	}

	.task-icon {
		width: 30px;
		text-align: center;
	}

	.task-state {
		width: 70px;
		font-size: 10px;
	}

	&.state-inProcess .task-state {
		color: @brand-success;
	}

	&.state-initial .task-state {
		color: @brand-danger;
	}



	.task-todo {
		width: 100%;
		max-width: 100%;
		text-overflow: ellipsis;
		line-height: 1;

		@media (min-width:430px) {
			width: 198px;
		}

		@media (min-width:530px) {
			width: 298px;
		}
	}

	&.past {
		background-color: @brand-danger-lighten;
		.hover-darken(@brand-danger-lighten);

		.task-date .task-date-value {
			color: @brand-danger;
		}
	}

	&.future {
		&.date-today {
			.task-date .task-date-value {
				color: @brand-primary;
			}
		}
	}
}
