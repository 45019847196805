
@empty-rule: {}

.media-phone-portrait-only(@content: @empty-rule) {
    @media (max-width: 599px) and (orientation: portrait) {
    @content();
    }
}
.media-phone-landscape-only(@content: @empty-rule) {
    @media (max-height: 599px) and (orientation: landscape) {
    @content();
    }
}
.media-phone-only(@content: @empty-rule) {
    .media-phone-portrait-only(@content);
    .media-phone-landscape-only(@content);
}

.media-tablet-up(@content: @empty-rule) {
    .media-tablet-portrait-up(@content);
    .media-tablet-landscape-up(@content);
}

.media-tablet-down(@content: @empty-rule) {
    .media-tablet-portrait-down(@content);
    .media-tablet-landscape-down(@content);
}

.media-tablet-portrait-up(@content: @empty-rule) {
    @media (min-width: 600px) and (orientation: portrait) {
    @content();
    }
}
.media-tablet-landscape-up(@content: @empty-rule) {
    @media (min-width: 900px) and (orientation: landscape) {
    @content();
    }
}
.media-tablet-portrait-down(@content: @empty-rule) {
    @media (max-width: 600px) and (orientation: portrait) {
    @content();
    }
}
.media-tablet-landscape-down(@content: @empty-rule) {
    @media (max-width: 900px) and (orientation: landscape) {
    @content();
    }
}

.media-desktop-up(@content: @empty-rule) {
    @media (min-width: 1200px) {
    @content();
    }
}

.media-big-desktop-up(@content: @empty-rule) {
    @media (min-width: 1800px) {
    @content();
    }
}

.media-desktop-down(@content: @empty-rule) {
    @media (max-width: 1200px) {
    @content();
    }
}

.media-big-desktop-down(@content: @empty-rule) {
    @media (max-width: 1800px) {
    @content();
    }
}

.media-width-up(@minwidth, @content: @empty-rule) {
    @media (min-width: @minwidth) {
    @content();
    }
}

.media-width-down(@maxwidth, @content: @empty-rule) {
    @media (max-width: @maxwidth) {
    @content();
    }
}