//@import './env.less';
@import '~less/include.less';

@box-border-radius: 3px;

.ui2-box {

    .ui2-flex-column-mixin();

    > header {
        .ff-header();
        font-weight: 500;
        text-transform:uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 35px;
        .header-text {
            padding-left: @gutter;
        }
    }

    > header:not(.elastic), > footer:not(.elastic), > .fixed {
        .ui2-flex-fixed-item-mixin();
    }

    > .elastic, > :not(header):not(footer):not(.fixed) {
        .ui2-flex-elastic-item-mixin();
        overflow-y: auto;
    }

    .ui2-box-actions {
			@bgclr: antiquewhite;
			display: flex;
			flex-direction: column;
			> button {
					.ui2-button-flat-mixin({
							background-color: darken(@bgclr, 20);
							border-left: 3px solid darken(@bgclr, 40);
					});
					.ff-header();
					text-align: left;
					font-weight:500;
					display: flex;
					flex-direction: row;
					align-items: baseline;
					background-color: @bgclr;
					border-left: 3px solid darken(@bgclr, 20);
					border-radius: 0;
					i {
							margin-right:@gutter;
							flex-grow: 0;
							flex-shrink: 0;
					}
					span {
							flex-grow: 1;
							flex-shrink: 1;
							text-transform: uppercase
					}
			}
			> :last-child {
				.border-radius-mixin(@box-border-radius, bottom);
			}
    }

		&.track-changes {
			&:not(.has-changes) {
				.ui2-box-actions {
					.save-changes {
						background-color: white;
						color: #aaa;
						&:Hover {
							background-color: white!important;
							color: #aaa;
						}
					}
				}
			}
		}


    box-shadow: 0 0 5px 3px #00000010;
    border-radius: @box-border-radius;

		.border-radius-mixin(@box-border-radius);

		> :first-child {
			.border-radius-mixin(@box-border-radius, top);
		}
		> :last-child {
			.border-radius-mixin(@box-border-radius, bottom);
		}

    & + .ui2-box, .block + & {
        margin-top: 15px;
    }


    &.bg-white {
        background-color: white;
    }
}