@import '~less/include.less';

.actions-panel {
	height: 100%;
	//position: absolute;
	width: @actions-panel-width;
	left: 0px;
	top: 0px;
	padding: @gutter;
}
