@import '~less/include.less';

.select-control {
	padding: 0;
	margin: 0;
	//.offset-t;
	.select-control-item {
		list-style: none;
		position: relative;
		padding: 5px @gutter;
		.hover-darken();
		white-space: nowrap;
		overflow-x: hidden;
		text-overflow: ellipsis;

		> * {
			display: inline-block;
			vertical-align: middle;
			font-size: 18px;
		}

		.select-state {
			margin-right: .5em;
			width: 1em;
			opacity: .5;
		}

		.select-label {
		}

		.small-text {
			.ff-header();
			font-size: 10px;
			line-height: 1;
			font-weight: 400;
		}

		.select-description {
			display: block;
			.ff-header();
			font-size: 10px;
			line-height: 1;
			font-weight: 400;
		}

		&.selected .select-state {
			opacity: 1;
		}
	}

	.select-control-item.selected .select-state {
		.icon('dot-circle-o');
	}

	.select-control-item .select-state {
		.icon('circle-o');
	}

	&.multiple .select-control-item.selected .select-state {
		.icon('check-square-o');
	}

	&.multiple .select-control-item .select-state {
		.icon('square-o');
	}
}
.modal-box .select-control{
	margin-left:-@gutter;
	margin-right:-@gutter;
}

.select-control-box .select-label-searcher{
	display:none;
}
.modal-box.fullheight .select-control-box {
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;


	.select-label-searcher {
		display: block;
		height: 35px;
	}

	.select-control {
		height: ~"calc(100% - 35px)";
		overflow-x: hidden;
		overflow-y: auto;
		margin: 0;

		.select-control-item {
			padding-left: 0;

		}
	}
}