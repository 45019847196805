@import '~less/include.less';

.action-buttons-panel {
	.block();
	.padding-vh;

	button {
		width: 100%;
	}
}
