@import '~less/include.less';

.popover-offer-details
{
	/*padding:0;
	ul {
		margin:0;
		padding:0;
		list-style:none;
	}*/

	/*.block;
	ul li {
		.list-item-mixin();
	}*/

	.popover-content{
		padding:0;
		ul{
			.block-list-mixin();
			.metro, .address {
				cursor:pointer;
			}
		}
	}


}