.owner-in-realty-page {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	overflow: auto;
	row-gap: 7.5px;
	column-gap: 7.5px;
	padding: 7.5px;
	.owner-in-realty-info {
		flex-grow: 0;
		flex-shrink: 0;
	}
	.owner-in-realty-objects {
		flex-grow: 1;
		flex-shrink: 1;
		overflow: auto;
	}

}