.contact-details-in-modal {
	.v2-modal-box {
		min-width: 300px;
		max-width: 600px;
	}
	.v2-modal-header {
		background-color: lavender;
	}
	.contact-actions {
		padding: 3px;
		background-color: lavender;
		button {
			margin: 3px;
		}
	}
}