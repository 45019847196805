@board-list-width: 290px;

.board-container {
	height: 100%;
	overflow-x: auto;
	font-size: 0;
	white-space: nowrap;
	padding: 0 @gutter;
	clear: both;

	.h-scrollbar();

	.board-list-placeholder {
		display: none;
	}

	.board-list-region {
		display: inline-block;
		vertical-align: top;
		height: 100%;
		width: @board-list-width;
		white-space: normal;
		font-size: @font-size-base;

		&:not(:last-child) {
			margin-right: @gutter2;
		}

		> div {
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			.v-scrollbar();
			/*.block-create-solid-flat(false, rgba(0,0,0,.05));*/
			padding: 10px;
			margin-left: -10px;
			margin-right: -10px;

			> section {
				padding: 10px;
				margin-left: -10px;
				margin-right: -10px;
				height: ~"calc(100% - 20px)";
				.v-scrollbar();
				overflow-y: auto;
				overflow-x: hidden;
			}
		}


		.block + .block {
			.offset-t();
		}
	}
}


.before-boardcontainer{
	clear: both;
	&.nav{
		height: 42px;
		padding: 0 @gutter;
	}
	& + .board-container{
		height:~"calc(100% - 42px)";
	}
}