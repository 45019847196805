@import '~less/include.less';

body.client {
	.module-board();
}

.clients-search {

	.client.client-in-block {
		display: inline-block;
		vertical-align: middle;
		width: 300px;
		margin-right: @gutter;
	}
}

.client.client-in-block{
	.block();
	.block-shadow(true);
	margin-bottom: 10px; 
	.hover-darken(); 
	position: relative;
}

.client-board {
	.contacts-box.ui2-box {
		.contacts-box-header {
			.contacts-box-header-text {
				padding: 0;
				> i, > b {
					&:empty {
						display: none;
					}
				}
				> span {
					font-size: 11.9px;
					font-weight: 700;
					line-height: 26px;

				}
				// .ff-header();
			}
			button.dark-margined {
				padding: 0 7.5px;
			}
		}
	}
}