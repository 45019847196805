@import '~less/include.less';

.deal.deal-in-block {
	.block();
	//.block-shadow(true);
	//.hover-darken();
	margin-bottom: 10px;
	position: relative;
	border-width: 0px;
	box-shadow: 0 0 4px 1px rgba(0,0,0,0.2);

	&.status-completing{
		background-color:fade(@brand-success, 30%);
	}
	&.status-canceling{
		background-color:fade(@brand-danger, 30%);
	}

	> * {
		position: relative;
		z-index: 1;
	}

	> .bg-holder {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 0;
		border-radius: 2px;
	}

	.system {
		.ff-header();
		font-size: 10px;
		font-weight: 500;
		padding: 2px @gutter;
		white-space:nowrap!important;
		overflow:hidden;
		> span {
			margin-right: 5px;
			display:inline-block;
		}

		.wid {
			//color: rgba(0,0,0,.4);
		}
	}
	&:Hover{
		cursor:pointer;
	}
	&.good {
		&:Hover {
			background-color: fade(darken(@brand-success,30%),80%);
			.name {color:#f0f0f0;}
			.system {color:#f0f0f0;}
			.todo {color:lighten(@brand-success,20%);}
			.action .time {
				background-color: lighten(@brand-success,30%);
				color: @brand-success;
			}
		}

		.action .time {
			background-color: fade(@brand-success,30%);
			color: @brand-success;
		}

		//.good-power();


		.system {
			color: rgba(80,80,80,.7);
			background-color: rgba(255,255,255,.2);
		}
	}

	&.bad {
		&:Hover {
			background-color: fade(darken(@brand-danger,30%),80%);
			.name {color:#f0f0f0;}
			.system {color:#f0f0f0;}
			.todo {color:lighten(@brand-danger,20%);}
			.action .time {
				background-color: lighten(@brand-danger,30%);
				color: @brand-danger;
			}
		}

		//.bad-power();

		.action .time {
			background-color: fade(@brand-danger,30%);
			color: @brand-danger;
		}
		.todo {
			color: @brand-danger;
		}
		.system {
			color: rgba(80,80,80,.7);
			background-color: rgba(255,255,255,.2);
		}
	}

	&.status-inProcess .system .status {
		color: @brand-primary;
	}

	&.status-completing .system .status {
		color: @brand-success;
	}

	&.status-canceling .system .status {
		color: @brand-danger;
	}

	&.status-awaitingOffer .system .status {
		color: @brand-warning - #222;
	}

	&.stage-initial .system .stage {
		color: @brand-danger;
	}

	&.stage-qualify .system .stage {
		color: #fc50ee;
	}

	&.stage-search .system .stage {
		color: #7930e2;
	}

	&.stage-offer .system .stage {
		color: #1a75a4;
	}

	&.stage-show .system .stage {
		color: #1db5b1;
	}

	&.stage-negotiations .system .stage {
		color: #239452;
	}

	&.stage-deal .system .stage {
		color: @brand-success;
	}

	&.stage-income .system .stage {
		color: #189b4b;
	}

	.name {
		.ff-header();
		font-weight: 400;
		font-size: 16px;
		padding: 5px @gutter;
		line-height: 1;
	}

	.comment {
		font-size:12px;
		padding: 5px @gutter;
		line-height: 1;
		font-style:italic;
		> i {
			font-style:normal;
			.icon('comment');
		}
	}

	.action {
		padding: 5px @gutter;
		.ff-header();
		font-weight: 400;
		line-height: 1;
		background-color:rgba(250, 235, 215, .4);
		> * {
			//padding-left: @gutter;
			&:first-child {
				//padding-left: 0;
			}
		}

		.time {
			font-weight: 600;
			padding: 2px @gutter / 3;
			display: inline-block;
			float: left;
			margin-left: -@gutter / 3;
			margin-top: -2px;
			margin-right: @gutter / 3;
			margin-bottom: @gutter / 3;
			border-radius: 2px;
		}
		.todo{
			font-size:12px;
		}
	}

	&.past .action {
		color: @brand-danger;
	}

}
