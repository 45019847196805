@import '~less/include.less';
.entity-edit-page {

	.owners-in-realties-container {
		max-width: 600px;
		margin:auto;
		padding: @gutter 0;
		overflow: auto;
		
		.owner-in-realty-list-item {
			padding: 10px 0;
			&:nth-child(2n) {
				background-color: #00800010;
			}
			.objects-count {
				width: 150px;
			}
			> *:not(.item-name) {
				flex-grow: 0;
				flex-shrink: 0;
			}
			.item-name {
				flex-grow: 1;
				flex-shrink: 1;
			}
			button {
				i {
					font-style: normal;
				}
			}
			&.for-realty {
				.card {
					i {
						.icon('user')
					}
				}
			}
			
			&.for-owner {
				.card {
					i {
						.icon('building-o')
					}
				}
			}
		}

	}

}