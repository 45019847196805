@import '~less/include.less';

body.deal {
	.module-board();



}

.deal-content {

	.flex-row-li {
		display: flex;
		flex-direction: row;
		> div {
			> span {
				line-height: 1;
				display: block;
			}
		}
		> button {
			background-color: transparent;
			border: none;
			margin: 0;
			box-shadow: none;
		}
	}

	&.deal-completed{
		background-color:@brand-success;
	}
	&.deal-canceled{
		background-color:@brand-danger;
	}
	&.deal-completing{
		background-color:fade(@brand-success, 30%);
	}
	&.deal-canceling{
		background-color: fade(@brand-danger, 30%);
	}

	.contacts-box.ui2-box {
		.contacts-box-header {
			.contacts-box-header-text {
				padding: 0;
				a {
					color: white
				}
				> i, > b {
					&:empty {
						display: none;
					}
				}
				> span {
					font-size: 11.9px;
					font-weight: 700;
					line-height: 1;

				}
				// .ff-header();
			}
			button.dark-margined {
				padding: 0 7.5px;
			}
		}
		.contacts-list {
			background-color: white;
		}
	}




}
.modal-box .deal-content {
	margin:-@gutter;
	height:~"calc(100% + 30px)"!important;
}


.progress-in-modal {
	font-family: 'Fira Sans Extra Condensed', sans-serif;
	header {
		font-size: 24px;
		color: #444;
		font-weight: 500;
	}
	progress {
		width: 100%;
		transition:all .3s;
	}
	label {
		display: block;
		width: 100%;
		text-align:center;
		color: #337ab7;
		font-size: 18px;
		font-weight: 400;
	}
    
}

body.deal section.module > section > div .board-list-region header.contacts-box-header {
	height: unset;
	padding: 4px @gutter-half;
}