/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "../vendors/bootstrap/less/variables.less";
@import "../vendors/bootstrap/less/mixins.less";

@import "../vendors/bootstrap/less/buttons.less";

@import "../vendors/bootstrap/less/panels.less";

@import "../vendors/bootstrap/less/list-group.less";

//@import "../vendors/bootstrap/less/grid.less";
// was referenced .container-fluid; - fixed to .container-fixed();

//@import "../vendors/bootstrap/less/utilities.less";
.pull-right-mixin() {
    float: right !important;
}