.icon-preloader{
	text-align:center;
	.fa;
	.icon('refresh');
	//.fa-refresh;
	.fa-spin;
	.fa-2x;
	.fa-fw;

	/*&:extend(.fa);
	&:extend(.fa-refresh);
	&:extend(.fa-spin);
	&:extend(.fa-3x);
	&:extend(.fa-fw);*/
}