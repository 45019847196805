@import '~less/include.less';

.contacts-box.ui2-box {
	.contacts-box-header {
		height: unset;
		background-color: cornflowerblue;
		color: white;
		display: flex;
		flex-direction: row;
		.contacts-box-header-text {
			flex-grow: 1;
			flex-shrink: 1;
			padding-top: 5px;
			padding-bottom: 5px;
			color: white;
			> * {
				color: white;
			}
			b { display: none }
		}
		.fixed {
			flex-grow: 0;
			flex-shrink: 0;
		}
		button {
			color: white;
		}
	}



}

.contacts-box, .contacts-container {
	.contacts-list {
		> *:not(:first-child) {
			border-top: 1px solid rgba(0,0,0,.1);
		}
		> .ui2-molecule.contacts-list-item {
			padding-top: 4px;
			padding-bottom: 4px;
			> .ui2-atom {
				> i {
					color: #333;
					font-size: 11px;
				}
				> b {
					color: #777;
					font-weight: normal;
					&:empty {
						padding:0;
						min-height: unset;
					}
				}
				> i:empty, > b:empty {
					padding: 0;
					min-height: unset;
					& + span {
						padding-top: 7px;
						padding-bottom: 7px;
					}
				}
				> span {
					padding-bottom: 0;
				}				
			}
		}
		i.primary {
			.icon('check-square');
		}
	}
}