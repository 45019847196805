.module-board() {
	section.module {
		> section {
			> div {
				height: 100%;

				.board-list-region header {
					height: @gutter2;
				}

				.deals-region {
					height: ~"calc(100% - @{gutter2})";
					overflow-y: auto;
					.v-scrollbar();
				}
			}
		}
	}
}
