.hover-darken(@c: rgba(0,0,0,.1)) {
	&:Hover {
		background-color: darken(@c,10%);
		cursor: pointer;
	}
}

.hover-lighten(){
	&:Hover{
		background-color:rgba(255,255,255,.1);
		cursor:pointer;
	}
}

.hover-nounderline() {
	&:hover {
		text-decoration: none;

		* {
			text-decoration: none;
		}
	}
}

.hover-darken-btn-transparent(@c: rgba(0,0,0,.1)){
	.hover-darken();
	&:not(:hover){
		box-shadow: none;
	}
	&:focus, &:active {
		background-color:unset;
		outline:none;
		.hover-darken();
	}
}