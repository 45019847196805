.nested-pac-container {
	z-index:2;
	.pac-container.pac-logo{
		//width:300px!important;
		left:unset!important;
		top: unset!important;
		width: auto!important;
		min-width: 300px;
		right:7px;
		&:after{
			content:'';
			background:none;
		}
	}
}