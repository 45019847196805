@import '~less/include.less';

.nf-modal-header-text-mixin() {
    height: 40px;
    .ff-header();
    color: @brand-primary;
    font-size: 28px;
    font-weight: 500;
    text-transform: uppercase;
}


.new-flex-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
    z-index: 2;
}

.new-flex-modal__bg {
    position: absolute;
    width: 100%;
    height: 100%;    
    background-color: rgba(0,0,0,.5);
}

.new-flex-modal__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.new-flex-modal__close.new-flex-modal__close {
    width: 40px;
    height: 40px;
    &.absolute {
        position: absolute;
        top:0;
        right:0;    
    }
    font-size: 36px;
    text-align:center;
    color: rgba(0,0,0,.1);
    &:Hover {
        background-color: rgba(0,0,0,.1);
        color: rgba(0,0,0,.2);
        cursor: pointer;
    }
}

.new-flex-modal-box {
    position: relative;
    display: flex;
    flex-direction: column;
    //min-height: 400px;
    background-color: white;
    box-shadow: 0 0 10px 3px rgba(0,0,0,.1);
    width: calc(100% - 30px);

    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);

    .media-tablet-up({
        width: unset;
        min-width: 600px;
    });

    > header, > footer, > .fixed {
        flex-grow: 0;
        flex-shrink: 0;
    }

    > .elastic {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: auto;
    }


}

.new-flex-modal-header-text {
    .nf-modal-header-text-mixin();
}

.new-flex-modal-box__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    > *:not(button):not(.new-flex-modal__close) {
        flex-grow: 1;
        flex-shrink: 1;
    }
    > button, > .new-flex-modal__close {
        flex-grow: 0;
        flex-shrink: 0;
    }
    > .new-flex-modal-header-text {
        padding: 0 15px;
    }
}
.new-flex-modal-promise-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 7.5px 10px;
    > .separator {
        flex-grow: 1;
        flex-shrink: 1;
    }
    > button {
        flex-grow: 0;
        flex-shrink: 0;
        margin: 0 5px;
        font-weight: 500;
        &.resolve {
            .btn-primary();
        }
    }
}


.new-flex-modal.confirm-dialogue {
    .new-flex-modal-box {
        .flex-modal-content {
            &:first-child {
                padding-top:70px;
            }
            &:not(:first-child) {
                padding-top: 30px;
            }
            padding-bottom: 30px;
            
            padding-left: 30px;
            padding-right: 30px;

            font-size: 18px;
            font-weight: 500;
        }
    }
}