.add-or-join-contacts-to-oir.v2-modal-container {
	.contacts-list-item {
		.join-btn {
			border-radius: 3px;
			margin: 3px;
			&.joined {
				color: tomato;
			}
			&.not-joined {
				color: darkseagreen;
			}
		}
		.realties {
			> span {
				text-align: right;
			}
		}
	}
	.v2-modal-footer, .v2-modal-header {
		background-color: #5f9ea030;
	}
	.v2-modal-footer {
		button {
			border:1px solid rgba(0,0,0,.1);
			&.reject {
				background-color: #efefef;
			}
		}
	}
}