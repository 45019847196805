@import '~less/include.less';

.log-item {
	padding: 5px @gutter;
	position: relative;
	.ff-header();
	font-size: 10px;
	line-height: 1;
	color: #888;

	> * {
		display: inline-block;
		margin-right: 10px;
	}

	.logs-author {
		width: 100%;
		margin-right: 0;
	}

	.logs-text{
		color:#000;
	}
}
.board-list-region.logs {
	.logs-block{
		height:100%;
		.block();
		.block-shadow(true);

		header {
			height:30px;
		}

		.logs-region {
			height: ~"calc(100% - 30px)";
			> div {
				height: 100%;
			}
		}
	}
}