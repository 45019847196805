/*input[type="radio"],
input[type="checkbox"] {
	margin: 3px 0 0;
}*/

.radio-checkbox-input() {
	&[type="radio"], &[type="checkbox"]{
		display:none;
	}
}

.radio-checkbox-label() {
	padding:0;
	margin:0;
}

.radio-checkbox-label-btn() {

	border-color: transparent;
	border-radius: 0;
	margin-right:10px;


	&:hover {
		.input-label {
			border-bottom-color: rgba(0,0,0,.1);
		}
	}

	input:checked ~ .input-label {
		border-bottom-color:orange;
	}
}

.radio-btn-group, .checkbox-btn-group {
	height: auto;


	&.form-control {
		padding-top: 8px;
		font-size: 0;
		display: block;

		&[data-placeholder] {
			padding-bottom: 15px;
		}

		.input-label {
			line-height: 1;
			font-size: .8em;
		}
	}

	.input-label {
		display: block;
		border-bottom: 4px solid transparent;
		color: rgba(0,0,0,.5);
	}



	&.inline {
		.radio, .checkbox {
			display: inline-block;
			vertical-align: top;
			font-size: 14px;
		}

		&.header {
			.input-label {
				.ff-header();
				font-size: 18px;
				font-weight:500;
				text-transform: uppercase;
				color: rgba(0,0,0,.7);
			}

			input:checked ~ .input-label {
				color: orange;
			}
		}
	}

	> label {
		.ff-header();
		font-weight: bold;
		font-size: 10px;
		text-transform: uppercase;
		margin-bottom: 0;
		display: block;
	}

	> label + .radio, > label + .checkbox, .radio:first-child, .checkbox:first-child {
		label {
			padding-left: 0;
		}
	}

	input {
		.radio-checkbox-input();
	}

	.radio, .checkbox {
		margin: 0;

		label {
			.radio-checkbox-label();
			.radio-checkbox-label-btn();
			min-height: unset;
			display: block;
		}
	}



	input:checked ~ .input-label {
		color: rgba(0,0,0,.99);
	}
}

.form-control {
	&.bolder {
		font-weight: 500;
	}
	&.blue {
		color: @brand-primary;
	}
}