.bldclr-bg(@v) {
    @varname: "clr-bld-@{v}";
    background-color: @@varname;
}

.bldclr-color(@v) {
    //color: @@"clr-bld-" + @v;
}
/*
@clr-bld-Ap: #fb5baf;
@clr-bld-A: #ff0027;
@clr-bld-Bp: #ff8500;
@clr-bld-B: #ffe200;
@clr-bld-Cp: #b0fe1d;
@clr-bld-C: #0d9c00;
@clr-bld-Dp: #01ff92;
@clr-bld-D: #006c9c;
@clr-bld-Ep: #888;
@clr-bld-E: #888;
@clr-bld-Fp: #888;
@clr-bld-F: #888;
*/