@import '~less/include.less';

.agency-additional-data {
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: @gutter-half;
	column-gap: @gutter-half;
	row-gap: @gutter-half;

	.data-menu {
		flex-grow: 0;
		flex-shrink: 0;
	}

	.data-list {
		display: flex;
		flex-direction: column;
		overflow: auto;
		column-gap: @gutter-half;
		row-gap: @gutter-half;
	}

}
