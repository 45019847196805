@import '~less/include.less';
@pdf-modal-border-radius: 8px;

.pdf-thing-mixin() {
    // padding: @gutter;
    margin: @gutter;
    border: solid 1px black;
    border-radius: @pdf-modal-border-radius;


    &.valid {
        > .block-state, > .pdf-layer-info > .block-state {
            > .state-icon {
                i {
                    .icon('check');
                    color: @brand-success;
                }
            }
        }
    }
    &.invalid {
        > .block-state, > .pdf-layer-info > .block-state {
            background-color: fade(@brand-danger, 10);
            .state-icon {
                i {
                    color: @brand-danger;
                    .icon('times');
                }
            }
        }
        &.root-layer > footer {
            background-color: fade(@brand-danger, 10);
        }
    }

    &:not(.excluded) {
       > .block-state, > .pdf-layer-info > .block-state {
            .included-icon {
                i {
                    .icon('check-square');
                }
            }
        }
    }
    &.excluded {

        background-color: #777;
        .pdf-block, .pdf-layer {
            background-color: #777;
        }

        > .block-state, > .pdf-layer-info > .block-state {
            .included-icon {
                i {
                    .icon('square-o');
                }
            }
        }
        .errors {
            li {
                color: white;
            }
        }
    }    
    


}
.pdf-layer.root-layer {

}
.pdf-layer-info {
    border-bottom: 1px solid black;
}
.pdf-layer, .pdf-block {
    .pdf-thing-mixin();
}

.pdf-layer {
    // &:not(.excluded), :not(.excluded) & {
    //     &:not(.root-layer) {
    //         background-color: #00408020;
    //     }
    // }

    header {
        padding: @gutter;
        .ff-header();
        font-weight: 500;
        font-size: 18px;
        small {
            font-size: 14px;
            font-weight: 300;
        }
    }

}

.pdf-block {

    background-color: white;
    box-shadow: 0 0 7px 2px #00000020;


    ul {
        margin: 0;
        padding: 0;
        li {
            padding: @gutter;
            list-style-position: inside;
        }
    }


    &.required {
        .block-state header small {
            color: tomato;
        }
    }
    &.optional {
        .block-state header small {
            color: #444;
        }
    }
}
.block-state.ui2-molecule {
    align-items: center;

    > .state-icon, > .included-icon {
        display: flex;
        flex-direction: column;
        font-size: 24px;
        padding: 0 @gutter;
        justify-content: center;
        align-self: stretch;
        > i {
            align-self: center;
            font-style: normal;
        }
    }

}


.pdf-layer.root-layer {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin: 0;
    > .pdf-layer-info, > footer {
        flex-grow: 0;
        flex-shrink: 0;
    }
    > .pdf-layer-info {
        .block-state {
            padding-right: 34px;
        }
    }
    > :not(.pdf-layer-info):not(footer) {
        flex-grow: 1;
        flex-shrink: 1;
    }
    > .pdf-layer-content {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
    > footer {
        border-top: 1px solid black;
        padding: @gutter/2;
        > button {
            margin: @gutter/2;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

.modals-dialog.flex-modal-dialog.pdf-modal-dialog {
    
    z-index: 10;

    .modal-box {
        border-radius: @pdf-modal-border-radius;
        .presentation-dialog-content {
            justify-content: center;
        }
        .presentation-building-process {
            width: 90%;
            align-self: center;
            .message {
                text-align:center;
                .ff-header();
                font-weight: 900;
                font-size: 24px;
            }
        }
    }
}