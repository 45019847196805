@import '~less/include.less';

.employee-card {
	&.fired {
		background-color: #ccc;
	}
	&.real {
		background-color: #f8f8f0;

		.last-action {
			display: block;
			min-height:15px;
		}

		.icon {
			.icon('user-o');
			display: block;
			font-size: 32px;
			color:#ccc;
		}


		&.online {
			.icon{
				color:@brand-success;
			}
		}
	}
}


.tabs-container {
	flex-direction: column;
	.tab-switcher, > header, > footer {
		flex-grow: 0;
		flex-shrink: 0;
	}
	.employees-list-region {
		flex-grow: 1;
		flex-shrink: 1;
		overflow: auto;
	}
}

.flex-in-root-section {
	display: flex;
	height: 100%;
	overflow: auto;
}

.employee-actions-popup {
	width: 300px;
	> div {
		width: 300px;
		background-color: white;
		display: flex;
		flex-direction: column;
		label {
			margin: 0;
			padding: @gutter-half @gutter;
		}
		button {
			text-align: left;
			padding: @gutter-half @gutter;
		}
	}
}