@import '~less/include.less';

@w-seven: 100% / 7;
@w-seven2: @w-seven * 2;
@w-seven3: @w-seven * 3;


.weekdays {
	> div {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		width: 100%;

		b {
			flex-grow: 1;
			flex-basis: @w-seven;
			text-align: center;
			font-weight: 200;
			border-bottom: 1px solid rgba(0,0,0,.1);
			padding: 4px 0;
		}
	}
}
.months-list, .years-list {

	.select-item.current {
		color: @brand-primary;
		font-weight: 500;
	}

	> div {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
		width: 100%;

		> button {
			border: none;
			background-color: transparent;
			height: 32px;
			line-height: 32px;
			box-shadow: none;
			padding: 0 4px;
			flex-grow: 0;
			flex-basis: 100% / 3;

			&:Hover {
				cursor: pointer;
				background-color: rgba(0,0,0,.1);
				border-radius: 4px;
			}
		}
	}
}
.years-list > div > button {
	flex-basis: 25%;
}


.month-controls, .years-list-controls {



	> div {

		display: flex;
		flex-flow: row;
		width: 100%;

		> * {
			border: none;
			background-color: transparent;
			height: 32px;
			line-height: 32px;
			box-shadow: none;
			padding: 0 4px;

			&:Hover {
				cursor: pointer;
				background-color: rgba(0,0,0,.1);
				border-radius: 4px;
			}
		}

		> button {
			flex-grow: 0;
			flex-basis: @w-seven;

			> i {
				font-style: normal;
			}

			&.month-minus {
				> i {
					.icon('chevron-left');
				}
			}

			&.month-plus {
				> i {
					.icon('chevron-right');
				}
			}
		}

		> span {
			font-weight: 500;
			font-size: 20px;
			text-transform: uppercase;
			text-align: center;
		}

		.year {
			flex-grow: 1;
			flex-basis: @w-seven2;
		}

		.month {
			flex-grow: 2;
			flex-basis: @w-seven3;
		}
	}
}

.years-list-controls {
	.years-label {
		flex-grow:1;
		flex-basis: 50%;
	}
}

.month-days {
	.days-list {
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;

		.day {
			height: 32px;
			line-height: 32px;
			text-align: center;
			flex-grow: 1;
			flex-basis: @w-seven;

			&.selected {
				background-color: fade(@brand-info,20);
			}

			&:nth-child(7n + 1) {
				clear: left;
			}

			&.out-of-month {
				color: rgba(0,0,0,.3);
			}

			&.holiday {
				color: @brand-danger;
			}

			&.holiday.out-of-month {
				color: fade(@brand-danger,30);
			}

			&:not(.out-of-month) {
				font-weight: 500;
			}

			&.today, &.holiday.today {
				color: @brand-primary;
			}

			&:not(.disabled) {
				&:Hover {
					cursor: pointer;
					background-color: fade(@brand-success,10);
					border-radius: 4px;
				}
			}
		}
	}
}


.datetime-flex-container() {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
}

.datetime-flex-item(@w: @w-seven) {
	flex-grow: 0;
	flex-basis: @w-seven;
	text-align:center;
	line-height:32px;
	height:32px;
}

.datetime-control-item() {
	box-shadow: none;
	border: none;
}
.datetime-control-button() {
	&:Hover {
		background-color: rgba(0,0,0,.1);
		cursor: pointer;
		border-radius:3px;
	}
}

.datetime-control {
	margin: @gutter 0;

	button {
		padding: 0;

		> i {
			font-style: normal;
		}

		&:focus, &:active {
			outline: none;
		}
	}


	.datetime-month, .datetime-month-weekdays > div, .datetime-controls, .datetime-month-list > div, .datetime-year-list > div {
		.datetime-flex-container();
	}

	.datetime-day, .datetime-month-weekdays > div > b, .datetime-controls > *, .select-item {
		.datetime-flex-item;
	}

	.datetime-day, .select-item, .datetime-controls > * {
		.datetime-control-item();
	}

	.datetime-day, .datetime-controls button, .datetime-controls .year, .datetime-controls .month, .select-item {
		.datetime-control-button();
	}


	.datetime-controls {

		> span {
			line-height: 32px;
			font-weight: 20px;
			.ff-header();
			font-weight: 500;
		}

		.year {
			flex-basis: @w-seven2;
		}

		.month {
			flex-basis: @w-seven3;
		}

		&.year {
			margin-bottom: 4px;
			padding-bottom: 4px;
			border-bottom: 1px dotted rgba(0,0,0,.05);

			> button {
				flex-basis: 25%;
			}

			> span {
				flex-basis: 50%;
			}
		}

		.increment > i {
			.icon('chevron-right',true);
		}

		.decrement > i {
			.icon('chevron-left',true);
		}
	}

	.datetime-month-weekdays {
		> div {
			border-top: 1px dotted rgba(0,0,0,.05);
			border-bottom: 1px dotted rgba(0,0,0,.05);
			margin: 4px 0;

			> b {
				font-weight: 200;
			}
		}
	}

	.datetime-month-list .select-item {
		flex-basis: 100% / 3;
	}

	.datetime-year-list .select-item {
		flex-basis: 25%;
	}


	.current {
		color: @brand-primary;
		font-weight: 500;
	}

	.datetime-day {
		&:not(.current) {
			&.holiday {
				color: @brand-danger;
			}

			&.out-of-month.holiday {
				color: fade(@brand-danger, 40);
			}

			&.out-of-month {
				color: fade(#000, 40);
			}
		}

		&.selected {
			background-color: fade(@brand-info,20);
		}
	}

	.value-info > .value {

		font-weight:200;
		font-size:24px;
		display:inline-block;

		margin-right:@gutter2;

		> b {
			color:rgba(0,0,0,.4);
		}

		> *:not(b){
			color:@brand-primary;
		}

		span {
			//flex-basis: @w-seven;
		}



		.date {
			//flex-basis: @w-seven2;
		}

		input[type=number] {
			width: 50px;
			border: none;
			line-height:1;
			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}

		.time {
			display: flex;
			align-items:center;

			input [type=number] {
				text-align: center;
			}

			.time-value {
				flex-flow: column;
				display: flex;
				> * {
					text-align:center;
				}
			}

			i {
				display: block;
				font-style: normal;
				font-weight:200;
				font-size:14px;
				color:rgba(0,0,0,.4);

				&:first-child {
					.icon('chevron-up')
				}

				&:last-child {
					.icon('chevron-down')
				}
			}
		}

		> * {
			display:inline-block;
			vertical-align:middle;
		}

	}
}