.map-control-searchPlace.map-control-wrapper {
	position: relative;
	.yandex-search-suggestions {
		font-size: 12px;
		position: absolute;
		top: 35px;
		right: 0;
		border-radius: 2px;
		&:not(:empty) {
			background-color: white;
			min-width: 200px;
			box-shadow: 0 0 5px 5px rgba(0,0,0,.1);
		}
		.suggest-item {
			padding: 5px;
			&:not(:first-child) {
				border-top: 1px solid rgba(0,0,0,.1);
			}
			.name {
				font-weight: bold;
				line-height: 1;
			}
			.desc {
				font-style: italic;
				line-height: 1;
				font-size: 10px;
			}

			&:hover {
				cursor: pointer;
				background-color: rgba(0,0,0,.1);
			}

		}
	}
}