@import '~less/include.less';

.fullheight .editValue-control {
	.top-static {
		height: 30px;
	}

	.auto {
		height: ~"calc(100% - 80px)";
		overflow-x: hidden;
		overflow-y: auto;

		.control-region {
			height: 100%;
			overflow-x: hidden;
			overflow-y: auto;
			box-sizing: border-box;
			
		}
	}

	.actions {
		padding-top: @gutter;
		height: 50px;
	}



}

.editValue-control.flexible-edit-modal {
	> .top-static, > .actions {
		flex-grow: 0;
		flex-shrink: 0;
		padding: @gutter;
	}

	> .actions {
		background-color: #f0f0f0;
		button {
			text-transform: uppercase;
			font-weight: 500;
			&.ok {
				background-color: olivedrab;
				color: white;
				&:hover {
					background-color: darken(olivedrab, 10);
				}
			}
		}
	}


	> .auto {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		flex-shrink: 1;
		overflow: auto;
		> .control-region {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			flex-shrink: 1;
			overflow: auto;
			> :first-child:last-child {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				flex-shrink: 1;
				overflow: auto;
			}
		}
	}

	.select-control-box {
		.select-control {
			margin: 0;

		}
	}

	&.no-padding-section {
		margin: -15px;
	}

}



.editValue-control {
	.control-region {
		padding: 15px 0;
		//margin-top:@gutter;
		//margin-bottom:@gutter;

	}
	.predefined-region{
		//margin-top: @gutter;
		//margin-bottom: @gutter;
	}
}
