@import '~less/include.less';

.lead-source-edit {

	display:flex;
	flex-flow:row;
	flex-wrap:nowrap;
	align-items: baseline;

	> * {
		padding: 0 @gutter/2;
	}
	.name-container {
		flex-grow:2;

		.suggestions {
			.source-suggestion {
				padding: 4px;

				&:Hover {
					cursor: pointer;
					color: tomato;
				}

				i {
					font-style:normal;
					//.icon('circle-o');
					color:#555;
					margin-right:@gutter/2;
				}
				&.active {
					i {
						//.icon('circle-o-notch');
					}
				}
			}
		}

	}
	.instrument-container {
		flex-grow:1;

	}
	input {
		font-weight:bold;
		color:tomato;
	}
	.type-container {
		flex-grow:1;
		label {
			display: block;
			input:checked + span {
				color: tomato;
			}
			span {
				transition: color .5s;
				color: #555;
			}
		}
	}
}