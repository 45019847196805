@import "~less/include.less";

.offer-properties-popup {
    max-width: 315px;
}

.offer-properties-content_colorMixin(@bgcolor, @color, @labelColor) {

    background-color: @bgcolor;
    color: @color;

    & + .arrow {
        background-color: @bgcolor;
    }
    [data-role="label"] {
        color: @labelColor;
    }
}

.offer-properties-content {
    &.rent {

        .offer-properties-content_colorMixin(darken(@brand-success,15%), #fff ,rgba(255,255,255,.7));

    }
    &.sell {
        .offer-properties-content_colorMixin(@brand-primary, #fff ,rgba(255,255,255,.7));
    }

    &.with-borders {
        > * {
            border-bottom: 1px solid rgba(0,0,0,.1);
            border-top: 1px solid rgba(255,255,255,.1);
            > * {
                border-right: 1px solid rgba(0,0,0,.1);
                border-left: 1px solid rgba(255,255,255,.1);
            }
        }
    }
}
