.actualize-history-details {
	.history-data-item {
		padding: 15px;
		.ui2-atom  {
			> i:first-child:empty {
				min-height: unset;
				padding: 0;
			}
		}
		&:nth-child(2n) {
			background-color: rgba(0,0,0,.1);
		}
		> * {
			flex-grow: 0;
			flex-shrink: 0;
		}
	}
}