@import './env.less';

.ui2-molecule {

    .ui2-flex-row-mixin();

    align-items: baseline;

    > .icon:not(.elastic), > button:not(.elastic), > .fixed {
        flex-grow: 0;
        flex-shrink: 0;
    }

    > .ui2-atom:not(.fixed), > .elastic {
        flex-grow: 1;
        flex-shrink: 1;
    }

    > .elastic-2 {
        flex-grow: 2;
        flex-shrink: 2;
    }

    > .elastic-3 {
        flex-grow: 3;
        flex-shrink: 3;
    }

    > button:not(.not-flat) {
        border-radius: 0;
        .ui2-button-flat-mixin();
        .ui2-button-hover-mixin({
            background-color: #00000010;
        });
    }

    > button:not(.not-stretch) {
        align-self: stretch;
    }

    > .stretch {
        align-self: stretch;
    }


		.with-borders > &:not(:first-child) {
			border-top: 1px solid rgba(0,0,0,.1);
		}

		&.wrap-on {
			flex-wrap: wrap;
		}
		&.full-width {
			width: 100%;
		}

		&.clickable {
			cursor: pointer;
			&:Hover {
				background-color: rgba(0,0,0,.1);
			}
		}



		
		
	}

	button.dark-margined.dark-margined.dark-margined.dark-margined.dark-margined {
		border-radius: 3px;
		margin: 4px;
		background-color: rgba(0,0,0,.1);
		border: none;
		&:hover {
			cursor: pointer;
			background-color: rgba(0,0,0,.2)!important;
		}
	}