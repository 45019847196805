@import '~less/include.less';

.full-screen-card {
    z-index: 5;
    [data-role="modal-box"] {
        width: calc(100% - @gutter/2);
        height: calc(100% - @gutter/2);
    }
    [data-role="modal-box-content"] {
        width: 100%;
        height: 100%;
    }
}