@import '~less/include.less';
.entity-edit-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    > *:first-child {
        flex-grow: 0;
        flex-shrink: 0;
    }
    > .content {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
    }

    .realties-edit-properties {
        max-width: 1000px;
        margin:auto;
        //padding:0 @gutter;
        height: 100%;

        display: flex;
        flex-direction: column;
        overflow: hidden;

        .search-container {
            flex-grow: 0;
            flex-shrink: 0;
            padding: @gutter 0;
            //border-top: 1px solid #00000010;
            border-bottom: 1px solid #00000010;
        }
        .actions-container {
            flex-grow: 0;
            flex-shrink: 0;

            button.save:not(:disabled) {
                .btn-success;
            }

        }
        .properties-container {
            flex-grow: 1;
            flex-shrink: 1;
            overflow: auto;
            padding:0 @gutter;
        }

        .propertygroup-item {
            > header {
                font-size: 24px;
                font-weight: bold;
                > span {
                    //width: 400px;
                    padding: @gutter;
                    display: inline-block;
                    text-align: right;
                }
                // color: white;
                // text-align:center;
                // background-color: royalblue;
            }
        }

        .properties-list {
            .property-edit-view {
                display: flex;
                width:100%;
                padding: 10px 0;
                
                flex-direction: column;

                //border-top: 1px solid #00000010;
                border-bottom: 1px solid #00000010;

                .label-place {
                    flex-grow: 0;
                    flex-shrink: 0;
                    font-size: 13px;
                    font-weight: bold;
                    padding: 0 @gutter;
                    align-self: center;
                    color: #777;

                    width: 100%;
                    text-align:left;
                }
                .control-place {
                    flex-grow: 1;
                    flex-shrink: 1;
                    .form-control {
                        font-weight: bold;
                    }
                }

                .multiselect-control .form-control {
                    height: unset;
                    padding: 2px 8px;
                    .multiselect-option {
                        display: inline-block;
                        background-color: #00000010;
                        border-radius: 3px;
                        padding: 0 4px;
                        margin: 4px;
                        cursor: pointer;
                        &:hover {
                            background-color: #00000018;
                        }
                        &.active {
                            background-color: forestgreen;
                            color: white;
                            font-weight: bold;
                            &:hover {
                                background-color: darken(forestgreen, 10);
                            }
                        }
                    }
                }

                .boolean-control {
                    label {
                        margin-bottom: 0;
                        input {
                            margin-right: 5px;
                        }
                        margin-right: @gutter;
                    }
                }

                &.group {
                    .label-place {
                        color: royalblue;
                        font-size: 18px;
                    }
                }

                .media-tablet-up({
                    flex-direction: row;
                    .label-place {
                        width: 400px;
                        text-align: right;
                    }
                });

                &.has-changes {
                    background-color: wheat;
                }

            }
        }


        .actions-container {
            padding: @gutter 0;
        }

    }



}


.text-control {
	&:not(.enlarged) {
		*:not(textarea) {
			display: none;
		}
	}
	&.enlarged {
		background-color: white;
		padding: 0 @gutter;
		// padding-bottom: 0;
		z-index: 1;
		display: flex;
		flex-direction: column;
		.info {
			display: block;
			padding: @gutter 0;
			font-weight: 500;
			flex-grow: 0;
			flex-shrink: 0;
		}
		button.close {
			display:block;
			flex-grow: 0;
			flex-shrink: 0;
			padding: 10px;
			&.cross {
				position: absolute;
				top: 0;
				right: 0;
				> i {
					.icon('times');
					font-style: normal;
				}
			}
		}
		textarea {
			width: 100%;
			flex-grow: 1;
			flex-shrink: 1;
			
		}
	}
}