@import '~less/include.less';

.deal-search .module > section {
	> div {
		height: 100%;

		.actions-panel {
			//height: 100%;
		}

		.scrolled-region {
			height: 100%;
		}

		.scrollY-region {
			height: 100%;
		}
	}
}

.deals-items-list.deals-search {
	//margin-left: @actions-panel-width - @gutter;
	padding: @gutter;
}
