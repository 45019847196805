@import '~less/include.less';

.realties-search-content {
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: row;

	.actions-panel {
		//position: relative;
	}

	.result {
		padding: 0;
		flex-grow: 1;
		//padding-left: 300px;

		.scrollY-region {
			.items-list {
				padding: @gutter;
			}
		}
		.items-list {
		}



	}
}


.my-super-puper-popup {
	position: fixed;
	width: 200px;
	height: 100px;
	background-color: tomato;
	top: 200px;
	left: 400px;
}
.my-super-puper-popup-2 {
	//background-color: tomato;
}