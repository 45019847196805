.width600{
	min-width:270px;
	max-width:600px;
	margin-left:auto;
	margin-right:auto;
}
.w100p{
	width:100%;
}
.h100p{
	height:100%;
}
.h200px{
	height:200px;
}
.mh200px{
	max-height:200px;
}