@import '~less/include.less';

.admin-groups-page {
	padding: 15px;

	.admin-groups-actions {
		button {
			margin: @gutter/2;	
		}
	}

	.admin-group-item {
		padding: 15px;
		background-color: white;
		box-shadow: 0 0 10px 1px rgba(0,0,0,.05);
		border: 1px solid rgba(0,0,0,.07);
		margin: @gutter/2;
		header {
			font-weight: 500;
		}
		&:Hover {
			cursor: pointer;
			background-color: #00000010;
		}
	}

}
