@btn-border-radius-base:         @border-radius-base;
@btn-border-radius-large:        @border-radius-large;
@btn-border-radius-small:        @border-radius-small;

.btn-shadow() {
	box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.1);
}
.btn-mixin() {

	.btn();
	.btn-default();
	.btn-shadow();

	&.xs {
		.btn-xs();
	}

	&.lg {
		.btn-lg();
	}

	.cnt-xs & {
		.btn-xs();
	}

	.cnt-lg & {
		.btn-lg();
	}
}

button, .as-btn {
	.btn-mixin();
}
