.ui2-lines {
    display: flex;
    flex-direction: column;
    &.with-borders {
        > *:not(:first-child) {
            border-top: 1px solid #00000010;
        }
    }
}

.ui2-line {
    display: flex;
    flex-direction: row;

    > button:not(.elastic), > .icon, > .fixed {
        flex-grow: 0;
        flex-shrink: 0;
    }

    > .elastic, > *:not(.fixed) {
        flex-grow: 1;
        flex-shrink: 1;
    }


    &.items-2 {
        > * {
            width: 50%;
        }
    }
    &.items-3 {
        > * {
            width: calc(100% / 3);
        }
    }
    &.items-4 {
        > * {
            width: 25%;
        }
    }

    .with-borders > & {
        > *:not(:first-child) {
            border-left: 1px solid #00000010;
        }
    }
}

.ui2-box.with-borders > section > .ui2-lines {
    > .ui2-line {
        &:not(:last-child) {
            border-bottom: 1px solid #00000010;
        }
        > *:not(:first-child) {
            border-left: 1px solid #00000010;
        }
    }
}

.ui-system-line {

	font-size: 10px;
	color: cornflowerblue;
	.ff-system();
	font-style: normal;
	text-transform: uppercase;
	font-weight: bold;
	line-height: .9;
	padding:2px 7.5px;
	min-height: 15px;

	&.top {

	}
	&.bottom {

	}
	&.full-width {
		width: 100%;		
	}
}