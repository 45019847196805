@outline: 0 0 0px 1px #00000010;
@shadow: 0 0 7px 3px #00000010;

.own-popup-color-mixin(@c) {
	> :first-child {
		background-color: @c;
	}
	.arrow {
		background-color: @c;
	}
}
.own-popup {

    box-shadow: @shadow, @outline;

    border-radius: 4px;
    > :first-child {
        border-radius: 4px;
        position: relative;
        z-index:1;
				&:empty + .arrow {
					display: none;
				}
    }
    .arrow {
        position: absolute;
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
        z-index:0;
				box-shadow: @shadow, @outline;
    }


		&.white {
			.own-popup-color-mixin(white);
		}

		&.yellow {
			.own-popup-color-mixin(antiquewhite);
		}


}