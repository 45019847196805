@import '~less/bundle.less';

.modern-edit-layout {
	display: flex;
	flex-direction: column;
	> :not(header):not(footer) {
		flex-grow: 1;
		flex-shrink: 1;
		overflow: auto;		
	}
	> header, > footer {
		flex-grow: 0;
		flex-shrink: 0;
		padding: (@gutter / 2) @gutter;
	}
	> header {
		.ff-header();
		text-transform: uppercase;
		font-weight: 700;
		font-size: 24px;

	}
}