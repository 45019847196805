@import '~less/include.less';

.entity-edit-page {
    > nav.sub {
        display: flex;
        flex-direction: row;
        width: 100%;
        border-bottom: 1px solid #00000010;

        a {
            display: block;
            padding: 5px 10px;
            color: #75b4ea;
            .ff-header();
            font-weight: bold;
            font-size: 14px;
            &:Hover {
                cursor: pointer;
                text-decoration: none;
                background-color: #00000010;
            }
        }
        .active > a {
            background-color: #75b4ea;
            color: white;
            &:Hover {
                background-color: #75b4ea;
            }
        }
    }
    > .content {
        height: 100%;
        > * {
            height: 100%;
        }
    }


    .info-container {
        > .ui-tab-triggers {
            background-color: #00000010;
        }
    }

}