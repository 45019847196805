
.image-galery {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    button {
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 70px;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        width: 70px;

        &:focus, &:active, &:focus:active {
            outline: none;
            background-color: #ffffff10;
            border-color: transparent;
        }
        
        &:Hover {
            cursor: pointer;
            background-color: #ffffff10;
            border-color: transparent;
            box-shadow: none;
        }
    }

    img {
        padding: 5px;
        max-width: 100%;
        max-height: 100%;
        //object-fit: cover;

    }


    .big-container {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position:relative;


        button.open {
            position: absolute;
            top: 15px;
            left: calc(50% - 112.5px);
            font-size: 14px;
            font-weight: bold;
            height: unset;
            width: 225px;
            border-radius: 4px;
            background-color: #eee;
            border-color: #aaa;            
        }

        .big {
            flex-grow: 1;
            flex-shrink: 1;
            display: flex;
            overflow: hidden;
            align-items: center;
            justify-content: center;


        }

    }

    .previews {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;

        .previews-items {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            flex-shrink: 1;
            overflow: hidden;
            > * {
                width: 110px;
                height: 110px;
            }
        }


        img {
            &:hover {
                background-color: #ffffff50;
                border-radius: 5px;
            }
        }

    }

}


.modals-dialog.image-galery-dialog {
    .modal-close {
        width: 70px;
        height: 70px;

        span { 
            font-size: 64px;
            line-height: 1;
        }

        &:Hover {
            background-color: #ffffff10;
        }

    }

    .modal-box.block {
        background-color: #000000;
        section {
            padding: 0;
        }
    }

}