@import '~less/include.less';

.clients .module > section {
	> div {
		height: 100%;

		.actions-panel {
			height: 100%;
		}

		.scrolled-region {
			height: 100%;
		}

		.scrollY-region {
			height: 100%;
		}
	}
}

.clients-items-list.clients-search {
	//margin-left: @actions-panel-width - @gutter;
	padding: @gutter;
}
