@import '~less/include.less';
.ui2-atom {
	display: flex;
	flex-direction: column;
	i, b {
		font-size: 10px;
		color: cornflowerblue;
		.ff-system();
		font-style: normal;
		text-transform: uppercase;
		font-weight: bold;
		line-height: .9;
		padding-bottom: 2px;
		padding-top: 4px;
		min-height: 15px;
	}
	> i {
		order: 1;
	}
	> b {
		order: 4;
	}
	> span {
		order: 2;
		.ff-header();
		font-weight: 400;
		font-size: 16px;
		line-height: 1;
		padding-top: 4px;
		padding-bottom: 4px;
		color: #444;
	}
	> small {
		order: 3;
		font-weight: 400;
		color: #444;
	}
	&.clickable:not(:disabled):not(.disabled) {
		&:Hover {
			background-color: rgba(0,0,0,.1);
			cursor: pointer;
		}
	}
}