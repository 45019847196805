@import '~less/include.less';


.item-fastaction-trigger{
		> span {
			
			&:Hover{
				background-color:rgba(0,0,0,.1);
				cursor:pointer;
				color:@brand-primary;
			}
		}
}
.fastaction{

	&.fastaction-arrow-right .item-fastaction-trigger > span { .icon('arrow-circle-o-right'); }
	&.fastaction-close .item-fastaction-trigger > span { .icon('times-circle-o'); }

}