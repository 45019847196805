@import '~less/include.less';

.v2-modal-content-container {
	.edit-price-control {
		button.apply {
			margin: @gutter;
		}
		.input-container {
			padding: @gutter;
		}
	}
}