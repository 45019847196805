@import '~less/include.less';

.twoicons() {
	.two-icons-mixin({
		font-size: 24px;
		color: #999;
	},
	{
		font-size: 16px;
		.text-shadow-outline(white);
		left: -4px;
		color: lightseagreen;
	});
}
.clickable() {
	cursor: pointer;
	border-radius: 3px;
	&:Hover {
		background-color: rgba(0,0,0,.1);
	}
}

.owner-in-realty-info {

	button, button:active, button:focus, button:focus-visible, button:focus-within {
		outline: none;
		border: none;
	}

	.box {
		border-radius: 3px;
		border: 1px solid rgba(0,0,0,.2);
		box-shadow: 0 0 4px 2px rgba(0,0,0,.1);
		//margin: 7.5px;
	}

	.box-row {
		> button, > .btn-group button {
			background-color: transparent;
			border-color: transparent;
			box-shadow: none;
			margin: 3px;
			border-radius: 3px;
			color: #999;
			> span {
				font-size: 14px;
			}
			&:not(.two-icons) > span > i {
				font-size: 24px;
			}
			&:Hover {
				background-color: rgba(0,0,0,.1);
			}
		}

		> button:first-child, > .btn-group:first-child {
			width: 52px;
		}
		> .btn-group:first-child {
			> button {
				width: 100%;
			}
		}

		&.main {

			.btn-group.actions {
				button.dropdown-toggle {

					font-size: 24px;
				}
			}

		}

		&.contacts {
			.two-icons {
				.twoicons();
			}

			.contacts-list {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				.contacts-list-item {
					.ff-header();
					position: relative;
					display: flex;
					flex-direction: column;
					//padding: 12px 4px;
					padding-top: 0px;
					border-radius: 3px;
					background-color: #eee;
					margin: 3px;				
					// > i {
					// 	position: absolute;
					// 	top: 0;
					// 	left: 4px;
					// 	font-size: 12px;
					// 	font-style: normal;
					// }
					// > b {
					// 	position: absolute;
					// 	bottom: 0;
					// 	left: 4px;
					// 	font-style: normal;
					// 	font-size: 12px;
					// 	font-weight: 400;
						// 	color: darkcyan;
					// }
	
					> div {
						//display: inline-block;
						font-weight: 500;
						padding: 0 4px;
						> * {
							display: inline-block;
							vertical-align: middle;
						}
						.name {
							color: darkred;
						}
						&.funcs {
							font-size: 12px;
							font-weight: 400;
							color: darkcyan;
							margin-top: -5px;
						}
					}
				}
			}			

		}

		&.tags {
			
			align-items: center;

			.two-icons {
				.twoicons();
			}

			.tags-list {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
			}

			.tag {
				margin: 3px;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				border-radius: 2px;
				background-color:bisque;
				align-items: center;
				> * {
					flex-grow: 0;
					flex-shrink: 0;
					font-weight: 400;
				}
				> span:first-child {
					margin: 3px;
					padding: 0 3px;
				}
				i {
					color: #555;
				}
				.close-btn {
					margin: 3px;
					padding: 0 3px;
					border-radius: 2px;
					cursor: pointer;
					&:Hover {
						background-color: rgba(0,0,0,.1);
					}
				}
			}

		}

		&.note {
			//justify-content: center;
			align-items: center;
			> button {
				font-size: 24px;
			}
			.just-text {
				//font-size: 18px;
				font-weight: bold;
				margin: 3px 0;
				padding: @gutter-half 3px;
				.clickable();
			}
		}



	}


	.info-box {
		.toggle-button .icon {
			font-style: normal;
			.icon(caret-up);
		}
		&.collapsed {
			> :not(:first-child) {
				display: none;
			}
			.toggle-button .icon {
				.icon(caret-down);
			}	
		}
	}



	.entity-label-view {
		align-items: center;
		cursor: pointer;
		margin: 3px;
		border-radius: 3px;
		&:Hover {
			background-color: rgba(0,0,0,.1);
		}

		.ui2-atom {
			> b:empty {
				height: unset;
				padding: unset;
				min-height: unset;
			}
		}
		.icon {
			padding-left: 7.5px;
		}
		&.realty {
			.icon {
				.icon('building', true)
			}
		}

		&.owner {
			.icon {
				.icon('user-secret', true)
			}
		}

	}


}

.flex-column {
	display: flex;
	flex-direction: column;
	&.gap-on {
		row-gap: @gutter-half;
	}
	&.with-scroll {
		overflow: auto;
		.v-scrollbar();
	}
}

.flex-row {
	display: flex;
	flex-direction: row;

	&.centered {
		align-items: center;
	}
	&.wrap-on {
		flex-wrap: wrap;
	}

	&.gap-on {
		column-gap: @gutter-half;
	}

	> .elastic {
		flex-grow: 1;
		flex-shrink: 1;
	}

	> .fixed {
		flex-grow: 0;
		flex-shrink: 0;
	}

	> button:not(.elastic) {
		flex-grow: 0;
		flex-shrink: 0;
	}

}