@import '~less/include.less';

.client-in-list{
	position:relative;
	.item-fastaction-trigger{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		padding: 0!important;
		> span {
			//.icon('arrow-circle-o-right',true);
			height: 100%;
			padding: 10px;
			font-size: 20px;
			color:rgba(0,0,0,.4);
			border-radius:2px;
			display:block;
		}
	}
}
