@import '~less/include.less';

.offer-view-list {
	/*padding: 15px 0;*/
	margin: 5px 0;

	&.mo-rent {
		.priceMeter {
			color: @brand-success;
		}
		//background-color: fade(@brand-success,10);
	}

	&.mo-sell {
		.priceMeter {
			color: @brand-primary;
		}

		//background-color: fade(@brand-primary,10);
	}

	&:first-child {
		margin-top: 0;
	}

	border: 1px solid transparent;
	padding: @gutter;
	.block();
	.block-shadow(true);


	.line {
		display: block;
		font-size: 0;
		line-height: 25px;

		@media (min-width:@screen-sm-min) {
			display: inline-block;
		}

		> * {
			display: inline-block;
			//font-size: 16px;
			vertical-align: middle;
			font-size: 14px;
			line-height: 1;
			padding: 0 @gutter/2;
		}
		/*&.fs20 > * {
			font-size: 16px!important;
			margin-right:0!important;
			text-align:center;
		}

		&.pd40 {
			padding-right:40px;
		}*/
	}
	/*.third{
		width:100% / 3!important;
	}*/
	.small-bold {
		.ff-header();
		font-size: 10px;
		font-weight: 500;
		line-height: .7;
		overflow-wrap: break-word;
		margin-right: 5px;
	}

	span > small {
		.ff-header();
		font-size: 10px;
	}
	/*
	.purposes {
		.small-bold;
		width: 50px;
	}

	.state {
		.small-bold;
		width: 80px;
	}

	.spaceLayout {
		.small-bold;
		width: 50px;
	}
	*/
	.building {
		.ff-header();
		font-weight: 500;
		width: 100px;
		//font-size: 14px !important;
		//line-height: 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 10px;

		&.Ap {
			color: @clr-bld-Ap;
		}

		&.A {
			color: @clr-bld-A;
		}

		&.Bp {
			color: @clr-bld-Bp;
		}

		&.B {
			color: @clr-bld-Bp;
		}

		&.Cp {
			color: @clr-bld-Cp;
		}

		&.C {
			color: @clr-bld-C;
		}

		&.Dp {
			color: @clr-bld-Dp;
		}

		&.D {
			color: @clr-bld-D;
		}

		&.Ep {
			color: @clr-bld-Ep;
		}

		&.E {
			color: @clr-bld-E;
		}
	}

	.metro {
		.ff-header();
		font-weight: 500;
		width: 100px;
		//font-size: 10px !important;
		//line-height: 10px;
		overflow: hidden;
		text-overflow: ellipsis;
	}



	.floor {
		width: 35px;
	}

	.square {
		width: 75px;
		font-weight: 500;
	}

	.priceMeter {
		width: 100px;
		font-weight: 500;
	}

	.percent {
		width: 45px;
	}

	.bld-class {
		width: 25px;
	}

	.full-address {
		.small-bold;
		max-width: 300px;
		color: #aaa;
	}
}


.offers-items-list.scrollY-region{
	padding:@gutter;
}

.selected-cluster .offer-view-list {
	.w20p {
		display: inline-block;
		width: 20% !important;
		text-align: center;
		margin: 0 !important;
	}

	.w40p {
		display: inline-block;
		width: 40% !important;
		text-align: center;
		margin: 0 !important;
	}

	.w33p {
		display: inline-block;
		width: 100% / 3 !important;
		text-align: center;
		margin: 0 !important;
	}

	.w50p {
		display: inline-block;
		width: 50% !important;
		white-space: nowrap;
		overflow: hidden;
		margin: 0 !important;
	}
}