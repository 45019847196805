
.ui-panel-wrapper-mixin(@rule) {
    &.with-wrapper > .wrapper, &:not(.with-wrapper) {
        @rule();
    }
}

.ui-panel-mixin() {
    height: unset;
    .ui-panel-wrapper-mixin({
        padding: 30px;
        height: unset;
    });
    &.outer-margin {
        margin: 15px;    
    }
    &.outer-margin-2 {
        margin: 30px;    
    }
    .ff-header();
    font-weight: bold;
    font-size: 18px;
}

.ui-panel.ui-panel.ui-panel {
    .ui-panel-mixin();
    &.ui-panel-info {
        .ui-panel-wrapper-mixin({
            background-color: @brand-info;
            color: white;
        })
    }
    &.ui-panel-error {
        .ui-panel-wrapper-mixin({
            background-color: @brand-danger;
            color: white;
        })
    }    
    &.centered {
        text-align: center;
    }
    &.rounded {
        .ui-panel-wrapper-mixin({
            border-radius: 4px;
        });
    }   
    &.rounded-2 {
        .ui-panel-wrapper-mixin({
            border-radius: 8px;
        });
    }   
    &.dotted {
        .ui-panel-wrapper-mixin({
            border: 1px dotted rgba(0,0,0,.1);
        });
    }   

    &.dotted-2 {
        .ui-panel-wrapper-mixin({
            border: 2px dotted rgba(0,0,0,.1);
        });
    }   

    &.dotted-4 {
        .ui-panel-wrapper-mixin({
            border: 4px dotted rgba(0,0,0,.1);
        });
    }   

    &.dashed {
        .ui-panel-wrapper-mixin({
            border: 1px dashed rgba(0,0,0,.1);
        });
    }   

    &.dashed-2 {
        .ui-panel-wrapper-mixin({
            border: 2px dashed rgba(0,0,0,.1);
        });
    }   

    &.dashed-4 {
        .ui-panel-wrapper-mixin({
            border: 4px dashed rgba(0,0,0,.1);
        });
    }  

}