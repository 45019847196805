
.tab-button-mixin(@color) {
	padding: @gutter-half;
	background-color: transparent;
	border-color: transparent;
	border-style: solid;
	border-bottom-width: 4px;
	text-transform: uppercase;
	cursor: pointer;
	.ff-header();

	&.active {
		border-bottom-color: @color;
		color: darken(@color, 20);
	}

	&:not(.active):hover {
		border-bottom-color: rgba(0,0,0,.1);
	}
	.button-text {
		display: inline-block;
		vertical-align: middle;
	}
	.badge-container {
		display: inline-block;
		.badge {
			display: inline-block;
			vertical-align: middle;
			margin-left: 3px;
			border-radius: 5px;
			background-color: @brand-primary;
			color: white;
			font-size: 10px;
			font-weight: bold;
			line-height: 1;
			&:not(:empty) {
				padding: 3px;
				min-width: 10px;						
			}
		}
	}
}

.tab-buttons-mixin() {
	display: flex;
	flex-direction: row;
	padding: 0 @gutter;
	column-gap: 3px;
}