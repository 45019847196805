@import '~less/include.less';
@clr-rent: green;
@clr-rent-bg: #cff2cf;
@clr-sale: #2c78ff;
@clr-sale-bg: #e7e7ff;

@clr-selected-object: #0a88b5;
@clr-selected-offer: #906090;

.owner-in-realty-page {

	.owner-in-realty-objects {
		display: flex;
		flex-direction: column;
		overflow: auto;
		flex-grow: 1;
		flex-shrink: 1;
	}

	.objects-list {
		display: flex;
		flex-direction: column;
		overflow: auto;
		row-gap: @gutter;
		padding: @gutter-half 0;
		flex-grow: 1;
		flex-shrink: 1;
	}

	.object-list-item {
		border: 1px solid rgba(0,0,0,.2);
		border-radius: 3px;
		display: flex;
		flex-direction: column;
		margin: 3px;
		//background-color: #efefef;

		.main-info {
			.single-property.info-purposes {
				padding: 0 @gutter-half;
			}
		}

		.system-group {
			font-size: 11px;
			.single-property {
				margin-right: @gutter-half;
				> * {
					display: block;
					line-height: 1;
				}
				label {
					margin: 0;
					color: #aaa;
				}
			}
		}

		.purposes {
			&.possible-none {
				background-color: lighten(indianred, 10);
				color: white;
			}
			.schema-value-container {
				padding: 0 @gutter-half;
			}
		}

		.object-details, .offer-details {
			label {
				// margin: 0;
				// text-transform: lowercase;
				// font-size: 11px;
				// display: inline-block;
				// margin-right: @gutter-half;
				// // width: 70px;
				// color: #aaa;
				// &::after {
				// 	content: ': '
				// }
			}
		}

		.offer-details {
			label {
				// width: 50px;
			}
		}

		.main-line {
			align-items: center;
			flex-wrap: wrap;
		}

		.actions-button {
			.dropdown-toggle {
				background-color: transparent;
				border-color: transparent;
				box-shadow: none;
				border-radius: 3px;
				margin: 3px;
				&:hover {
					background-color: rgba(0,0,0,.1);
				}
			}
		}

		.schema-value-container {
			.schema-value {
				font-weight: bold;
				font-size: 1;
			}
			.schema-postfix {
				font-weight: normal;
				font-size: 60%;
				margin-left: .2em;
			}
			.schema-prefix {
				font-weight: normal;
				font-size: 60%;
				margin-right: .2em;
			}
		}

		.group-column {
			display: flex;
			flex-direction: column;
		}

		.group-row {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding:0 @gutter;
			column-gap: @gutter;
			row-gap: @gutter;

			.elastic {
				flex-grow: 1;
				flex-shrink: 1;
			}
			.fixed {
				flex-grow: 0;
				flex-shrink: 0;
			}
		}

		.square-floor {
			width: 160px;
			> div {
				line-height: 1;
			}
			.schema-value-container:not(:empty) {
				padding: 0 @gutter-half;
			}
			// > * {
			// 	margin: 3px;
			// }
			.info-floor, .info-roomNumber {
				font-size: 16px;
			}
			.info-squareOffer {
				font-size: 24px;
			}
		}

		.images {
			display:flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			> * {
				display: block;
				width: 70px;
				height: 70px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin: 3px;
				border-radius: 3px;
				background-color: rgba(0,0,0,.05);
			}
			img {
				border-radius: 3px;
			}
			span {
				font-weight: bold;
				font-size: 10px;
				text-align: center;
			}
		}

		.select-button {
			background-color: transparent;
			border-color: transparent;
			box-shadow: none;
			border-radius: 3px;
			margin: 3px;
			padding: 5px;
			i {
				font-size: 18px;
				display: block;
				position: relative;
				color: #aaa;
				&::before {
					display: block;
					position: relative;
					padding-top: 1.5px;
				}
			}
			&:Hover {
				background-color: rgba(0,0,0,.1);
			}
		}

		.small-badge {
			font-size: 10px;
			font-weight: bold;
			margin: 2px;
			padding: 3px;
			padding-bottom: 2px;
			text-transform: uppercase;
			border-radius: 2px;
			background-color: olive;
			color: white;
			line-height: 1;
		}

		.no-offers {
			margin: 6px;
			border-radius: 3px;
			background-color: rgba(0,0,0,.05);
			font-weight: bold;
			padding: @gutter;
		}

		.offer-item {
			margin: 6px;
			border-radius: 3px;
			// background-color: rgba(0,0,0,.05);
			align-items: center;

			.offer-actions {
				display: flex;
			}

			.market-operation {
				// min-width: 90px;
				.ff-header();
				font-size: 12px;
				font-weight: bold;
				> * {
					padding: 0 @gutter-half;
				}
				.value {
					text-transform: uppercase;
				}
				// > span {
				// 	padding: 0 @gutter-half;
				// 	display: block;
				// 	&.value {
				// 		text-transform: uppercase;

				// 	}
				// }
			}

			.price-group {
				.price-postfix {
					font-size: 12px;
					display: block;
					line-height: 1;
					padding: 0 @gutter-half;
				}
				.price-value {
					font-weight: bold;
					font-size: 18px;
					line-height: 1;
					padding: 0 @gutter-half;
				}
				.price-meter {
					min-width: 125px;
				}
				.price-square {
					min-width: 170px;
				}
			}

			.price-additionals {
				// padding-bottom: @gutter-half;
				// label {
				// 	margin: 0;
				// 	text-transform: lowercase;
				// 	font-size: 11px;
				// 	margin-right: @gutter-half;
				// 	color: #aaa;
				// 	line-height: 1;
				// 	display: block;
				// }
				// span.value {
				// 	display: block;
				// }

					&.just-words {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						padding: 2px @gutter-half;
						column-gap: @gutter-half;
					}

					.word {
						font-size: 10px;
						font-weight: bold;
						// margin: 2px;
						// padding: 3px;
						// padding-bottom: 2px;
						text-transform: uppercase;
						border-radius: 2px;
						// background-color: olive;
						// color: white;
						line-height: 1;
						color: olive;
					}
			}

			// &.offer-off:not(:hover) {
			// 	.offer-details {
			// 		display: none;
			// 	}
			// }

			&.selected {
				box-shadow: 0 0 0 3px @clr-selected-offer;
				.select-button.offer {
					i {
						color: @clr-selected-offer;
					}
				}
			}


		}

		&.selected {
			box-shadow: 0 0 0 3px @clr-selected-object;
			.select-button.object {
				i {
					color: @clr-selected-object;
				}
			}
		}

	}


	.object-list-item {
		&.no-offers, &.no-available-offers {
			background-color: lighten(tomato, 33);
			&.object-actual {
				.no-offers { color: tomato; }
			}
		}
		&.object-archived, &.object-deleted {
			background-color: #777;
			.object-details .status { color: white; }
			.offers-list {
				display: none;
			}
		}


		&:not(.object-archived) .offer-item {
			&.offer-notModerated {
				background-color: fade(darkorange, 20);
			}
			&.offer-off {
				background-color: fade(tomato, 20);
				.market-operation .status { color: tomato; }
			}
			&.offer-active {
				&.rent {
					.operation-color-mixin(@clr-rent, @clr-rent-bg);
				}
				&.sell {
					.operation-color-mixin(@clr-sale, @clr-sale-bg);
				}
			}
		}
	}


	.objects-filter {
		background-color: rgba(0,0,0,.05);
		border-radius: 3px;
		.simple-search-input {
			padding: @gutter-half;
			padding-top: 18px;
			.fa-search {
				top: 28px;
			}
		}
		.labeled-dropdown {
			padding: 0 7.5px;
			.header {
				font-size: 10px;
				font-weight: bold;
				text-transform: uppercase;
				height: 15px;
			}
			.btn-group {
				width: 100%;
				> button {
					width: 100%;
					padding: 0;
					display: flex;
					flex-direction: row;

					// width: 100%;
					span[data-role] {
						flex-grow: 1;
						flex-shrink: 1;
						padding: 6px 12px;
					}
					.caret, .reset {
						flex-grow: 0;
						flex-shrink: 0;
						padding: 6px 12px;
						margin: 0;
					}
				}
			}
		}
	}

	.actualize-block {
		.last-actualize-date, .last-actualize-name {
			font-weight: bold;
			padding: 0 @gutter-half;
			text-align: right;
		}
		.actions {
			padding: @gutter-half;
		}
	}


	.clickable-block {
		&.inner-margin-3 {
			margin: 3px;
		}
		border-radius: 3px;
		&:Hover {
			cursor: pointer;
			background-color: rgba(0,0,0,.1);
		}
	}

	.table-properties-column {
		.single-property {
			height: 20px;
			line-height: 1;
		}
	}


	.empty-object-list-item {
		padding: @gutter;
		border-radius: 3px;
		border:1px solid rgba(0,0,0,.1);
		background-color: rgba(0,0,0,.1);
	}

	.selected-container {
		position: fixed;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
	}

	.selected-info-pane {
		margin: @gutter-half;
		// padding: @gutter-half;
		border-radius: 5px;
		box-shadow: 0 0 7px 3px rgba(0,0,0,.1);
		width: 300px;
		color: #fff;
		&:empty {
			display: none;
		}
		&.offers {
			background-color: @clr-selected-offer;
		}
		&.objects {
			background-color: @clr-selected-object;
		}

		> header {
			display: flex;
			flex-direction: row;
			> span {
				flex-grow: 1;
				flex-shrink: 1;
				padding: @gutter-half @gutter;
			}
			> button {
				background-color: transparent;
				border-color: transparent;
				flex-grow: 0;
				flex-shrink: 0;
				box-shadow: none;
				color: white;
				&:Hover {
					background-color: rgba(0,0,0,.1);
				}
			}
		}



		&:not(:hover) {
			.unhovered {
				display: block;
			}
			.hovered {
				display: none;
			}
			.multy-action {
				display: none;
			}	
		}

		&:hover {
			.unhovered {
				display: none;
			}
			.hovered {
				display: block;
			}
		}

		.multy-action {
			padding: @gutter-half @gutter;
			.ff-header();
			font-weight: bold;
			cursor: pointer;
			font-size: 18px;
			&:Hover {
				background-color: rgba(0,0,0,.1);
			}
			border-top: 1px solid rgba(0,0,0,.1);
		}

	}

}


.table-properties-column {
	display: table;
	.single-property {
		display: table-row;
		> label {
			font-size: 11px;
			margin: 0;
			padding-right: @gutter-half;
			text-align: right;
			text-transform: lowercase;
			color: rgba(0,0,0,.5);
			&::after {
				content: ': '
			}			
		}
		> * {
			display: table-cell;
			
		}
	}
}



.operation-color-mixin(@clr, @bg: lighten(@clr, 20)) {
	background-color: @bg;
	.market-operation :not(.status) {
		color: @clr;
	}
	.price-group .price-value {
		color: @clr;
	}
}


.simple-search-input {
	position: relative;
	.fa-search {
		position: absolute;
		right: 8px + @gutter-half;
		top: 10px;
		z-index: 1;
	}
}

.v2-modal-content-container {

	.offer-price-edit {
		padding: @gutter;
	}
}