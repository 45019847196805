@ff-regular:'Fira Sans', sans-serif;
@ff-condensed:'Fira Sans Condensed', sans-serif;
@ff-extracondensed:'Fira Sans Extra Condensed', sans-serif;

@font-face-text:@ff-regular;
@font-face-header:@ff-extracondensed;
@font-face-action:@ff-extracondensed;

.ff(@fw:400, @ff:@font-face-text){
	font-family:@ff;
	font-weight:@fw;
}
.ff-text(@fw:200){
	.ff(@fw, @font-face-text);
}
.ff-header(@fw:300){
	.ff(@fw, @font-face-header);
}
.ff-header-default {
	.ff-header(500);
}
.ff-system() {
	.ff(@ff: @ff-regular);
}
.ff-action(@fw:300){
	.ff(@fw, @font-face-action);
}

.ff-reg(@fw: 400) {
	.ff(@fw,@ff-regular);
}
.ff-cond(@fw: 400) {
	.ff(@fw,@ff-condensed);
}
.ff-econd(@fw: 400) {
	.ff(@fw,@ff-extracondensed);
}