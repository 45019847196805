.principal-group {
	.employees-box {
		.employees-list {
			.employee {
				&.role{
					&-lead {
						background-color: beige;
					}
				}
			}
		}
	}
}