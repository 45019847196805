@import '~less/include.less';

.create-building-modal {
	position: relative;
	height: 100%;
	width: 100%;
	> h3 {
		position: absolute;
		z-index: 2;
		top: 7.5px;
		left: 7.5px;
		//padding: 15px;
		display: inline-block
		//width: ~"calc(100% - 30px)";
	}
	// > *:not(.map-container) {
	// 	position: relative;
	// 	z-index: 2;
	// }
	.map-container {
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		z-index: 1;
		> .map {
			width: 100%;
			height: 100%;	
		}
		> .address {
			position: absolute;
			z-index: 2;
			width: 350px;
			top: 10px;
			left: 10px;			
			padding: 5px;
			background-color: rgba(255,255,255,.9);
			box-shadow: 0 0 7px 3px rgba(0,0,0,.1);
			.address-form {
				.form-item {
					margin-bottom: 5px;
					.form-element > i {
						font-weight: bold;
					}
					input {
						color: cornflowerblue;
						font-weight: bold;
					}

					&.two {
						> span {
							width:50%;
							display: inline-block;
						}
					}
				}
			}			
		}


		.search-places-wrapper {
			padding: 3px;
			padding-right: 35px;
			position: relative;
			.form-control {
				width: 250px;
				position: relative;
			}
			.icon {
				position: absolute;
				top: 9px;
				right: 41px;
				.icon('map-marker');
				color: #aaa;
				font-size: 22px;
			}
		}

	}


	.controls-container {
		position: absolute;
		right: 30px;
		top: 0;
	}

	.step-last {
		> * {
			padding: @gutter-half @gutter;
		}
		.props {
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;

			.editschema-in-line {
				footer {
					padding: @gutter-half 0;
				}
			}

		}
	}
}


// .map-input-control {
// 	&, &.form-control {
// 		width: 250px;
// 		margin-right: 35px;
// 	}
// }
