@import '~less/include.less';

.side-bar {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.result-container {
		height: 100%;
		position:relative;
		.unselect {
			display:none;
		}

		&.something-selected {
			.unselect {
				position:absolute;
				display:block;
				top:0;
				right:0;
				border:none;
				i {
					.icon('times');
					font-style:normal;
				}
			}
		}


		> *:first-child {
			height: 60px;
			overflow: hidden;
			&:not(.realty-item){
				.ff-header();
				font-weight: 500;
				font-size: 20px;
				line-height: 60px;
				text-align: center;

			}

			&.realty-item {
				overflow: hidden;

				.realty-info .details address {
					display: none;
					//white-space: normal;
				}
			}
		}
		/*.item {
			padding: @gutter;
			background-color: lighten(@brand-success,40);
		}*/
		.result {
			height: ~"calc(100% - 60px)";
			.empty-view {
				padding: 15px;
				text-align:center;
				font-weight: bold;
			}
		}


		.item {
			.realty-info {
				> span { display: none; }
				> div {
					display: flex;
					flex-direction: column;
					line-height: 1.2;
					text-align: left;
					align-items: center;
					justify-content: center;
				}
				address {
					margin: 0;
				}
			}
		}




	}
}
