.ui2-rows {
    display: flex;
    flex-direction: column;
    &.fixed-height {
        max-height: 100%;
        overflow-y: auto;
    }
    &.fixed-width {
        max-width: 100%;
        overflow-x: auto;
    }
    > * {
        margin: 5px;
    }
}