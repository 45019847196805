@import '~less/include.less';

// .v2-modal-container {
// 	z-index: 1000;
// }

.edit-properties-view {
	display: flex;
	flex-direction: column;
	overflow: auto;

	border-top: 1px solid rgba(0,0,0,.1);

	border-bottom: 1px solid rgba(0,0,0,.1);

	.value-with-label-view:nth-child(2n+1) {
		background-color: rgba(0,20,80,.01);
	}
	.value-with-label-view:nth-child(2n) {
		background-color: white;
	}

	.value-with-label-view {
		padding: @gutter-half 0;
		> :last-child {
			align-self: center;
		}

		&.required.empty {
			.value-label-text {
				color: tomato;
			}
		}

	}

	.media-tablet-up({
		min-width: 580px;
		.value-with-label-view {
			display: flex;
			flex-direction: row;
		}

		.value-label-view {
			width: 200px;
			text-align: right;
			font-weight: bold;
			flex-grow: 0;
			flex-shrink: 0;
			.value-label-text {
				display: block;
				padding: @gutter-half @gutter;
			}
		}

		.display-value-view, .edit-value-view {
			flex-grow: 1;
			flex-shrink: 1;
		}
		.edit-value-view {
			margin: 0 4.5px;
		}

		.display-value-view {
			.display-value-text {
				margin: 3px 4.5px;
				padding: 4.5px 3px;
				padding-left: 9px;
				> span {
			 //.schema-value {
				 display: block;
				 color: @brand-primary;
				 font-weight: bold;
				 //padding: @gutter-half @gutter;
			 }
			}
		}

	});

}

