@import '~less/include.less';
.map-control-searchRealty{
	.map-control-inputWithIcon-mixin('building');
	.icon.icon{
		font-size:20px;
		&:before {
		line-height: 34px;
		}
	}
}
