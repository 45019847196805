.bg-primary.bg-primary {
	background-color:@brand-primary;
	color:#fff;
}

.bg-success.bg-success {
	background-color: @brand-success;
	color: #fff;
}

.bg-info.bg-info {
	background-color: @brand-info;
	color: #fff;
}

.bg-warning.bg-warning {
	background-color: @brand-warning;
	color: #fff;
}

.bg-danger.bg-danger {
	background-color: @brand-danger;
	color: #fff;
}

.bg-default.bg-default {
	background-color: #777;
	color: #fff;
}