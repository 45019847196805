@import '~less/include.less';

.employees-box {

	> header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 35px;
	}

	.employees-list {
		.ui2-flex-column-mixin();
	}

	.employee-item {

		// &:not(:first-child) {
		// 	border-top: 1px solid #00000010;
		// }

		// .ui-line-mixin();
		// > div {
		// 	.make-ui-molecule();
		// }		

		.info {
			i {
				.ff-text();
				font-style: normal;
				text-transform: uppercase;
				font-weight: 500;
				color: cornflowerblue;
			}
			span {
				.ff-header();
				font-weight: 500;
				font-size: 18px;
			}
		}

		&:Hover {
			background-color: #00000010;
		}

		&:not(:Hover) {
			.remove-employee {
				visibility: hidden;
			}
		}
	}




}


.rights-box {
	.module-rights-values {
		list-style: none;
		padding: 0;
		margin: 0 @gutter;
		//margin-bottom: @gutter/2;
	}
	.module-rights-value-item {
		
		display: flex;

		&:not(:first-child) {
			border-top: 1px dashed #00000010;
		}

		padding: @gutter/2 0;

		&.checked {

			.checked-icon {
				.icon('check-square');
			}
		}
		&:not(.checked) {
			.checked-icon {
				.icon('square-o');
			}
		}

		&:hover {
			cursor: pointer;
			background-color: #00000010;
			border-radius: 3px;
			border-top-color: transparent;
			& + * {
				border-top-color: transparent;
			}
		}

	}
	.checked-icon {
		font-style: normal;
		margin: 0 @gutter;
		width: 12px;
	}

	.module-item {
		&:not(:first-child) {
			border-top: 1px solid #00000010;
		}
		&.has-changes {
			background-color:lighten(blanchedalmond, 5);
		}
		padding-bottom: @gutter/2;

		&.collapsed {
			padding-bottom: 0;
			.module-rights-values {
				display: none;
			}
			.module-item-info {
				.caret {
					transform: rotate(-90deg);
				}
			}
			&:hover {
				cursor: pointer;
				background-color: #00000010;
			}
		}
		&.off {
			.module-item-info {
				color: #aaa;
			}
		}
		&:not(.off) {
			.module-item-info {
				color: @brand-primary;
			}
		}
	}

	.module-item-info {
		.ff-header();
		font-weight: 500;
		padding: @gutter/2;
	}

}

.principal-group {
	flex-wrap: nowrap;
	
	.employees, .rights {
		min-width: 350px;
	}
}