.ui2-column {
    display: flex;
    flex-direction: column;
    width: 400px;
    &.fixed-height {
        max-height: 100%;
        overflow-y: auto;
    }
    > * {
        margin: 5px;
    }
}