@import '~less/include.less';


.text-outline(@c) {
	text-shadow: 	1px 0px 1px @c, 
								0px 1px 1px @c, 
								1px 1px 1px @c, 
								-1px 0px 1px @c, 
								0px -1px 1px @c, 
								-1px -1px 1px @c;
}

.ymaps3-point-size(@s) {
	@hs: (@s / 2);
	width: @s;
	height: @s;
	border-radius: @hs;
	margin-left: -@hs;
  margin-top: -@hs;
}
.ymaps3-pin-size(@w, @h) {
	width: @w;
	height: @h;
	margin-left: -(@w / 2);
	margin-top: -@h;
}

.yandex-default-marker {
	&.m-size-40 {
		.ymaps3-pin-size(30px, 40px);
		animation: map-bounce-1 .8s infinite linear;
		svg {
			fill: chartreuse;
			stroke: #00000080;
			stroke-width: 3px;
		}
	}
}

.yandex-metro-marker {
	.ymaps3-point-size(20px);
	opacity: .3;
	&:Hover {
		opacity: .6;
		cursor: pointer;
	}
}

.new-yandex-map-point {
	display: block;
	position: relative;
	// box-shadow:0 0 0 2px rgba(0,0,0,.2);
	color: white;
	&.round {
		background-color: tomato;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		&-16 {
			.ymaps3-point-size(16px);
		}
		&-24 {
			.ymaps3-point-size(24px);
		}
		&-32 {
			.ymaps3-point-size(32px);
		}
		&-40 {
			.ymaps3-point-size(40px);
		}
		&-64 {
			.ymaps3-point-size(64px);
		}
	}

	&.precise {
		width:1px;
		height:1px;
		background-color: red;
		box-shadow:0 0 0 5px red;
		> * {
			display: none;
		}
	}

	&.cluster {
		//background-color: fade(#906090, 50);
		font-weight: bold;
		color: white;
		font-size: 10px;
		text-align: center;
		z-index: 1;

		> div {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		// &.empty {
		// 	background-color: transparent;
		// }

		// &:hover {
		// 	background-color: #906090;
		// }		

		&.brick {
			&:not(.empty) {

				> div {
					width: 100%;
					height: 100%;
					// background-color: fade(#906090, 10);
					background: radial-gradient(#906090a0 0%, #90609000 70% );
					big, small {
						text-shadow: 1px 0px 1px black, 0px 1px 1px black, 1px 1px 1px black, 
						-1px 0px 1px black, 0px -1px 1px black, -1px -1px 1px black;
						color: yellow;
					}
	
					&:hover {
						background: radial-gradient(#906090ff 0%, #90609000 70% );
					}
				}
			}
		}

		&.round-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			&:not(.empty) {
				> div {
					background-color: #906090b0;
					.text-outline(#00000040);
					color: lighten(tomato, 20);
					box-shadow: 0 0 0 2px #00000060;
				}
				&.size-40 {
					> div {
						width: 40px;
						height: 40px;
						border-radius: 20px;
					}
				}
				&:hover {
					cursor: pointer;
					> div {
						background-color: #704070;
					}
				}
			}
		}

	}

	&.point {
		z-index: 2;
		font-weight: bold;
		box-shadow: 0 0 0 2px #00000060;
		&:hover, &.hovered {
			cursor: pointer;
			background-color: darken(tomato, 30);
		}

	}

	&.cluster {

		&.highlighted:not(:empty) {
			z-index: 2;
			> div {
				animation: map-cluster-view-blink .3s infinite;
			}
		}
	
		&.exist:not(:empty) {
			> div {
				background-color: orange;
			}
		}
	
		&.entry:not(:empty) {
			> div {
				background-color: cornflowerblue;
			}
		}
	
		&.selected-for-actions:not(:empty) {
			> div {
				background-color: darkolivegreen;
			}
		}


	}
	&.cluster.size-40 {
		&.selected {
			> div {
				width: 60px;
				height: 60px;
				border-radius: 30px;
				box-shadow:0 0 0 3px darkcyan;
				background-color: #704070;
			}
		}
	}
	&.point {
		&.selected-for-actions:not(:empty) {
			background-color: darkolivegreen;
		}
		&.entry {
			background-color: cornflowerblue;
		}

		&.highlighted:not(:empty) {
			z-index: 2;
			animation: map-cluster-view-blink .3s infinite;
		}
	
		&.exist:not(:empty) {
			background-color: orange;
		}
	
		&.entry:not(:empty) {
			background-color: cornflowerblue;
		}
		&.selected.selected {
			box-shadow:0 0 0 3px darkcyan;
			background-color: darken(tomato, 30);
		}
	}
	&.points:Hover, &.cluster:Hover, &.points.hovered {
		background-color: rgba(0,0,0,.1);
		cursor: pointer;
	}
}



@keyframes map-cluster-view-blink {
  0%, 49% {
    background-color:fade(#906090, 50);
		transform: scale(120%);
  }
  50%, 100% {
    background-color: fade(tomato, 50);
		transform: scale(100%);
  }
}

@keyframes map-bounce-1 {
	0%   { transform: translateY(0); }
	40%   { transform: translateY(0); }
	50%  { transform: translateY(-10px); }
	60% { transform: translateY(0); }
	100% { transform: translateY(0); }
}