@import '~less/include.less';

@modal-box-shadow: 0 0 7px 3px rgba(0,0,0,.4);
@modal-bg-color: #f8f8f8;
@border-radius: 3px;
@resolve-bg-color: @brand-primary;
@resolve-text-color: white;
@reject-bg-color: white;
@reject-text-color: black;
@rejectHard-bg-color: lighten(tomato, 30);
@rejectHard-text-color: black;


.v2-modal-container, .v2-modal-box-wrapper, .v2-modal-box, .v2-modal-content-container {
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.v2-modal-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	padding: @gutter-half;
}

.v2-modal-bg {
	background-color: rgba(0,0,0,.5);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}	


.v2-modal-box-wrapper {
	flex-grow: 1;
	flex-shrink: 1;
	align-items: center;
	&::before {
		content: '';
		max-height: 25%;
		flex-grow: 1; 
		flex-shrink: 1; 
	}
}

.v2-modal-box {
	position: relative;
	box-shadow: @modal-box-shadow;
	border-radius: @panel-border-radius;
	background-color: @modal-bg-color;
	z-index: 1;

}


.v2-modal-header {
	flex-grow: 0;
	flex-shrink: 0;
	width: 100%;
	display: flex;
	flex-direction: row;

	button, .fixed {
		flex-grow: 0;
		flex-shrink: 0;
	}
	button {
		box-shadow: none;
	}
	.v2-modal-header-text, .elastic {
		flex-grow: 1;
		flex-shrink: 1;
	}

}

.v2-modal-header-text {
	.ff-header();
	padding: @gutter-half @gutter;
	font-size: 20px;
	font-weight: bold;
}

.v2-modal-close-button {
	background-color: transparent;
	border-color: transparent;
	margin: 3px;
	border-radius: @border-radius;
	font-size: 18px;
	color: #ccc;
	&:Hover {
		background-color: rgba(0,0,0,.1);
		color: #888;
	}
}

.v2-modal-content-container {
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.v2-modal-footer {
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	padding: @gutter-half;
	column-gap: @gutter-half;
	row-gap: @gutter-half;

	button, .fixed {
		flex-grow: 0;
		flex-shrink: 0;
	}

	.separator.reject-rejecthard {
		flex-grow: 1;
		flex-shrink: 1;
	}

	.modal-button-collor-mixin(@bg, @txt) {
		background-color: @bg;
		color: fade(@txt, 80);
		&:not(.wait):not(.disabled):not(:disabled):Hover {
			background-color: darken(@bg, 10);
			border-color: darken(@bg, 20);
			color: @txt;
		}
		&.disabled, &:disabled {
			background-color: lighten(@bg, 20);
			color: @txt;
			&:Hover {
				color: @txt;
			}
		}
	}

	button {
		background-color: transparent;
		border: 1px solid transparent;
		border-radius: @border-radius;
		padding: 5px @gutter;
		.ff-header();
		font-weight: 700;
		font-size: 18px;
		&:not(.wait):not(.disabled):not(:disabled):Hover {
			cursor: pointer;
			background-color: rgba(0,0,0,.1);
		}
		&.resolve {
			.modal-button-collor-mixin(@resolve-bg-color, @resolve-text-color);
		}
		&.rejectHard {
			.modal-button-collor-mixin(@rejectHard-bg-color, @rejectHard-text-color);		
		}
	}

}

.v2-modal-content-container > .message-text {
	padding: @gutter;
	font-size: 20px;
}

.default .v2-modal-box {
	width: 100%;
	max-width: 800px;
}

.fullscreen .v2-modal-box {
	width: 100%;
	height: 100%;
}

