@import '~less/include.less';

.client.client-in-popup{
	font-size:12px;
	max-width:600px;
	width:auto;
	display:block;
	.ff-header(400);

	.note{
		white-space:pre-wrap;
	}

	.sys{
		font-size:10px;
		white-space:nowrap;
		margin: 0 -14px;
		padding: 4px 14px;
	}

	> *:first-child{
		margin-top:-9px;
	}
	> *:last-child{
		margin-bottom:-9px;
	}


	.deal {
		margin: 0 -14px;
		padding: 4px 14px;
		font-weight:400;
		color:@brand-primary;
		.hover-darken();
	}

	.deals-region{
		margin-bottom:10px;
		> div {
			>*:nth-child(2n){
				background-color:rgba(0,0,80,.05);
				&:Hover{
					background-color:rgba(0,0,80,.1);
				}
			}
		}
	}

	.header{
		font-weight:700;
		font-style:italic;
	}

	.empty-deals{		
	}

}