
.modals-dialog.offer-modal-dialog{
 
	.modal-box.fullwidth{
		max-width:992px;
		margin:auto;
	}
}

.board-list-region.offerInfo {
	.block {
		margin-top: 15px;
	}
}

.block .owner-contacts-box {
	.list-btn {

		> small:last-child {
			top: unset;
			bottom: 2px;
		}

		> span {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			column-gap: 7.5px;
			box-sizing: border-box;
			> * {
				flex-grow: 1;
				flex-shrink: 1;
				width: 50%;
				height: 23px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			padding: 15px;
		}

	}
}