.dropdown-menu-inputed {
	li.radio {
		margin:0;
	}

	li > label {
		display: block;
		margin: 0;
		padding: 0;

		input {
			display: none;
		}

		input + span:not(.icon), input ~ span.input-label {
			&:extend(.dropdown-menu > li > a);

			&:Hover {
				&:extend(.dropdown-menu > li > a:hover);
				cursor: pointer;
			}
		}

		input:checked + span:not(.icon), input:checked ~ span.input-label {
			&:extend(.dropdown-menu > .active > a);
		}
	}
}
