.collapse-neighbors{

}

.collapse-neighbors-trigger{
	cursor:pointer;
}

.collapse-neighbors ~ * {
	display:none;
	//height: 0px;
	//overflow:hidden;
}
