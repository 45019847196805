@import "~less/include.less";
.preloader-container {
	&.fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #ffffffc0;
		.preloader-box {
			flex-grow: 0;
			flex-direction: 0;
			i.fa {
				font-size: 40px;
				color: @brand-primary
			}
		}
	}
}