
.ui-column {
	
	display: flex;
	flex-direction: column;

	width: @uiColumnWidth;
	padding: @uiColumnPadding;
	margin: @uiColumnMargin;

	&.width-2x {
		width: @uiColumnWidth * 2 + @uiColumnPadding * 2;
	}
}

.ui-columns-layout {
	display: flex;
	flex-direction: row;
	
}

.box-2 {

	.make-container-with-borders-3();

	&.with-borders {
		.box-2-color-mixin();
	}


	header {
		//.ui-line;
		.ui-line-mixin();
		> div > span {
			.ff-header(500);
			text-transform: uppercase;
		}
	}

	footer {
		> * {
			//.ui-line;
			.ui-line-mixin();
		}
	}

	// > footer > button {
	// 	padding: 0;
	// 	border: none;
	// 	background-color: transparent;
	// 	text-align:left;
	// }

}


.ui-line {
	
	.make-ui-line();
	
	&.with-molecules {
		.make-ui-line-with-molecules(true);
	}
	&.align-items-center {
		.make-ui-line-align-items(center);
		// > button, > .icon, > .inner-button {
		// 	align-items: center;
		// }
	}
	&.align-items-baseline {
		.make-ui-line-align-items(baseline);
		// > button, > .icon, > .inner-button {
		// 	align-items: baseline;
		// }
	}
}
.ui-molecule {
	.make-ui-molecule();
}


.ui-button {
	border: none;
	background-color: transparent;
	.clean-button-mixin();
}
