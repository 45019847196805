.editValue-control {
	.control-region {
		> .input-control {
			> .input-description {
				padding-bottom: 15px;
			}
			> textarea {
				height: 600px;
			}
		}
	}
}
