@block-bg-default: #f8f8f8;
@block-color-default: #444;

.block-shadow(@show: false) {
	& when (@show = true) {
		//box-shadow:0 0 5px 3px rgba(100,100,100,0.1);
		.shadow-smooth(true);
	}

	& when (@show = false) {
		box-shadow: none;
	}
}

.block-colorize( @section: transparent, @clr-main: #444, @solid: darken, @header: transparent, @footer: transparent, @clr-accent: #444 ) {
	color: @clr-main;

	& when (@solid = false) {
		border-color: darken(@header,10%);
		background-color: @section;

		> header {
			background-color: @header;
			color: @clr-accent;
			border-bottom-color: darken(@header,10%);
		}

		> footer {
			border-top-color: darken(@header,10%);
			background-color: @footer;
			color: @clr-accent;
		}
	}

	& when (@solid = darken) {
		border-color: darken(@section,10%);
		background-color: @section;

		> header {
			background-color: darken(@section,5%);
			border-bottom-color: darken(@section,10%);
		}

		> footer {
			border-top-color: darken(@section,10%);
			background-color: darken(@section,5%);
		}
	}

	& when (@solid = true) {
		border-color: darken(@section,10%);
		background-color: @section;
	}
}

.block-cls-variant(@bg: @block-bg-default, @clr: @block-color-default) {
	&.solid {
		.block-colorize(@bg, @clr, darken);
	}

	&.solid-flat {
		.block-colorize(@bg, @clr, true);
	}

	&:not(.solid):not(.solid-flat) {
		.block-colorize(@block-bg-default, @block-color-default, false, @bg, @bg, @clr);
	}
}

.block-mixin() {
	.panel();
	margin-bottom: 0;
	position: relative;

	> header {
		.panel-heading();
		.ff-header();
		position: relative;
		&.small {
			padding-top: 3px;
			padding-bottom: 3px;
			line-height: 26px;
			font-weight: bold;
			text-transform: uppercase;
		}

		> big {
			font-weight: 400;
		}

		.close{
			color: white;
			text-shadow: 0 1px 0 black;
			position: absolute;
			right: 0;
			/* height: 100%; */
			top: 0;
			bottom: -1px;
			padding: 0 10px;
			border-radius: 0;
			background-color: rgba(0,0,0,.4);
			opacity: 1;
			&:Hover{
				background-color: rgba(0,0,0,.2);
			}
			&:focus, &:active{
				outline:none;
			}
		}

		> button {
			position:absolute;
			right:5px;
			padding: 2px 5px;
		}
	}

	> section {
		.panel-body;
	}

	> ul, ul.like-list-group {
		&:extend(.list-group);
		text-transform: lowercase;
		//.list-group();
		background-color: transparent;
		margin-bottom: 0;
		font-size: 0;

		> li {
			.list-group-item();
			background-color: transparent;
			position: relative;
			border-color: transparent;
			/*border-left-color: transparent;
			border-right-color: transparent;*/
			margin: 0;
			border-left-width: 0;
			border-right-width: 0;
			border-top-color: rgba(0,0,0,.05);

			&.list-btn, &.list-lbl {
				padding: 0;

				> small {
					font-size: 10px;
					padding: 0;
					padding-left: @gutter;
					position: absolute;
					color: #444;
					font-weight: 500;
					padding-top: 3px;
					white-space:nowrap;
					max-width:100%;
					overflow:hidden;
					text-overflow:ellipsis;
					& + *:not(small) {
						padding: 15px @gutter 5px;
					}
				}

				> *:not(small) {
					padding: 10px @gutter;
					display: block;
					.ff-header();
					font-size: 16px;
					font-weight: 500;


					> small {
						color: #777;
						line-height: 1;
						display: block;
						font-size: 10px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				> button.close {
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					width: @gutter2;
					opacity: 1;
					//box-shadow: none;
					text-align: center;
					padding: 0;
					font-size:24px;
					/*.hover-darken();
					&:not(:hover){
						box-shadow: none;
					}
					&:focus, &:active {
						background-color:unset;
						outline:none;
						.hover-darken();
					}*/
					.hover-darken-btn-transparent();
					& + span {
						padding-right:@gutter2;
					}
				}

				> i {
					position:absolute;
					top:0;
					right:0;
					height:100%;
				}

				&.halfed {
					display: inline-block;
					/*border-top-color: rgba(0,0,0,.1) !important;
					border-bottom-color: transparent;*/
					width: 50%;
					text-overflow: ellipsis;
					overflow: hidden;
					> span {
						white-space:nowrap;
					}
					&:first-child + .halfed {
						border-top-color: transparent;
					}
				}
			}

			&.list-btn > *:not(small) {
				color: @brand-primary;
				.hover-darken();
				.hover-nounderline();
			}

			&.list-btn {
				/*.hover-darken();
				.hover-nounderline();*/

				&:Hover {
					border-top-color: transparent;
				}
			}


			&:first-child {
				border-top-color: transparent;
			}

			&:last-child {
				border-bottom-color: transparent;
			}

			> .close {
				position: absolute;
				height: 100%;
				padding: 0 @gutter-half;
				text-align: center;
				top: 0;
				right: 0;
			}
		}
	}

	> footer {
		.panel-footer;
		border-color: transparent;
		background-color: transparent;
	}

	.system {
		.ff-header();
		font-size: 10px;
		font-weight: 500;

		&-top {
			.ff-header();
			position: absolute;
			top: 0;
			font-size: 10px;
			font-weight: 500;
		}

		&-top-right {
			.ff-header();
			font-size: 10px;
			font-weight: 500;
			position: absolute;
			top: 0;
			right: 0;
			padding: 0 5px;
			text-align: right;
		}

		&-top-left {
			.ff-header();
			font-size: 10px;
			font-weight: 500;
			position: absolute;
			top: 0;
			right: 0;
			padding: 0 @gutter;
			text-align: right;
		}
	}

	.block-line {
		padding: 0px @gutter;
	}

	.block-line-system {
		.ff-header();
		font-size: 10px;
		font-weight: 500;
		line-height: 1;
		padding: 0px @gutter;
		padding-top: 5px;
	}

	.block-line-small {
		.block-line;
		.ff-header();
		font-size: 10px;
		font-weight: 400;
	}
}

.block-list-item-mixin()
{
	padding: 0;

	> small {
		font-size: 10px;
		padding: 0;
		padding-left: @gutter;
		position: absolute;
		color: #444;
		font-weight: 500;
		padding-top: 3px;
		white-space:nowrap;
		max-width:100%;
		overflow:hidden;
		text-overflow:ellipsis;
		& + *:not(small) {
			padding: 15px @gutter 5px;
		}
	}

	> *:not(small) {
		padding: 10px @gutter;
		display: block;
		.ff-header();
		font-size: 16px;
		font-weight: 500;


		> small {
			color: #777;
			line-height: 1;
			display: block;
			font-size: 10px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	> button.close {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: @gutter2;
		opacity: 1;

		text-align: center;
		padding: 0;
		font-size:24px;

		.hover-darken-btn-transparent();
		& + span {
			padding-right:@gutter2;
		}
	}

	> i {
		position:absolute;
		top:0;
		right:0;
		height:100%;
	}

	&.halfed {
		display: inline-block;

		width: 50%;
		text-overflow: ellipsis;
		overflow: hidden;
		> span {
			white-space:nowrap;
		}
		&:first-child + .halfed {
			border-top-color: transparent;
		}
	}
}
.block-list-item-borders-mixin(){
    border-bottom: 1px solid rgba(0,0,0,.1);
    border-top: 1px solid rgba(255,255,255,.1);
}
.block-list-item-btn-mixin(){
	&:Hover{
		background-color:rgba(0,0,0,.1);
		border-color:transparent;
		cursor:pointer;
		& + * {
			border-top-color:transparent;
		}
	}
}
.block-list-mixin()
{
	list-style:none;
	padding:0;
	margin:0;
	font-size:0;
	> li {
		.block-list-item-mixin();
		.block-list-item-borders-mixin();
		&.button{
		.block-list-item-btn-mixin();
		}
	}
}


.block-create-solid-flat(@shadow: true, @bg: #fff, @clr: #444) {
	.block-mixin();
	.block-shadow(@shadow);
	.block-colorize(@bg, @clr, true);
}


.block() {
	.block-mixin();
	.block-shadow(true);
	&.no-border{
		border-width:0px;
	}
	&.no-shadow {
		.block-shadow(false);
	}

	&.block-card {
		margin: @gutter;
		width: auto;
		display: block;

		@media(min-width:664px) {
			display: inline-block;
			width: 300px;
		}
	}

	&.block-short {
		margin: @gutter;
		width: auto;
		display: block;

		@media(min-width:664px) {
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
		}

		@media(min-width:@screen-lg-min) {
			margin: @gutter;
			max-width: 800px;
		}
	}

	&.white {
		.block-cls-variant();
	}

	&.primary {
		.block-cls-variant(@brand-primary, #fff);
	}

	&.success {
		.block-cls-variant(@brand-success, #fff);
	}

	&.info {
		.block-cls-variant(@brand-info, #fff);
	}

	&.warning {
		.block-cls-variant(@brand-warning, #fff);
	}

	&.danger {
		.block-cls-variant(@brand-danger, #fff);
	}

	&.column {
		display: inline-block;
		height: 100%;
		width: @global-column-width;
	}


	&.li-buttons {
		> ul > li {
			.hover-darken();
		}
	}

	> ul > li.btnable {
		.hover-darken();
	}

	& + .block{
		.offset-t();
	}

	&.block-btn{
		&:Hover{
			box-shadow: 0px 0px 3px 0px @brand-success;
			cursor:pointer;
		}
	}

	&.image-thumb{
		> section {
			padding:9px;
			img + img {margin-top:7px;}
		}
	}

	& ~ .block {
		margin-top:@gutter;
	}

}
