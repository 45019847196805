.floatui-popup {
    background: #333;
    color: white;
    [data-role="arrow"] {
        position: absolute;
        background: #333;
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
    }
}