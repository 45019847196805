@import '~less/include.less';

.deals-search {

	.deal {
		display: inline-block;
		vertical-align: middle;
		width: 300px;
		margin-right: @gutter;
	}
}
