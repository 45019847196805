@import '~less/include.less';

@select-control-selected-color: @brand-primary;


.select-control {
	display: flex;
	flex-direction: column;

	overflow: auto;
	> :not(.select-entries) {
		flex-grow: 0;
		flex-shrink: 0;
	}
	
	row-gap: @gutter;

	.select-entries {
		flex-grow: 1;
		flex-shrink: 1;
		display: flex;
		flex-direction: column;
		overflow: auto;
		user-select: none;
	}
	&.single-line .select-entries {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.select-segments {


		.tab-buttons-mixin();

		.segment-button {
			.tab-button-mixin(@select-control-selected-color);
			

		}

	}

	.select-search {
		padding: 0 @gutter;
		position: relative;
		input {
			.input-mixin();
			padding-right: 24px;
		}
		.icon {
			position: absolute;
			right: 20px;
			top: 5px;
			i {
				.icon(search);
			}
		}
	}


	.select-entry {
		display: flex;
		flex-direction: row;
		margin: 3px;
		border-radius: @border-radius;
		cursor: pointer;

		.select-icon {
			flex-grow: 0;
			flex-shrink: 0;
			padding-top: @gutter-half;
			padding-left: @gutter-half;
		}

		> *:not(.select-icon) {
			flex-grow: 1;
			flex-shrink: 1;
		}

		&:Hover {
			background-color: rgba(0,0,0,.1);
		}

		.select-entry-label.default {
			padding: @gutter-half @gutter;
		}

		&.selected {
			.select-icon {
				color: @select-control-selected-color;
			}
			.select-entry-label.default {
				color: @select-control-selected-color;
				font-weight: bold;

			}
		}
	}



	&.multiple .select-entry {
		&.selected .select-icon > i { .icon(check-square-o); }
		&:not(.selected) .select-icon > i { .icon(square-o, regular); }
	}

	&.single .select-entry {
		&.selected .select-icon > i { .icon(check-circle-o, regular); }
		&:not(.selected) .select-icon > i { .icon(check-circle-o, regular); color: rgba(0,0,0,.1); }
	}

	.select-icon > i {
		font-style: normal;
	}




}