.text-shadow-outline(@color) {
	text-shadow: 1px 1px @color, 1px 0 @color, 0 1px @color, 0 0 @color,
	-1px -1px @color, -1px 0 @color, 0 -1px @color;
}

.two-icons-last(@rule) {
	> span > i:last-child {
		@rule();
	}
}
.two-icons-first(@rule) {
	> span > i:first-child {
		@rule();
	}
}

.two-icons-sizes(@big, @small) {
	.two-icons-first({
		font-size: @big;
	});
	.two-icons-last({
		font-size: @small;
	});
}

.two-icons-mixin(@first, @last) {
	.two-icons-first(@first);
	.two-icons-last(@last);
}
