@import "~less/include.less";

.edit-address-container {
    position: relative;

    .map-holder {
        width: 100%;
        height: 100%;
    }

    .address-info {
        position: absolute;
        top: 5px;
        left: 5px;
				max-width: 600px;
        >.wrapper {
            margin: 5px;
            box-shadow: 0 0 5px 3px #00000010;
            background-color: white;

					.full-address {
						.ff-header();
						padding: 3px 5px;
						font-weight: bold;
						font-size: 18px;
						line-height: 1;
					}

            // height: 100px;
            // width: 100px;
            > :not(button):not(.clean):not(.full-address) {
                padding: 3px 5px;
                .ff-header();
            }

            .clean {
                > span {
									display: inline-block;
									padding: 3px 5px;
									.ff-header();
                }
            }

            .button-info {
                padding-inline-start: 17px;
                color: cornflowerblue;

                li {
                    list-style: none;
                    position: relative;

                    &:not(.clickable):before {
                        content: "•";
                        font-size: 60px;
                        line-height: 10px;
                        //padding-right: 10px;
                        display: block;
                        position: absolute;
                        top: 6px;
                        left: -17px;
                    }

                    input {
                        margin: 0;
                        display: inline-block;
                        position: relative;
                        top: 2px;
                    }

                    &.clickable {
                        color: black;

                        &:Hover {
                            * {
                                cursor: pointer;
                            }

                            label {
                                text-decoration: underline dotted;
                            }
                        }
                    }
                }

            }

            >button {
                margin: 5px;
            }
        }
    }

		.controls-container {
			position: absolute;
			right: 30px;
			top: 0;
		}

		.search-places-wrapper {
			position: relative;
			padding: 10px;

			.icon {
				.icon('map-marker');
				position: absolute;
				top: 16px;
				right: 15px;
				font-size: 22px;
				color: #aaa;
			}
		}

}