@import '~less/include.less';


@module-header-height-mobile: 50px;
@module-header-height-desktop: 50px;
@module-header-height: @module-header-height-desktop;
@module-nav-width: 200px;
@module-shortnav-width:4px;


body > section.module {
	height: 100%;


	> header {
		height: @module-header-height;

		> div {
			display: table;
			width: 100%;
			height: 100%;

			> * {
				display: table-cell;
				height: 100%;
				vertical-align: middle;
				&.header {
					width: 100%;
				}
				&.header-action {
					width: 50px;
					height: 100%;
					.hover-darken();
					text-align: center;

					> * {
						width: 50px;
						font-size: 24px;
					}
				}

				&.header-options {
					display: none;

					> span {
						.icon('ellipsis-v');
					}
				}

				&.fast-links {
					> span {
						.icon('bars');
					}
				}


				&.header-back {
					display: none;
					width: 30px;
					min-width: 30px;
					text-align: center;
					.hover-darken();

					> span {
						.icon('arrow-left');
						line-height: 1;
					}
				}
			}
		}

		.header {
			padding-left: @gutter;
			//padding-right: @gutter;
			position: relative;
			overflow: hidden;

			> span {
				.ff-header-default;
				font-size: 24px;
				color: @brand-primary;
				font-weight: 500;
				line-height: 0;
				position: absolute;
				white-space: nowrap;
			}
		}

		&.show-back .header-back {
			display: table-cell;
		}

		&.show-options .header-options {
			display: table-cell;
		}
	}

	> section {
		height: ~"calc(100% - @{module-header-height-mobile})";
		padding: 0.1px 0;
		margin: -.1px 0;

		@media(min-width:@screen-sm-min) {
			height: ~"calc(100% - @{module-header-height-desktop})";
		}

		&.flex-page {
			display: flex;
			overflow: auto;
		}
		&.flex-column {
			flex-direction: column;
		}
		&.flex-row {
			flex-direction: row;
		}

		> div {
			position: relative;
		}
	}

	> nav {
		position: fixed;
		top: 0;
		bottom: 0;
		//width: @module-nav-width;
		left: -@module-nav-width + @module-shortnav-width;
		width: @module-nav-width;
		overflow-y: auto;
		overflow-x: hidden;
		z-index: 4;
		background-color: @brand-success;
		color: #fff;
		display: none;
		.ff-header();
		font-weight: 400;
		text-transform: uppercase;

		&:Hover {
			left: 0;
		}

		a {
			color: #fff;
		}

		.nav-back .prefix {
			.icon('arrow-left');
			margin-right: 5px;
			color: lighten(@brand-success,20%);

			& + * {
				color: lighten(@brand-success,20%);
				.ff-action();
			}
		}

		.nav-deeper .postfix {
			.pull-right-mixin();
			.icon('arrow-right');
		}

		.nav-deeper li > a > span {
			display: inline-block;
			max-width: calc(100% - 15px);
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		li a {
			.hover-lighten();
		}

		.module-links-region:not(.off) + .module-links-region:not(.off) {
			border-top: 10px solid rgba(0,0,0,.05);
		}

		.back-link-region:not(.off) ~ .module-links-region:not(.off) {
			border-top: 10px solid rgba(0,0,0,.05);
		}
	}


	&.module-with-nav {
		> header, > section {
			@media (min-width:@screen-md-min) {
				margin-left: @module-shortnav-width;
			}
		}

		> nav {
			@media (min-width:@screen-md-min) {
				display: block;
			}
		}
	}


	&.short-module {
		position: relative;
		.block();
		.block-shadow(true);
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
		top: 10%;
		height: auto;

		> header {
			box-shadow: none;
			border: none;
			background-color: transparent;
			height: auto;

			.header {
				padding: 0;
				box-shadow: none;
				border: none;
				background-color: transparent;
				margin-bottom: @gutter;
				border-bottom: 1px solid rgba(0,0,0,.1);
				height: 26px;
			}
		}
	}

	&.flex-page {
		display: flex;
		overflow: auto;
	}
	&.flex-column {
		flex-direction: column;
	}
	&.flex-row {
		flex-direction: row;
	}

}

.fast-links-container {
	position: fixed;
	top: 50px;
	right: 0;
	width: 250px;
	background-color: @brand-success;
	color: #fff;
	box-shadow: 0 0 5px 1px rgba(0,0,0,.2);
	z-index:2;
	> * {
		display: block;
		padding: 4px @gutter/2;
		.ff-header();
	}

	a {
		color: #fff;
		.ff-header();
		font-weight:500;
		text-transform:uppercase;
		i {
			font-style:normal;
			margin-right:5px;
			.icon('chevron-right');
		}
		padding:10px @gutter/2;
		border-bottom:1px solid rgba(0,0,0,.1);
		&:Hover{
			cursor:pointer;
			background-color:rgba(0,0,0,.1);
			text-decoration:none;
		}
	}

	div {
		font-size: 0;

		> * {
			height: 35px;
			border-width: 1px;
			display: inline-block;
			vertical-align: middle;
		}

		input {
			width: ~"calc(100% - 40px)";
			font-size: 16px;
			border: 1px solid darken(@brand-success,10);
			border-right-width: 0;
			background-color: #fff;
			padding-left:@gutter/2;
			color:#444;
		}

		button {
			font-size: 16px;
			width: 40px;
			text-align: center;
			padding-left: 0;
			padding-right: 0;
			border: 1px solid darken(@brand-success,10);
			border-left-width: 0;
			background-color: #fff;
			border-radius:0;
			i {
				.icon('search');
				font-style: normal;
			}
		}
	}
}


.module-nav {
	.VERSION {
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 4px;
	}
}

.module-run-error-message {
	padding: @gutter;
	margin: @gutter;
	background-color: darkred;
	color: white;
	border:4px dashed rgba(255,255,255,.5);
}