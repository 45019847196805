@import '~less/include.less';

.leads-layout {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.actions-panel + .result > .leads-stats {
	margin-left: @actions-panel-width - @gutter;
}

.result > .leads-stats {
	padding: @gutter / 2;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.filters-region, .segments-region {
		width: 50%;
	}

	.stats-region {
		width: 100%;
		min-width: 600px;
		padding: @gutter / 2;
	}
	.filters-region {
		//padding-left: @gutter / 2;
		padding: @gutter / 2;
	}
	.segments-region {
		//padding-right: @gutter / 2;
		padding: @gutter / 2;
	}

	@media (max-width: @screen-xs-max) {
		.filters-region, .segments-region {
			width: 100%;
		}
	}

	.stats-region  ul.stats-ul {
		max-width:800px;
		margin: 0;
		padding: 0;

		ul {
			display: none;
		}

		.stat-item.uncollapse + ul {
			display: block;
		}

	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}



	.segment-button {
		.btn;
		.btn-default;
		padding:0;
		font-size:0;
		> * {
			padding:5px 10px;
			display:inline-block;
			font-size:14px;
			vertical-align:middle;
		}
		i {
			font-style:normal;
			&:Hover {
				background-color:rgba(0,0,0,.1);
				cursor:pointer;
			}
		}
		i.start {
			.icon('chevron-left');
		}
		i.end{
			.icon('chevron-right');
		}
		&:first-child i.start {
			display:none;
		}
		&:last-child i.end {
			display:none;
		}

	}


	.stat-item {
		display: flex;
		flex-flow: row;
		border-bottom: 1px solid #eee;
		padding: 4px;
		&:Hover {
			background-color:fade(@brand-success,10%);
		}
		&.level0 {
			i:nth-of-type(1) {
				font-weight: 500;
			}
		}

		> b {
			display: block;
		}

		> button {
			flex-grow: 0;
			padding: 0;
			margin: 0;
			border: none;
			box-shadow: none;

			&:focus, &:active {
				outline: none;
			}

			i {
				.icon('plus-square-o');
				font-style: normal;
				padding: 0 5px;
				color: rgba(0,0,0,.3);
			}
		}

		> i {
			flex-grow: 0;
			display: inline-block;
			font-style: normal;
			text-align: center;
			padding: 0 5px;
			width: 80px;
			flex-basis: 80px;

			&:nth-of-type(1) {
				flex-grow: 3;
				overflow: hidden;
				white-space: nowrap;
				text-align: left;
				//padding-left: 0;
				width: 100%;
			}
		}



		&.uncollapse > button > i {
			.icon('minus-square-o');
			color: rgb(0,0,0);
		}
	}

	.pinned-filters-region {
		//padding:10px;
		margin-left: -5px;
		margin-right: -5px;
		margin-bottom: @gutter;
	}

	.filters-control-item {
		line-height:1;
		min-width: 150px;
		max-width: 200px;
		display:inline-block;
		border:none;
		margin:5px;
		box-shadow:0 0 15px 0px rgba(0,0,0,.1);
	}

	.block {
		overflow: hidden;
		> h3 {
			padding-left: @gutter;
			border-left:4px solid tomato;
			white-space:nowrap;

		}
		@media (max-width: @screen-xs-max) {
			> h3 {
				&:hover {
					cursor:pointer;
				}
				~ * {
					display:none;
				}
				&:after {
					content:'кликните чтобы раскрыть';
					text-align:center;
					display:block;
					font-weight:normal;
					font-size: 16px;
				}
			}
			&.uncollapsed {
				> h3:after {
					display:none;
				}
				> h3 ~ * {
					display:block;
				}
			}
		}
	}

}


.sell-funnel {
	.funnel-items {
		.funnel-item {
			position:relative;
			text-align:center;
			width: 100%;

			.info {
				position:relative;
				> :not(.bar) {
					position:relative;
				}
				.name {					
					font-size: 24px;
					font-weight:700;
				}
				.bar {
					position:absolute;
					z-index:0;
					width:100%;
					height:100%;
					> * {
						height:100%;
						background-color: fade(lightblue, 50);
						border-radius: 4px;
						margin-left: auto;
						margin-right: auto;
					}
				}
			}

			.changes {				
				big, small {
					display:inline-block;
					font-size:0;
					> span {
						font-size:0;
						display:inline-block;
						padding:0 5px;
						b {
							text-align:center;
							display:block;
							font-size: 18px;
						}
						i {
							text-align:center;
							display:block;
							font-size: 12px;
						}
					}
				}

			}

			&.has-canceled .changes .canceled-indi {
				color: tomato;
			}

			> i {
				.icon('long-arrow-down');
				font-size: 32px;
				display:inline-block;
				font-style:normal;
				color:#aaa;
			}
			&:last-child {
				> i {
					display:none;
				}
				&.done {
					color: darken(@brand-success,10);
					.changes small {
						display:none;
					}
				}
			}
		}
	}
}
