.entity-property {
    &.editable {
        &:hover {
            cursor: pointer;
            background-color: #00000010;
            button {
                background-color: transparent;
            }
        }
    }
		&.has-changes {
			background-color:floralwhite;
		}
}