@emptyRule: {};

.ui2-flex-fixed-height-mixin() {
    &.fixed-height {
        max-height: 100%;
        overflow-y: auto;
    }
}

.ui2-flex-fixed-width-mixin() {
    &.fixed-width {
        max-width: 100%;
        overflow-x: auto;
    }
}

.ui2-flex-border-property-mixin(@property, @color: #00000010, @width: 1px, @type: solid) {
    &.with-borders {
        > :not(:first-child) {
            @{property}: @width @type @color;
        }
    }
}

.ui2-flex-border-vertical-mixin(@color: #00000010, @width: 1px, @type: solid) {
    .ui2-flex-border-property-mixin(border-top, @color, @width, @type)
}

.ui2-flex-border-horizontal-mixin(@color: #00000010, @width: 1px, @type: solid) {
    .ui2-flex-border-property-mixin(border-left, @color, @width, @type)
}

.ui2-flex-elastic-item-mixin(@intesivity: 1) {
    flex-grow: @intesivity;
    flex-shrink: @intesivity;
}

.ui2-flex-fixed-item-mixin() {
    flex-grow: 0;
    flex-shrink: 0;
}



.ui2-flex-row-mixin() {
    display: flex;
    flex-direction: row;
    .ui2-flex-fixed-width-mixin();
    .ui2-flex-border-horizontal-mixin();    
}

.ui2-flex-column-mixin() {
    display: flex;
    flex-direction: column;
    .ui2-flex-fixed-height-mixin();
    .ui2-flex-border-vertical-mixin();    
}
