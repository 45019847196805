
@import '~less/include.less';


#notifier {
	position: fixed;
	bottom: 5px;
	left: 5px;
	z-index: 2;

	.notifier-item {
		.block();
		.block-shadow(true);
		width: 300px;
		position: relative;

		.message {
			.ff-header();
			font-weight: 400;
			font-size: 10px;
			line-height: 1;
			padding: 5px @gutter;
			max-height: 55px;
			overflow-y: hidden;
		}

		.note-header {
			font-size: 10px;
			line-height: 1;
			padding: 5px @gutter;
		}

		.actor {
			.ff-header();
			font-weight: 500;
			color: #888;
			padding: 5px @gutter;
			line-height: .8;
			padding-top: 0;
		}

		.close {
			position: absolute;
			top: 0;
			right: 0;
			padding-left: 5px;
			padding-right: 5px;
		}

		& + .notifier-item {
			margin-top: 5px;
		}

		&.notify-fail {
			background-color: @brand-danger;
			color: #fff;

			.message {
				font-size: 15px;
			}
		}

		&.notify-new-comment {
			.message {
				font-size: 16px;
				font-weight: 500;
			}
		}

		&.clickable{
			.hover-darken(#ffe);
		}
	}
}
