@import '~less/include.less';

.comment-item {
	padding: @gutter;
	position: relative;

	.comment-date {
		position: absolute;
		top: 0;
		right: @gutter;
		.ff-header();
		font-size: 10px;
		font-weight: 400;
	}

	.comment-info {
		position: relative;
	}

	.comment-author {
		display: block;
		.ff-header();
		font-weight: 400;
		color: #888;
		font-size: 14px;
	}

	.comment-author

	, .comment-date {
		line-height: 16px;
	}
	.comment-subject{
		font-size:12px;
		.ff-header();
		font-weight:700;
		color: @brand-info;
	}
	.comment-text {
		font-size: 14px;
		font-weight: 500;
		white-space:pre-wrap;
	}

	[data-role="link"]{
		white-space:nowrap;
		color:@brand-primary;
	}

}

.board-list-region.comments {
	.comments-block {
		height: 100%;
		.block();
		.block-shadow(true);

		.add-comment-box {
			padding: @gutter;
			height: 130px;

			textarea {
				margin-bottom: 5px;
			}
		}

		.comments-region {
			height: ~"calc(100% - 130px)";
			//padding-top: @gutter;
			> div {
				height: 100%;
				/*.block();
				.block-shadow(true);*/
			}
		}
	}
}
