.ui-list {
	list-style: none;
	margin:0;
	padding:0;
}

.ui-list-item {
	list-style: none;
	display:flex;
	flex-direction: row;
	width: 100%;
	> * {
		flex-grow: 1;
		flex-shrink: 1;
		padding: 7.5px;
	}
	> button {
		flex-grow: 0;
		flex-shrink: 0;
		border: none;
		background-color: transparent;
		box-shadow: none;
		&:Hover {
			cursor: pointer;
			background-color: rgba(0,0,0, .1);
		}
	}
}
