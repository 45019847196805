@import '~less/include.less';

.entity-edit-page {

	.owner-contacts-page {
		max-width: 1000px;
		margin: auto;
		display: flex;
		flex-direction: column;
		overflow: auto;
		.actions-container {
			padding: @gutter;
			flex-grow: 0;
			flex-shrink: 0;
		}
		.contacts-list {
			flex-grow: 1;
			flex-shrink: 1;
			overflow: auto;

			.contact-list-item {
				position: relative;
				> .ui2-atom {
					margin-bottom: 15px;
				}
				.ui-system-line {
					position: absolute;
					bottom: 0;
					left: 38px;
					width: calc(100% - 38px);
				}
			}

		}
	}

	.contacts-list {
		padding: 0 @gutter;


		.contact-list-item {
			.v-separator {
				flex-grow: 3;
				flex-shrink: 3;
			}
			.phones-emails {
				width: 150px;
			}
		}

	}


	
}
.realty-names-popup {
	.popup-line {
		padding: 4px;
		&:nth-child(2n) {
			background-color: #40400010;
		}
	}
}