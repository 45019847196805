@import '~less/include.less';

body.deal-create {
	.module-board();

	/*.before-boardcontainer{
		white-space:nowrap;
		overflow-x:auto;
		overflow-y:hidden;
		li {
			display:inline-block;
			float:none;
		}
	}*/

}


.block.managers-container {
	li {
		font-size: 14px;
		// padding-top: 1px;
		// padding-bottom: 1px;
		span {
			.ff-header();
			text-transform: none;
			font-weight: 500;
		}
		&:Hover {
			background-color: rgba(0,0,0,.1);
			cursor: pointer;
		}
	}
}