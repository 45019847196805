@import '~less/include.less';

.actualize-subject {
	border: 1px solid transparent;
    border-radius: 2px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    position: relative;
    box-shadow: 0 0 10px 5px rgba(0,0,0,.05);
    border: 1px solid rgba(0,0,0,.07);
    color: #444;
    border-color: #e6e6e6;
    background-color: #fff;
	margin: 15px;
	> * {
		padding: 5px 15px;
	}
	.subject-name {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-bottom: 0;
		background-color: rgba(255,128,0,.05);
		> label {
			flex-grow:1;
			margin: 0;
		}
		> span {
			flex-grow:0;
			padding: 0 5px;
		}
		> span.action-link {
			text-decoration: underline;
			cursor: pointer;
		}
	}
	.subject-info {
		padding-top: 0;
		border-bottom: 1px solid rgba(128,64,0,.1);
		font-size: 11px;
		font-weight: 400;
		color: #555;
		margin-bottom: 5px;
		background-color: rgba(255,128,0,.05);
	}
	.children {
		padding-top: 0;
		padding-bottom: 0;
	}
	.child {
		display: flex;
		flex-direction: column;
		align-items: baseline;
		border-bottom: 1px solid rgba(0,0,0,.1);
		padding-top: 7px;
		padding-bottom: 7px;
		&:last-child {
			border-bottom: none;
		}
		//justify-content: space-between;
		> * {
			display: flex;
			flex-direction: row;
			align-items: baseline;
			&:not(:first-child) {
				padding-left: 17px;
				font-size: 11px;
				font-weight: 400;
				color: #555;
			}
			> *:not(:first-child) {
				margin-left: 15px;
			}
		}



		.tags {
			.tag {

				display: inline-block;
				padding:3px;
				font-size: 10px;
				font-weight: bold;
				line-height: 1;
				margin:5px;
				border-radius: 2px;
				border: 1px solid rgba(0,0,0,.1);
				background-color: blanchedalmond;				
				> i {
					.icon('tag');
					margin-right: 4px;
				}
			}
		}

		.staled > span:not(:empty) {
			display: block;
			border-radius: 3px;
			padding: 0 3px;
			font-weight: bold;
			color: #fff;
		}
		&.staled-positive {
			.staled > span:not(:empty) {
				background-color: gray;
			}
		}
		&.staled-negative {
			.staled > span:not(:empty) {
				background-color: darkred;
			}
		}


	}
	.bottom-actions {
		border-top: 1px solid rgba(40,128,0,.1);
		background-color: rgba(80, 255, 0, .05);
		> span {
			padding: 5px;
			margin: 5px;
		}
		.action-link {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}
