.lists-tabs {
	width: 100%;
	overflow: auto;
}
.tabs-list{
    white-space: nowrap;
}
.tab-item{
	display:inline-block;
	.ff-header(500);
	font-size:16px;
	padding:5px @gutter;
	color:#aaa;
	border-bottom: 3px solid transparent;
	.hover-darken();
	&.active, .active > & {
		color:#000;
		border-bottom-color :orange;
	}
}