.popover{
	.popover-content{
		ul.list-group{
			margin:-9px -14px;
			li {
				.list-group-item;
				border-left-width:0;
				border-right-width:0;
				border-bottom-width:0;
				padding-top:5px;
				padding-bottom:5px;
				background-color:transparent;
				&:first-child{ border-top-width:0; }

				> small {
					display:block;
					.ff-header();
					font-weight:500;
					font-size:9px;
					text-transform:uppercase;
					color:rgba(0,0,0,.5);
				}
				> span {
					display:block;
					font-size:16px;
					line-height:1;
				}
			}


			/*
			&.bg-rent {
				background-color:darken(@brand-success,15%);
				color:#fff;
				li {
					color:#fff;
				}
			}
			&.bg-sell {
				background-color:@brand-primary;
				color:#fff;
				li {
					color:#fff;
				}
			}
			*/

		}
	}


	&.in.popover-flat {
		padding:0;
		border:none;
		.arrow{
			border-left-color: rgba(0,0,0,.1);
		}

		ul.list-group{
			border:none;
			li {
				border:none;
			}
		}
	}

	&.in {
		&.bg-rent{
			background-color:darken(@brand-success,15%);
			color:#fff;
			&.left .arrow:after{border-left-color:darken(@brand-success,15%);}
			&.right .arrow:after{border-right-color:darken(@brand-success,15%);}
			&.top .arrow:after{border-top-color:darken(@brand-success,15%);}
			&.bottom .arrow:after{border-bottom-color:darken(@brand-success,15%);}
			ul li small { color: rgba(255,255,255,.7)}
		}
		&.bg-sell{
			background-color:@brand-primary;
			color:#fff;
			
			&.left .arrow:after{border-left-color:@brand-primary;}
			&.right .arrow:after{border-right-color:@brand-primary;}
			&.top .arrow:after{border-top-color:@brand-primary;}
			&.bottom .arrow:after{border-bottom-color:@brand-primary;}

			ul li small { color: rgba(255,255,255,.7)}
		}
	}

	&.popover-block{
		.popover-content{
			.block();
		}
	}


}