.tags-container {
	.tags-list-view {
		padding: 2px;
		.no-tags {
			padding: 3.5px 7px;
			color: #bbb;
			font-weight: bold;
		}
		.tag-item {
			padding: 3.5px 7px;
			padding-right: 0;
			font-weight: bold;
			display: inline-block;
			cursor: default;
			border-radius: 3px;
			button {
				background-color: transparent;
				border: none;
				padding: 5px;
				margin: 0;
				box-shadow: none;
				color: red;
				&:Hover {
					background-color: rgba(0,0,0,.1);	
					cursor: pointer;
				}
			}
			&:not(:Hover) {
				button {
					visibility: hidden;
				}
			}
			&:hover {
				background-color: rgba(0,0,0,.1);
			}
		}
	}
}