@import '~less/include.less';

.modal-close-mixin() {
	position: absolute;
	top: 0;
	right: 0;
	width: 32px;
	height: 32px;
	color: rgba(100, 100, 100, 0);
	transition: color 1s;
	text-align: center;
	z-index: 10;

	>span {
		.icon('close', true);
		font-size: 32px;
		line-height: 32px;
		color: #777;
	}

	&:Hover {
		cursor: pointer;
		border-radius: 2px;
		background-color: rgba(0, 0, 0, .1);
		color: #000;
	}
}


.modals-dialog:not(.flex-modal-dialog) {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 10;

	.modal-bg {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, .3);
	}

	.modal-close {
		.modal-close-mixin();
	}

	.modal-wrapper {
		position: fixed;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		overflow: auto;

		.modal-box {
			.not-in-modal {
				display: none;
			}
		}
	}

	.modal-box {
		position: relative;
		min-width: 270px;
		min-height: 50px;
		margin: auto;

		.no-padding {
			padding: 0;

			&:before {
				content: '';
				display: none;
			}

			&:after {
				clear: unset;
			}
		}

		&.preloader {
			width: 70px;
			height: 70px;
			min-width: unset;
			min-height: unset;
			top: 40%;
			color: #fff;
		}

		&.block.shadowed {
			box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
		}

		&:Hover .modal-close {
			color: rgba(100, 100, 100, .4);
		}

		&.fullheight {
			top: @gutter;
			height:~"calc(100% - @{gutter2})";
			overflow-y: auto;
			overflow-x: hidden;

			>section {
				height: 100%;

				>div {
					height: 100%;
				}
			}
		}

		&.fullwidth {
			max-width: none;
			width:~"calc(100% - @{gutter2})";
		}
	}

	&.narrow .modal-box {
		.width600;
	}

	&.popup {

		.modal-bg {
			display: none;
		}

		.modal-wrapper {}

		.modal-close {
			display: none;
		}

		.modal-box {
			position: absolute;
			height: auto;
			width: auto;
			min-height: initial;
			min-width: initial;
			max-width: initial;

			.no-padding {
				padding: 0;
			}

			>* {
				background-color: rgb(255, 250, 240);
				color: #444 !important;
				//border-color:
			}

			>section {
				padding: 0;
			}


			.modal-pin {
				width: 26px;
				height: 40px;
				position: absolute;
				overflow: hidden;
				display: block;
				background-color: transparent;
				left: -25px;
				top: -2px;
			}

			.modal-pin:after {
				content: "";
				position: absolute;
				height: 20px;
				width: 20px;
				background: rgb(255, 250, 240);
				transform: rotate(45deg);
				box-shadow: 0 0 10px 5px rgba(0, 0, 0, .05);
				top: 6px;
				right: -9px;
			}
		}
	}


	&.as-page {
		.modal-box {
			width: 100%;
			height: 100%;

			>section {
				width: 100%;
				height: 100%;
				background-color: #fff;
			}
		}
	}

}


.modals-dialog.flex-modal-dialog {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	z-index: 10;

	>.modal-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #00000040;
		z-index: 0;
	}

	>.modal-wrapper {
		padding: 15px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		flex-shrink: 1;
		z-index: 1;
		height: 100%;
		width: 100%;
		justify-content: center;

		max-width: 800px;
		margin: auto;

		>.modal-box {
			position: relative;
			background-color: white;
			box-shadow: 0 0 10px 5px #00000020;
			display: flex;
			flex-direction: column;
			&:not(.in-middle) {
				flex-grow: 1;
				flex-shrink: 1;
			}
			max-height: 100%;

			&.in-middle {
				flex-grow: 0;
				flex-shrink: 0;
				// align-self: center;
			}

			>section {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				flex-shrink: 1;
				max-height: 100%;
				overflow: auto;

				> :first-child {
					display: flex;
					flex-direction: column;
					flex-grow: 1;
					flex-shrink: 1;
					max-height: 100%;
					overflow: auto;
				}
			}

			>.modal-pin,
			>.modal-close {
				position: absolute;
			}

			>.modal-close {
				.modal-close-mixin();
			}
		}
	}

}