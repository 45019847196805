@import '~less/include.less';

.dropdown-icon-type-parts() {
	@true: 'square';
	@false: 'circle';
}
.dropdown-icon-state-parts() {
	@true: '';
	@false: '-o';
}
.dropdown-icon-prestate-parts() {
	@true: 'check-';
	@false: '';
}


.dropdown-item-icon(@multi, @checked) {
  @types: .dropdown-icon-type-parts();
  @type: @types[@@multi];
  @states: .dropdown-icon-state-parts();
  @state: @states[@@checked];
	@prestates: .dropdown-icon-prestate-parts();
	@prestate: @prestates[@@checked];

	i:empty {
		text-transform: none;
		font-style: normal;
		margin-right: 5px;
		margin-left: -15px;
		.icon('@{prestate}@{type}@{state}');
	}

}

.dropdown-item-icon(@multi) {
	&.active {
		.dropdown-item-icon(@multi, true);
	}
	&:not(.active) {
		.dropdown-item-icon(@multi, false);
	}
}

.dropdown-icons(@multi) {
	.dropdown-item {
		.dropdown-item-icon(@multi);
	}
}


.dropdown-icons() {

	&.multiple {
		.dropdown-icons(true);
	}
	&:not(.multiple) {
		.dropdown-icons(false);
	}

}

.dropdown-toggle {
	[data-role="current-value"] + .caret {
		margin-left: 5px;
	}
	> .reset {
		padding: 6px 12px;
  	display: inline-block;
  	margin-top: -6px;
  	margin-bottom: -6px;
  	margin-right: -12px;
		margin-left: 12px;
		&:hover {
			background-color: rgba(0,0,0,.1);
		}
	}
}

.with-icons {
	> .dropdown-menu {
		.dropdown-icons();
	}
}