@import '~less/include.less';

.deals-container {
	.board-list-region{
		padding-bottom:5px;
		> div {
			height:100%;
			background-color:rgba(0,0,0,.1);
			border-radius:2px;
		}
	}
}
