@import '~less/include.less';

.deal-offer.deal-offer.deal-offer.deal-offer.deal-offer.deal-offer.deal-offer.deal-offer {
	&.result-true{
		border-left-color:@brand-success;
		border-left-width: 5px;
		span.comment {
			color:@brand-success;
		}
	}
	&.result-false{
		border-left-color:@brand-danger;
		border-left-width: 5px;
		span.comment {
			color:@brand-danger;
		}
	}
	&:not(.virtual){
		.selection{
			.icon('check-square-o');
			font-style:normal;
			color:rgba(0,0,0,.1);
		}
		&.in-selected {
			.selection {
				color:@brand-success;
			}
		}
	}
	&.virtual {
		background-color:rgba(60,60,160,.1);
		.selection {
			cursor:default;
			&:Hover{
				background-color:unset;
			}
		}
	}
	border-left: 5px solid transparent;

	span.comment {
		display:block;
		border-top:1px solid rgba(0,0,0,.1);
		padding-top:4px;
		white-space:pre-wrap;
	}

	/*&.in-selected {
		border-right-color:@brand-success;
	    background-color: rgba(0,80,0,.05);
	}*/
}