@import '~less/include.less';

.offer-list-item {
	position: relative;
	.hover-darken();

	.aline {
		padding-right: 35px;
	}

	.actions-region {
		position: absolute;
		top: 2px;
		right: 2px;

		.action-btn {
			border: none;
			background-color: transparent;
			font-size: 20px;
			.hover-darken-btn-transparent();
			padding: 0;
			width: 30px;
			height: 30px;

			&.toggle-select {
				background-color: #eee;
				border-radius: 20px;
				//padding:0;
				text-align: center;

				span {
					.icon('check');
					width: 20px;
					color: #fff;
					text-align: center;
				}
			}
		}
	}

	&.in-selected .actions-region .action-btn.toggle-select {
		background-color: @brand-success;
		color: #fff;

		span {
			.icon('check');
			color: #fff;
		}
	}

	&.no-selection .actions-region .action-btn.toggle-select {
		background-color: @brand-warning;
		cursor: not-allowed;

		span {
			.icon('check');
		}
	}



}


