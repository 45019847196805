@import '../vendors/fontawesome/less/variables.less';

.fa-icon-ext() {
	display: inline-block;
	font-family: FontAwesome!important;
	//font: normal normal normal @fa-font-size-base/@fa-line-height-base FontAwesome; // shortening font declaration
	//font-size: inherit; // can't have font-size inherit on line above, so need to override
	text-rendering: auto; // optimizelegibility throws things off #1094
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* ext1 */

.icon(@icon, @block: false) {
	@var: "fa-var-@{icon}";
	.fa-icon-ext();

	//font: normal normal normal @fa-font-size-base/@fa-line-height-base FontAwesome; // shortening font declaration
	/*font-style: inherit;
	font-weight: inherit;
	font-variant: inherit;
	font-size: inherit;
	line-height: inherit;*/

	&:before {
		content: @@var;
	}

	& when (@block = true) {
		display: block;
	}
}

.icon-stacked(@big, @small, @block: false) {
	@bigvar: "fa-var-@{big}";
	@smallvar: "fa-var-@{small}";
	.fa-icon();

	&:before {
		content: @@bigvar;
		font-size: 2em;
	}

	&:after {
		content: @@smallvar;
		line-height: inherit;
	}

	& when (@block = true) {
		display: block;
	}
}
