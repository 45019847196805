@import '~less/include.less';

.gmaps-point{
	@bsize: 0px;
	@size: 11px;

	position:relative;
	border-width:@bsize;
	border-radius:50%;
	border-style:solid;
	//border-color:green;
	width:@size;
	height:@size;
	background-color:#969;
	box-shadow:0 0 3px 1px rgba(0,0,0,.4);
	overflow:hidden;
    left: -(@size - 1) / 2;
    top: -(@size - 1) / 2;
}